/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

export const InputBox = styled(Input)`
  font-size: 1rem;
  line-height: 1.5rem;
  align-items: center;
  width: 100%;
  border-width: 2px;
  outline: none;
  border-radius: 1.5rem;
`;

export const TextAreaInput = styled(TextArea)`
  font-size: 1rem;
  line-height: 1.5rem;
  align-items: center;
  width: 100%;
  border-width: 2px;
  outline: none;
  border-radius: 1.5rem;
  padding: 20px;
`;

export const SmallTextAreaInput = styled(TextArea)`
  line-height: 1.5rem;
  align-items: center;
  width: 100%;
  border-width: 2px;
  outline: none;
  border-radius: 1.5rem;
  padding: 10px;
`;
