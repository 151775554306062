/* eslint-disable import/no-cycle */
import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import TinyMECInput from '../../TinyMECInput.components';
import PersonalNoteUD, {
  PersonalNoteUDType,
  personalNoteUDNewState,
} from './type/PersonalNoteUD/PersonalNoteUD';
import PersonalNoteCirrhosis, {
  PersonalNoteCirrhosisType,
  personalNoteCirrhosisNewState,
} from './type/PersonalNoteCirrhosis';
import PersonalNoteFamilyHistory, {
  PersonalNoteFamilyHistoryType,
  personalNoteFamilyHistoryNewState,
} from './type/PersonalNoteFamilyHistory';
import PersonalNoteEgd, {
  PersonalNoteEgdType,
  personalNoteEgdNewState,
} from './type/PersonalNoteEgd';
import PersonalNoteMri, {
  PersonalNoteMriType,
  personalNoteMriNewState,
} from './type/PersonalNoteMri';
import PersonalNoteUS, {
  PersonalNoteUSType,
  personalNoteUSNewState,
} from './type/PersonalNoteUS';
import PersonalNoteFibroScan, {
  PersonalNoteFibroScanType,
  personalNoteFibroScanNewState,
} from './type/PersonalNoteFibroScan';
import PersonalNoteColonoScope, {
  PersonalNoteColonoScopeType,
  personalNoteColonoScopeNewState,
} from './type/PersonalNoteColonoscope';
import { SaveNoteButton } from '../../Button';

export enum EPersonalNoteType {
  DROPDOWN = 'DROPDOWN',
  DROPDOWN_WITH_SCORE = 'DROPDOWN_WITH_SCORE',
  DATE = 'DATE',
  TEXT = 'TEXT',
  ANSWER = 'ANSWER',
  ANSWER_WITH_CONDITION = 'ANSWER_WITH_CONDITION',

  UD = 'UD',
  CHRONIC_HBV = 'CHRONIC_HBV',
  CHRONIC_HCV = 'CHRONIC_HCV',
  FATTY_LIVER = 'FATTY_LIVER',
  AUTOIMMUNE_HEPATITIS = 'AUTOIMMUNE_HEPATITIS',
  OTHERS = 'OTHERS',
  CIRRHOSIS = 'CIRRHOSIS',
  FAMILY_HISTORY = 'FAMILY_HISTORY',
  FIBROSCAN = 'FIBROSCAN',
  EGD = 'EGD',
  COLONOSCOPE = 'COLONOSCOPE',
  US = 'US',
  MRI = 'MRI',
}

export type TPersonalNoteTemplate = {
  key: string;
  index: number;
  name: string;
  sub_name?: string;
  type: string;
  items?: TPersonalNoteTemplate[];
  options?: TPersonalNoteTemplate[];
  sub_options?: TPersonalNoteTemplate[];
  condition?: string;
  condition_value?: number;
  limit?: number;
  defaultValue?: string;
};

const personalNoteTemplate = [
  {
    key: 'ud',
    index: 0,
    name: 'U/D',
    type: EPersonalNoteType.UD,
    items: [
      {
        key: 'chronic_hbv',
        index: 0,
        name: 'Chronic HBV',
        type: EPersonalNoteType.CHRONIC_HBV,
        items: [
          {
            key: 'hbsag',
            index: 0,
            name: 'HBsAg',
            type: EPersonalNoteType.DROPDOWN,
            options: [
              {
                key: 'clearance',
                index: 0,
                name: 'Clearance',
                type: EPersonalNoteType.ANSWER,
              },
              {
                key: 'seroconversion',
                index: 1,
                name: 'Seroconversion',
                type: EPersonalNoteType.ANSWER,
              },
              {
                key: 'positive',
                index: 2,
                name: 'Positive',
                type: EPersonalNoteType.ANSWER,
              },
            ],
          },
          {
            key: 'hbeag',
            index: 1,
            name: 'HBeAg',
            type: EPersonalNoteType.DROPDOWN,
            options: [
              {
                key: 'positive',
                index: 0,
                name: 'Positive',
                type: EPersonalNoteType.ANSWER,
              },
              {
                key: 'negative',
                index: 1,
                name: 'Negative',
                type: EPersonalNoteType.ANSWER,
              },
              {
                key: 'unknown',
                index: 2,
                name: 'Unknown',
                type: EPersonalNoteType.ANSWER,
              },
            ],
          },
          {
            key: 'on_medication',
            index: 2,
            name: 'On Medication',
            type: EPersonalNoteType.DROPDOWN,
            options: [
              {
                key: 'lamivudine',
                index: 0,
                name: 'Lamivudine',
                type: EPersonalNoteType.ANSWER,
              },
              {
                key: 'baraclude',
                index: 1,
                name: 'Baraclude',
                type: EPersonalNoteType.ANSWER,
              },
              {
                key: 'tdf',
                index: 2,
                name: 'TDF',
                type: EPersonalNoteType.ANSWER,
              },
              {
                key: 'taf',
                index: 3,
                name: 'TAF',
                type: EPersonalNoteType.ANSWER,
              },
              {
                key: 'peg_ifn',
                index: 4,
                name: 'PEG/IFN',
                type: EPersonalNoteType.ANSWER,
              },
            ],
          },
          {
            key: 'hcc_predictation',
            index: 3,
            name: 'HCC Predictation',
            type: EPersonalNoteType.DROPDOWN_WITH_SCORE,
            options: [
              {
                key: 'page',
                index: 0,
                name: 'PAGE',
                condition: '>',
                condition_value: 9,
                limit: 4,
                type: EPersonalNoteType.ANSWER_WITH_CONDITION,
              },
              {
                key: 'reach',
                index: 1,
                name: 'REACH',
                condition: '>',
                condition_value: 8,
                limit: 5,
                type: EPersonalNoteType.ANSWER_WITH_CONDITION,
              },
            ],
          },
        ],
      },
      {
        key: 'chronic_hcv',
        index: 1,
        name: 'Chronic HCV',
        type: EPersonalNoteType.CHRONIC_HCV,
        condition: '=',
        condition_value: 0,
        defaultValue: 'none_treat',
        options: [
          {
            key: 'treated',
            index: 0,
            name: 'Treated',
            type: EPersonalNoteType.ANSWER,
          },
          {
            key: 'none_treat',
            index: 1,
            name: 'None treat',
            type: EPersonalNoteType.ANSWER,
          },
        ],
        sub_options: [
          {
            key: 'treatment',
            index: 0,
            name: 'Treatment',
            type: EPersonalNoteType.DROPDOWN,
            options: [
              {
                key: 'peg_ifn',
                index: 0,
                name: 'PEG/IFN',
                type: EPersonalNoteType.ANSWER,
              },
              {
                key: 'daa',
                index: 1,
                name: 'DAA',
                type: EPersonalNoteType.ANSWER,
              },
            ],
          },
          {
            key: 'date',
            index: 1,
            name: 'Date',
            type: EPersonalNoteType.DATE,
          },
        ],
      },
      {
        key: 'fatty_liver',
        index: 2,
        name: 'Fatty liver',
        type: EPersonalNoteType.FATTY_LIVER,
      },
      {
        key: 'autoimmune_hepatitis',
        index: 3,
        name: 'Autoimmune hepatitis',
        type: EPersonalNoteType.AUTOIMMUNE_HEPATITIS,
      },
      {
        key: 'others',
        index: 4,
        name: 'Others',
        type: EPersonalNoteType.OTHERS,
      },
    ],
  },
  {
    key: 'cirrhosis',
    index: 1,
    name: 'Cirrhosis',
    type: EPersonalNoteType.CIRRHOSIS,
    defaultValue: 'none_cirrhosis',
    options: [
      {
        key: 'cirrhosis',
        index: 0,
        name: 'Cirrhosis',
        type: EPersonalNoteType.ANSWER,
      },
      {
        key: 'none_cirrhosis',
        index: 1,
        name: 'Non cirrhosis',
        type: EPersonalNoteType.ANSWER,
      },
    ],
  },
  {
    key: 'family_history',
    index: 2,
    name: 'Family History',
    type: EPersonalNoteType.FAMILY_HISTORY,
    items: [
      {
        key: 'hcc',
        index: 0,
        name: 'HCC',
        type: EPersonalNoteType.TEXT,
      },
      {
        key: 'ca_colon',
        index: 1,
        name: 'CA colon',
        type: EPersonalNoteType.TEXT,
      },
      {
        key: 'others',
        index: 2,
        name: 'Others',
        type: EPersonalNoteType.TEXT,
      },
    ],
  },
  {
    key: 'fibroscan',
    index: 3,
    name: 'Fibroscan',
    type: EPersonalNoteType.FIBROSCAN,
  },
  {
    key: 'egd',
    index: 4,
    name: 'EGD',
    type: EPersonalNoteType.EGD,
  },
  {
    key: 'colonoscope',
    index: 5,
    name: 'Colonoscope',
    type: EPersonalNoteType.COLONOSCOPE,
  },
  {
    key: 'us',
    index: 6,
    name: 'U/S',
    sub_name: 'With elastrography',
    type: EPersonalNoteType.US,
    options: [
      {
        key: 'yes',
        index: 0,
        name: 'Yes',
        type: EPersonalNoteType.ANSWER,
      },
      {
        key: 'no',
        index: 1,
        name: 'No',
        type: EPersonalNoteType.ANSWER,
      },
    ],
  },
  {
    key: 'mri',
    index: 7,
    name: 'MRI',
    sub_name: 'With MRE',
    type: EPersonalNoteType.MRI,
    options: [
      {
        key: 'yes',
        index: 0,
        name: 'Yes',
        type: EPersonalNoteType.ANSWER,
      },
      {
        key: 'no',
        index: 1,
        name: 'No',
        type: EPersonalNoteType.ANSWER,
      },
      {
        key: 'others',
        index: 2,
        name: 'Others',
        type: EPersonalNoteType.ANSWER,
      },
    ],
  },
];

const Title = styled.div`
  font-size: 16px;
  color: #3673b9;
`;

interface Props {
  value: PersonalNote;
  isShuttingDownModal: boolean;
  onCancel: () => void;
  onOk: (data: any) => void;
}

export type PersonalNote = {
  ud: PersonalNoteUDType;
  cirrhosis: PersonalNoteCirrhosisType;
  familyHistory: PersonalNoteFamilyHistoryType;
  fibroScan: PersonalNoteFibroScanType;
  egd: PersonalNoteEgdType;
  colonoScope: PersonalNoteColonoScopeType;
  us: PersonalNoteUSType;
  mri: PersonalNoteMriType;
  note: string;
};

export const personalNoteNewState = {
  ud: personalNoteUDNewState,
  cirrhosis: personalNoteCirrhosisNewState,
  familyHistory: personalNoteFamilyHistoryNewState,
  fibroScan: personalNoteFibroScanNewState,
  egd: personalNoteEgdNewState,
  colonoScope: personalNoteColonoScopeNewState,
  us: personalNoteUSNewState,
  mri: personalNoteMriNewState,
  note: '',
};

const PersonalNoteModal: React.FC<Props> = (props) => {
  const { value, isShuttingDownModal, onOk } = props;

  const [state, setState] = useState<PersonalNote>({
    ...value,
    ud: {
      ...value.ud,
      chronicHCV: {
        ...value.ud.chronicHCV,
        dateSelected: value.ud.chronicHCV.dateSelected && moment(value.ud.chronicHCV.dateSelected),
      },
    },
    fibroScan: {
      ...value.fibroScan,
      previousDate:
        value.fibroScan.previousDate && moment(value.fibroScan.previousDate),
      updatedDate:
        value.fibroScan.updatedDate && moment(value.fibroScan.updatedDate),
    },
    colonoScope: {
      ...value.colonoScope,
      previousDate:
        value.colonoScope.previousDate && moment(value.colonoScope.previousDate),
      updatedDate:
        value.colonoScope.updatedDate && moment(value.colonoScope.updatedDate),
    },
    egd: {
      ...value.egd,
      previousDate:
        value.egd.previousDate && moment(value.egd.previousDate),
      updatedDate:
        value.egd.updatedDate && moment(value.egd.updatedDate),
    },
    us: {
      ...value.us,
      previousDate: value.us.previousDate && moment(value.us.previousDate),
      updatedDate: value.us.updatedDate && moment(value.us.updatedDate),
    },
    mri: {
      ...value.mri,
      previousDate: value.mri.previousDate && moment(value.mri.previousDate),
      updatedDate: value.mri.updatedDate && moment(value.mri.updatedDate),
    },
  });

  const onChangeUD = (_value: PersonalNoteUDType) => {
    setState({
      ...state,
      ud: _value,
    });
  };

  const onChangeCirrhosis = (_value: PersonalNoteCirrhosisType) => {
    setState({
      ...state,
      cirrhosis: _value,
    });
  };

  const onChangeFamilyHistory = (_value: PersonalNoteFamilyHistoryType) => {
    setState({
      ...state,
      familyHistory: _value,
    });
  };

  const onChangeFibroScan = (_value: PersonalNoteFibroScanType) => {
    setState({
      ...state,
      fibroScan: _value,
    });
  };

  const onChangeEgd = (_value: PersonalNoteEgdType) => {
    setState({
      ...state,
      egd: _value,
    });
  };
  const onChangeColonoScope = (_value: PersonalNoteColonoScopeType) => {
    setState({
      ...state,
      colonoScope: _value,
    });
  };
  const onChangeUS = (_value: PersonalNoteUSType) => {
    setState({
      ...state,
      us: _value,
    });
  };
  const onChangeMri = (_value: PersonalNoteMriType) => {
    setState({
      ...state,
      mri: _value,
    });
  };

  const onChangeNote = (_value: string) => {
    setState({
      ...state,
      note: _value,
    });
  };

  const onSaveNote = () => {
    const data = {
      ...state,
      ud: {
        ...state.ud,
        chronicHCV: {
          ...state.ud.chronicHCV,
          dateSelected: state.ud.chronicHCV.dateSelected && state.ud.chronicHCV.dateSelected.toISOString(),
        },
      },
      fibroScan: {
        ...state.fibroScan,
        previousDate:
          state.fibroScan.previousDate && state.fibroScan.previousDate.toISOString(),
        updatedDate:
          state.fibroScan.updatedDate && state.fibroScan.updatedDate.toISOString(),
      },
      colonoScope: {
        ...state.colonoScope,
        previousDate:
          state.colonoScope.previousDate && state.colonoScope.previousDate.toISOString(),
        updatedDate:
          state.colonoScope.updatedDate && state.colonoScope.updatedDate.toISOString(),
      },
      egd: {
        ...state.egd,
        previousDate:
          state.egd.previousDate && state.egd.previousDate.toISOString(),
        updatedDate:
          state.egd.updatedDate && state.egd.updatedDate.toISOString(),
      },
      us: {
        ...state.us,
        previousDate: state.us.previousDate && state.us.previousDate.toISOString(),
        updatedDate: state.us.updatedDate && state.us.updatedDate.toISOString(),
      },
      mri: {
        ...state.mri,
        previousDate: state.mri.previousDate && state.mri.previousDate.toISOString(),
        updatedDate: state.mri.updatedDate && state.mri.updatedDate.toISOString(),
      },
    };
    onOk(data);
  };

  return (
    <Modal
      centered
      title={<Title>Personal Note</Title>}
      footer={(
        <div className="flex justify-end w-full my-0">
          <SaveNoteButton onClick={onSaveNote}>Save Note</SaveNoteButton>
        </div>
      )}
      width="75%"
      open={isShuttingDownModal}
      onCancel={props.onCancel}
    >
      <div className="flex flex-col justify-center w-full text-base">
        <div className="pb-4">
          <p className="font-medium">Note</p>
          <TinyMECInput
            height="200px"
            inputValue={state.note}
            handleChangeValue={onChangeNote}
          />
        </div>
        <div className="">
          {personalNoteTemplate.map((personalNote) => {
            const { key, type } = personalNote;
            if (type === EPersonalNoteType.UD) {
              return (
                <PersonalNoteUD
                  key={key}
                  personalNote={personalNote}
                  onHandleChange={onChangeUD}
                  value={state.ud}
                />
              );
            }
            if (type === EPersonalNoteType.CIRRHOSIS) {
              return (
                <PersonalNoteCirrhosis
                  key={key}
                  personalNote={personalNote}
                  onHandleChange={onChangeCirrhosis}
                  value={state.cirrhosis}
                />
              );
            }
            if (type === EPersonalNoteType.FAMILY_HISTORY) {
              return (
                <PersonalNoteFamilyHistory
                  key={key}
                  personalNote={personalNote}
                  onHandleChange={onChangeFamilyHistory}
                  value={state.familyHistory}
                />
              );
            }
            if (type === EPersonalNoteType.EGD) {
              return (
                <PersonalNoteEgd
                  key={key}
                  personalNote={personalNote}
                  onHandleChange={onChangeEgd}
                  value={state.egd}
                />
              );
            }
            if (type === EPersonalNoteType.COLONOSCOPE) {
              return (
                <PersonalNoteColonoScope
                  key={key}
                  personalNote={personalNote}
                  onHandleChange={onChangeColonoScope}
                  value={state.colonoScope}
                />
              );
            }
            if (type === EPersonalNoteType.FIBROSCAN) {
              return (
                <PersonalNoteFibroScan
                  key={key}
                  personalNote={personalNote}
                  onHandleChange={onChangeFibroScan}
                  value={state.fibroScan}
                />
              );
            }
            if (type === EPersonalNoteType.US) {
              return (
                <PersonalNoteUS
                  key={key}
                  personalNote={personalNote}
                  onHandleChange={onChangeUS}
                  value={state.us}
                />
              );
            }
            if (type === EPersonalNoteType.MRI) {
              return (
                <PersonalNoteMri
                  key={key}
                  personalNote={personalNote}
                  onHandleChange={onChangeMri}
                  value={state.mri}
                />
              );
            }
            return <div>{personalNote.name}</div>;
          })}
        </div>
      </div>
    </Modal>
  );
};
export default PersonalNoteModal;
