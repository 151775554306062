/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
// import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import AppointmentInfoCard from '../appointment/components/AppointmentInfoCard';
import Loading from '../common/components/Loading';
import useFetchOldAppointmentsByUserID from '../common/connecting-api/appointment/hooks/use-fetch-old-appointments-by-user-id';
import { TAppointment } from '../common/form-schema/appointments/AppointmentSchema';

interface Props {
  userType: string;
}

const AppointmentHistories: React.FC<Props> = (props) => {
  const { userType } = props;
  const { id } = useParams<{ id: string }>();
  const appointmentQuery = useFetchOldAppointmentsByUserID(id, userType);

  if (appointmentQuery.status !== 'success' || !appointmentQuery.data.success) return <div><Loading /></div>;

  if (appointmentQuery.data.appointments.length === 0) {
    return (
      <div className="p-4 text-center">
        <p className="text-base font-normal">
          ยังไม่มีประวัติการนัดหมายของผู้รับบริการ
        </p>
      </div>
    );
  }

  return (
    <div>
      {appointmentQuery.data.appointments.map((appointment: TAppointment, index: number) => {
        const appointmentLength = appointmentQuery.data.success && appointmentQuery.data.appointments.length;
        return (
          <AppointmentInfoCard
            key={appointment._id}
            userType={userType}
            appointment={appointment}
            isFromAppointmentHistories
            isLastElement={appointmentLength === index + 1}
          />
        );
      })}
    </div>
  );
};

export default AppointmentHistories;
