/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Modal } from 'antd';
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { HandleFormatDate } from '../../common/all-about-string-method';
import { SaveButton } from '../../common/components/Button';
import { TextAreaInput } from '../../common/components/Input';
import TinyMECInput from '../../common/components/TinyMECInput';
import TinyMECShowDetail from '../../common/components/TinyMECShowDetail';
import { useDeleteMedicalNote, useEditPlainMedicalNote } from '../../common/connecting-api/appointment/hooks/use-manage-all-medical-note';
import { TMedicalNote, TMedicalNoteInitialValue } from '../../common/form-schema/appointments/AppointmentSchema';
import AppointmentDiagnosisModalFromMedicalHistoryInfo from './AppointmentDiagnosisModalFromMedicalHistoryInfo';
import { AppointmentTypeLabel, MedicalNoteDeleteButton, MedicalNoteEditButton } from './AppointmentTypes';

interface IMedicalhistoryInfo {
  medicalNote: TMedicalNote;
  isLastElement: boolean;
}

const MedicalhistoryInfo: React.FC<IMedicalhistoryInfo> = (props) => {
  const {
    medicalNote,
    isLastElement,
  } = props;
  const queryClient = useQueryClient();

  const { mutate: editPlainMedicalNote } = useEditPlainMedicalNote();
  const { mutate: deleteMedicalNote } = useDeleteMedicalNote();

  const [isAppointmentModalVisible, setIsAppointmentModalVisible] = useState(false);
  const [isPlainMedicalNoteModalVisible, setIsPlainMedicalNoteModalVisible] = useState(false);
  const [plainMedicalNote, setPlainMedicalNote] = useState(medicalNote.note);

  const handleAlertSuccess = () => {
    queryClient.invalidateQueries(['all_medical_note', medicalNote.patient_id]);
    setIsPlainMedicalNoteModalVisible(false);
    Modal.success({
      title: 'Success!',
      content: 'Operation Success',
      okText: ' OK ',
    });
  };

  const handleAlertError = () => {
    Modal.error({
      title: 'Error!',
      content: 'Error occured, Please try again later',
      okText: ' Close ',
    });
  };

  const handleEditPlainMedicalNote = () => {
    const valuesToSave: TMedicalNoteInitialValue = {
      extra_visit: medicalNote.extra_visit,
      note: plainMedicalNote.trim(),
      patient_id: medicalNote.patient_id,
      created_by: medicalNote.created_by,
    };
    editPlainMedicalNote({ noteID: medicalNote._id, body: valuesToSave }, { onSuccess: handleAlertSuccess, onError: handleAlertError });
  };

  const handleCheckMedicalNoteType = (): string => {
    let colorCode = '';
    if (medicalNote.appointment_id) { colorCode = '#1E73BE'; }
    if (!medicalNote.appointment_id) { colorCode = '#CF1322'; }
    return colorCode;
  };

  const handleClickShowModal = () => {
    if (medicalNote.appointment_id) { setIsAppointmentModalVisible(true); }
    if (!medicalNote.appointment_id) { setIsPlainMedicalNoteModalVisible(true); }
  };

  const handleClickHideModal = () => {
    if (medicalNote.appointment_id) { setIsAppointmentModalVisible(false); }
    if (!medicalNote.appointment_id) {
      setPlainMedicalNote(medicalNote.note);
      setIsPlainMedicalNoteModalVisible(false);
    }
  };

  const handleDeleteMedicalNote = (note: TMedicalNote) => {
    Modal.warning({
      title: 'Confirm Deleting',
      content: 'Are you sure to delete this note?',
      centered: true,
      okText: 'Yes',
      cancelText: 'No',
      closable: true,
      okCancel: true,
      onOk: () => deleteMedicalNote(note._id, { onSuccess: handleAlertSuccess, onError: handleAlertError }),
      onCancel: () => {},
    });
  };

  return (
    <>
      <div
        className={`${isLastElement ? '' : 'mb-4'} bg-white outline-none`}
      >
        <div className="relative flex flex-row w-full">
          <div className="w-full cursor-pointer" onClick={handleClickShowModal}>
            <div className={`${!medicalNote.appointment_id && 'justify-between'}  flex flex-col pb-2`}>
              <span
                className="text-lg font-medium"
                style={{ color: handleCheckMedicalNoteType() }}
              >
                {HandleFormatDate(medicalNote.appointment_id ? medicalNote.appointment_id.appointment_date : medicalNote.created_at)}&nbsp;&nbsp; {medicalNote.extra_visit && '(Extra Visit)'} {medicalNote.appointment_id && <AppointmentTypeLabel appointmentType={medicalNote.appointment_id.appointment_type} isMedicalNote />}
              </span>
              <span className="text-base opacity-80 line-clamp-3">
                <TinyMECShowDetail details={medicalNote.note} />
              </span>
            </div>
          </div>
          <div className="absolute top-0 right-0 flex flex-row gap-x-2">
            {!medicalNote.appointment_id && <MedicalNoteEditButton handleEdit={handleClickShowModal} />}
            <MedicalNoteDeleteButton handleDelete={() => handleDeleteMedicalNote(medicalNote)} />
          </div>
        </div>
      </div>
      {!isLastElement && <hr className="my-2" />}
      {medicalNote.appointment_id ? (
        <AppointmentDiagnosisModalFromMedicalHistoryInfo
          isModalVisible={isAppointmentModalVisible}
          handleClickHideModal={handleClickHideModal}
          medicalNote={medicalNote}
        />
      ) : (
        <Modal
          centered
          footer={null}
          width="75%"
          open={isPlainMedicalNoteModalVisible}
          onCancel={handleClickHideModal}
        >
          <div className="flex flex-col">
            <p className="text-lg font-bold text-red-500 max-w-max">Progress Notes</p>
            <TinyMECInput inputValue={plainMedicalNote} handleChangeValue={(value) => setPlainMedicalNote(value)} />

            <SaveButton
              className="self-center mx-auto mt-4 max-w-max"
              onClick={handleEditPlainMedicalNote}
              disabled={plainMedicalNote.trim().length === 0}
            >
              Save
            </SaveButton>
          </div>
        </Modal>
      )}
    </>
  );
};

export default MedicalhistoryInfo;
