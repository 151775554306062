/* eslint-disable @typescript-eslint/no-explicit-any */
import config from '../../../config';
import { authTokenLocalStorage } from './local-storage';

const baseURL = `${config.apiPath}`;

interface FetchData<T = any> {
  data?: T;
}

const processFetch = (res: Response): Promise<FetchData> => (
  Promise.all([res.ok, res.json()])
    .then(([ok, response]) => {
      if (!ok) {
        return Promise.reject(response);
      }
      return Promise.resolve({ data: response });
    })
);

export default {
  get: (url: string): Promise<FetchData> => {
    const token = authTokenLocalStorage.get();
    return fetch(`${baseURL}${url}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        ...token ? { Authorization: `Bearer ${token}` } : {},
      },
    }).then((res) => {
      return processFetch(res);
    });
  },
  post: (url: string, body = {}): Promise<FetchData> => {
    const token = authTokenLocalStorage.get();
    return fetch(`${baseURL}${url}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...token ? { Authorization: `Bearer ${token}` } : {},
      },
      credentials: 'include',
      body: JSON.stringify(body),
    }).then((res) => {
      return processFetch(res);
    });
  },
  uploadImage: (url: string, body: FormData): Promise<FetchData> => {
    const token = authTokenLocalStorage.get();
    return fetch(`${baseURL}${url}`, {
      method: 'POST',
      headers: {
        ...token ? { Authorization: `Bearer ${token}` } : {},
      },
      credentials: 'include',
      body,
    }).then((res) => {
      return processFetch(res);
    });
  },
  delete: (url: string, body = {}): Promise<FetchData> => {
    const token = authTokenLocalStorage.get();
    return fetch(`${baseURL}${url}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...token ? { Authorization: `Bearer ${token}` } : {},
      },
      credentials: 'include',
      body: JSON.stringify(body),
    }).then((res) => {
      return processFetch(res);
    });
  },
  put: (url: string, body = {}): Promise<FetchData> => {
    const token = authTokenLocalStorage.get();
    return fetch(`${baseURL}${url}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...token ? { Authorization: `Bearer ${token}` } : {},
      },
      credentials: 'include',
      body: JSON.stringify(body),
    }).then((res) => {
      return processFetch(res);
    });
  },
};
