/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { Checkbox, DatePicker, Dropdown, Radio, RadioChangeEvent } from 'antd';
import cx from 'classnames';
import moment from 'moment';
import { TPersonalNoteTemplate } from '../../PersonalNoteModal';

export type PersonalNoteChronicHCVType = {
  isChecked: boolean;
  isExpand: boolean;
  radioSelected: string;
  isShowSubOption: boolean;
  dropdownSelected: string;
  dateSelected: moment.Moment | null;
};

export const personalNoteChronicHCVNewState: PersonalNoteChronicHCVType = {
  isChecked: false,
  isExpand: false,
  radioSelected: 'none_treat',
  isShowSubOption: false,
  dropdownSelected: '',
  dateSelected: null,
};

const PersonalNoteChronicHCV: React.FC<{
  personalNote: TPersonalNoteTemplate;
  value: PersonalNoteChronicHCVType | null;
  onHandleChange: (value: PersonalNoteChronicHCVType) => void;
}> = ({ personalNote, value, onHandleChange }) => {
  const {
    name,
    options,
    sub_options: subOptions,
    defaultValue,
    condition,
    condition_value: conditionValue,
  } = personalNote;

  const [state, setState] = useState<PersonalNoteChronicHCVType>(value ?? {
    ...personalNoteChronicHCVNewState,
    radioSelected: defaultValue ?? personalNoteChronicHCVNewState.radioSelected,
  });

  useEffect(() => {
    onHandleChange(state);
  }, [state, setState]);

  const onChangeCheckbox = (checked: boolean) => {
    setState(checked
      ? {
        ...state,
        isChecked: checked,
        isExpand: checked,
      }
      : {
        ...personalNoteChronicHCVNewState,
      });
  };

  const onChangeRadio = (e: RadioChangeEvent) => {
    setState({
      ...state,
      isExpand: true,
      isChecked: true,
      radioSelected: e.target.value,
      isShowSubOption: condition === '=' && e.target.tabIndex === conditionValue,
      dateSelected: e.target.tabIndex === conditionValue ? state.dateSelected : null,
      dropdownSelected: e.target.tabIndex === conditionValue ? state.dropdownSelected : '',
    });
  };

  const onChangeDropdown = (_value: string) => {
    setState({
      ...state,
      dropdownSelected: _value,
      isChecked: true,
    });
  };

  const toggleExpand = () => {
    setState({
      ...state,
      isExpand: !state.isExpand,
    });
  };

  const onChangeDate = (date: moment.Moment | null) => {
    setState({
      ...state,
      dateSelected: date,
    });
  };

  return (
    <div className="p-4 border">
      <div className="flex">
        <div className="flex flex-grow">
          <Checkbox
            name={name}
            className="pr-4"
            checked={state.isChecked}
            onChange={(e) => onChangeCheckbox(e.target.checked)}
          />
          <p className="pb-2">{name}</p>
        </div>
        <div className="flex flex-grow-0 items-center">
          <div onClick={toggleExpand} className=" cursor-pointer">
            <img
              className={`transform transition-transform ${
                state.isExpand ? 'rotate-0' : 'rotate-180'
              }`}
              src="/icons/arrow_down.svg"
              alt="arrow down"
            />
          </div>
        </div>
      </div>
      {state.isExpand && (
        <div className="pt-4">
          <div
            className="p-4 rounded-t-lg"
            style={{ backgroundColor: '#F8F8F8' }}
          >
            <Radio.Group
              onChange={onChangeRadio}
              value={state.radioSelected}
            >
              {options?.map((option) => (
                <Radio
                  key={option.key}
                  value={option.key}
                  tabIndex={option.index}
                >
                  {option.name}
                </Radio>
              ))}
            </Radio.Group>
          </div>
          <div
            className={cx('border-t-2 rounded-b-lg p-4 flex', {
              block: state.isShowSubOption,
              hidden: !state.isShowSubOption,
            })}
            style={{ backgroundColor: '#F8F8F8' }}
          >
            <div>
              <p>Treatment</p>
              <Dropdown
                className="border rounded-full py-2 px-4 mt-4 w-40 lg:w-60 xl:w-80 bg-white"
                trigger={['click']}
                menu={{
                  className: 'rounded-xl',
                  items:
                    subOptions?.at(0)?.options?.map((option) => {
                      return {
                        key: option.key,
                        label: option.name,
                        onClick: () => {
                          onChangeDropdown(option.name);
                        },
                      };
                    }) ?? [],
                }}
              >
                <div className="flex">
                  <div className="flex flex-grow">{state.dropdownSelected}</div>
                  <div className="flex flex-grow-0 w-4">
                    <img src="/icons/arrow_down.svg" alt="arrow down" />
                  </div>
                </div>
              </Dropdown>
            </div>
            <div className="pl-4">
              <div className="flex flex-col h-full justify-between">
                <p className="">Date</p>
                <DatePicker
                  className="rounded-full h-10 w-40 lg:w-60 xl:w-80"
                  value={state.dateSelected}
                  onChange={onChangeDate}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PersonalNoteChronicHCV;
