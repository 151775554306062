import React from 'react';
import { Radio } from 'antd';
import moment from 'moment';
import cx from 'classnames';

import { PersonalNoteColonoScopeType } from '../../common/components/modals/PersonalNoteModal/type/PersonalNoteColonoscope';

const PersonalNoteColonoScopeResult: React.FC<{
  value: PersonalNoteColonoScopeType;
}> = ({ value }) => {
  return (
    <div className="'flex flex-col pt-2 border-b">
      <p className="text-primary font-bold text-lg pb-2">Colonosccope</p>
      <div className="pb-4">
        <Radio.Group value={value.isShow}>
          <Radio key="colonoscope_yes" value>
            Yes
          </Radio>
          <Radio key="colonoscope_no" value={false}>
            No
          </Radio>
        </Radio.Group>
      </div>
      {value.isShow && (
        <>
          <div
            className={cx('pb-4', {
              hidden: !value.previousDate && !value.previousNote,
            })}
          >
            <p className="text-base font-bold pb-2">Previous Record</p>
            <div className="bg-gray-100 rounded-lg px-4 py-2">
              <p className="text-base font-bold">Date</p>
              <p className="text-base text-gray-500">
                {value.previousDate
                  ? moment(value.previousDate).format('DD/MM/yyy')
                  : '-'}
              </p>
              <p className="text-base font-bold">Result</p>
              <p className="text-base text-gray-500 break-words">
                {value.previousNote !== '' ? value.previousNote : '-'}
              </p>
            </div>
          </div>
          <div className="pb-4">
            <p className="text-base font-bold pb-2">Updated Record</p>
            <div className="bg-gray-100 rounded-lg px-4 py-2">
              <p className="text-base font-bold">Date</p>
              <p className="text-base text-gray-500">
                {value.updatedDate
                  ? moment(value.updatedDate).format('DD/MM/yyy')
                  : '-'}
              </p>
              <p className="text-base font-bold">Result</p>
              <p className="text-base text-gray-500 break-words">
                {value.updatedNote !== '' ? value.updatedNote : '-'}
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PersonalNoteColonoScopeResult;
