/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Layout,
} from 'antd';
import { motion } from 'framer-motion';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { useQueryClient } from 'react-query';

import { Helmet } from 'react-helmet';
import Routes from './Routes';
import useFetchUser from './features/common/connecting-api/auth/hooks/use-fetch-user';
import Loading from './features/common/components/Loading';
import LoginPage from './pages/auth/LoginPage';
import { SetDynamicTitleBar } from './features/common/set-dynamic-title-bar';
import AvatarImage from './features/common/components/AvatarImage';
import { TEmitServerRead, TMessageSchema, TPatientInfoWithIncomingMessage } from './features/chats/schema/MessageSchema';
import { UserType } from './features/common/constant-enum-type';
import { UserSchema } from './features/common/form-schema/user/UserSchema';
import UserState from './common-state/user-state';
import RecentChatState from './common-state/recent-chat-state';
import config from './config';
import { authTokenLocalStorage } from './features/common/utils/local-storage';
import { SocketContext } from './Contexts/SocketContext';
import UnreadChatState from './common-state/unread-chat-state';
import useFetchPatientsRecentChat from './features/common/connecting-api/chat/hooks/use-fetch-patients-recent-chat';
import ChatPatientIDState from './common-state/chat-patient-id-state';
import useSocket from './hooks/socket/use-socket';

const countChatDisplay = 1;

const RawAppComp: React.FC = () => {
  const queryURL = window.location.pathname;
  const additionalTitleBar = SetDynamicTitleBar(queryURL);
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData<{ success: boolean; user_info: UserSchema }>(['user']);
  const history = useHistory();
  const fetchRecentChat = useFetchPatientsRecentChat(user?.user_info._id);
  const chatPatientID = useRecoilValue(ChatPatientIDState);
  // const isVisible = usePageVisibility();
  const socket = useSocket();

  const [currentUser, setUser] = useRecoilState(UserState);
  const [notificationMessages, setNotificationMessages] = useState<{ chat_message: TMessageSchema; patient_info: TPatientInfoWithIncomingMessage }[]>([]);
  const setRecentChatState = useSetRecoilState(RecentChatState);
  const [unreadChatState, setUnreadChatState] = useRecoilState(UnreadChatState);
  const [showTitle, setShowTitle] = useState('LiverCU');
  const notificationSound = new Audio('/static/sound/mixkit-bell-notification-933.wav');

  let timeout: NodeJS.Timeout | null;
  const handleSetTimeOutNotiTitle = () => {
    if (timeout !== null) { clearTimeout(timeout); }
    timeout = setTimeout(() => {
      if (unreadChatState > 0) {
        setShowTitle(`(${unreadChatState}) LiverCU${additionalTitleBar}`);
      } else {
        setShowTitle(`LiverCU${additionalTitleBar}`);
      }
    }, 4000);
  };

  const handleClickMessageBadge = (notiMessage: { chat_message: TMessageSchema; patient_info: TPatientInfoWithIncomingMessage }) => {
    history.push(`/chat/${notiMessage.chat_message.room_id}`);
  };

  useEffect(() => {
    if (unreadChatState > 0) {
      setShowTitle(`คุณได้รับข้อความใหม่ ${unreadChatState} ข้อความ - LiverCU${additionalTitleBar}`);
      // notificationSound.play();
    }
    handleSetTimeOutNotiTitle();
  }, [unreadChatState]);

  useEffect(() => {
    if (user?.user_info) {
      setUser(user.user_info);
    }
  }, [user?.user_info]); // to set user

  useEffect(() => {
    if (authTokenLocalStorage.get()) {
      socket.open(config.socketIOAPIPath, authTokenLocalStorage.get()!);
    }
  }, []);

  const getRefreshRecentChats = () => {
    fetchRecentChat.refetch();
  };

  useEffect(() => {
    if (currentUser.user_type === UserType.Nurse) {
      socket.subAdminMessage((res: { chat_message: TMessageSchema; patient_info: TPatientInfoWithIncomingMessage }) => {
        let newChatMessage = res.chat_message;
        if (window.location.pathname.includes(`/chat/${newChatMessage.room_id}`)) {
          newChatMessage = {
            ...newChatMessage,
            is_read: true,
          };
          const emitData: TEmitServerRead = {
            last_id: newChatMessage.last_id,
            room_id: newChatMessage.room_id,
            send_by: newChatMessage.send_by,
            send_by_user_type: newChatMessage.send_by_user_type,
            read_by: currentUser.user_type,
          };
          console.log('App.tsx => server read 107');

          socket?.emitServerRead(
            emitData,
            () => {
              setRecentChatState((prevState) => {
                if (prevState.length > 0) {
                  const foundExsitedPatientChat = prevState.find((element) => element._id === res.chat_message.room_id);
                  if (foundExsitedPatientChat) {
                    const indexOfPatient = prevState.indexOf(foundExsitedPatientChat);
                    if (foundExsitedPatientChat.messages.length > 0) {
                      const newMessageChat = prevState[indexOfPatient].messages.map((msg) => {
                        if (msg.last_id <= res.chat_message.last_id && msg.send_by_user_type === UserType.Patient) {
                          return { ...msg, is_read: true };
                        }
                        return msg;
                      }).sort(((a, b) => new Date(a.created_at).valueOf() - new Date(b.created_at).valueOf()));
                      return [...prevState.slice(0, indexOfPatient), { ...prevState[indexOfPatient], messages: newMessageChat }, ...prevState.slice(indexOfPatient + 1)];
                    }
                  }
                }
                return [...prevState];
              });
              setUnreadChatState(unreadChatState > 0 ? unreadChatState - 1 : 0);
            },
          );
          setNotificationMessages([]);
          getRefreshRecentChats();
        } else {
          setShowTitle(`คุณได้รับข้อความใหม่ - LiverCU${additionalTitleBar}`);
          handleSetTimeOutNotiTitle();
          if (res.chat_message.send_by_user_type === UserType.Patient) {
            setNotificationMessages((prevMessage) => [...prevMessage, res]);
            setTimeout(() => {
              setNotificationMessages((prevMessage) => prevMessage.slice(1));
            }, 5000); // to remove noti messages
          }
          getRefreshRecentChats();
        }
      });
      socket.subAdminRead((res: { chat_message: TMessageSchema; patient_info: TPatientInfoWithIncomingMessage }) => {
        const newChatMessage = res.chat_message;
        if (window.location.pathname.includes(`/chat/${newChatMessage.room_id}`)) {
          setNotificationMessages([]);
          getRefreshRecentChats();
        } else {
          setShowTitle(`คุณได้รับข้อความใหม่ - LiverCU${additionalTitleBar}`);
          handleSetTimeOutNotiTitle();
          if (res.chat_message.send_by_user_type === UserType.Patient) {
            setNotificationMessages((prevMessage) => [...prevMessage, res]);
            setTimeout(() => {
              setNotificationMessages((prevMessage) => prevMessage.slice(1));
            }, 5000); // to remove noti messages
          }
          getRefreshRecentChats();
        }
      });
    }
    return (() => {
      socket.unsubAdminMessage();
      socket.unsubAdminRead();
    });
  }, [socket.isConnected, currentUser]); // socket listen to any change of incoming message

  useEffect(() => {
    let countUnreadChat = 0;
    if (fetchRecentChat.isSuccess && fetchRecentChat.data?.success && fetchRecentChat.data.recent_chat) {
      if (fetchRecentChat.data.recent_chat.length > 0) {
        const unReadChats = fetchRecentChat.data.recent_chat.filter(chat => !chat.last.is_read && chat.last.send_by_user_type === UserType.Patient);
        if (unReadChats && unReadChats.length > 0) {
          countUnreadChat = unReadChats.length;
        }
        setUnreadChatState(countUnreadChat);
        setRecentChatState(fetchRecentChat.data.recent_chat);
      }
    }
  }, [fetchRecentChat.isSuccess]);

  useEffect(() => {
    let countUnreadChat = 0;
    if (fetchRecentChat.isSuccess && fetchRecentChat.data?.success && fetchRecentChat.data.recent_chat) {
      if (fetchRecentChat.data.recent_chat.length > 0) {
        const unReadChats = fetchRecentChat.data.recent_chat.filter(chat => !chat.last.is_read && chat.last.send_by_user_type === UserType.Patient);
        if (unReadChats && unReadChats.length > 0) {
          countUnreadChat = unReadChats.length;
        }
        setUnreadChatState(countUnreadChat);
        setRecentChatState(fetchRecentChat.data.recent_chat);
      }
    }
  }, [fetchRecentChat.isRefetching]);

  return (
    <SocketContext.Provider value={socket}>
      {/* <DynamicTitle title={showTitle} /> */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>{showTitle}</title>
      </Helmet>
      <Layout>
        {user?.user_info.user_type === UserType.Nurse && notificationMessages.length > 0 && countChatDisplay <= 2 && (
          <div
            className="absolute z-10 w-2/12 top-20 right-6"
          >
            {notificationMessages.length > 0 && notificationMessages.map((notiMessage) => {
              return (
                <div key={notiMessage.chat_message._id} onClick={() => handleClickMessageBadge(notiMessage)}>
                  <motion.div
                    className="flex flex-row items-center w-full p-2 my-2 transition duration-500 ease-linear bg-white border-2 shadow-lg cursor-pointer gap-x-2 rounded-2xl"
                    style={{ borderColor: '#FA541C' }}
                  >
                    <div className="w-1/5">
                      <AvatarImage size={32} imgURL={notiMessage.patient_info.avatar_img_url} />
                    </div>
                    <div className="flex flex-col w-full overflow-hidden">
                      <p className="text-base font-bold truncate text-primary">คุณ{notiMessage.patient_info.first_name} {notiMessage.patient_info.last_name}</p>
                      <p className="text-base font-normal truncate opacity-80">{notiMessage.chat_message.message.text}</p>
                    </div>
                  </motion.div>
                </div>
              );
            })}
          </div>
        )}
        <Routes />
      </Layout>
    </SocketContext.Provider>
  );
};

const AppComp = React.memo(RawAppComp, () => true);

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const App: React.FC = () => {
  const [initialized, setInitialized] = useState(false);
  const query = useQuery();
  const { refetch: refetchUser, data } = useFetchUser();

  // localStorage.setItem('current-date', new Date().toDateString());

  useEffect(() => {
    // eslint-disable-next-line no-console
    /* [start] auth_token */
    const authToken = query.get('auth_token');
    if (authToken) authTokenLocalStorage.set(authToken);
    const token = authTokenLocalStorage.get();
    /* [end] auth_token */

    if (!token) {
      setInitialized(true);
    } else {
      // eslint-disable-next-line no-console
      refetchUser()
        .finally(() => {
          // eslint-disable-next-line no-console
          setInitialized(true);
        });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!initialized) return <div><Loading /></div>;

  if (!data) return <div><LoginPage /></div>;
  return (
    <AppComp />
  );
};

export default React.memo(App, () => true);
