/* eslint-disable react/require-default-props */
import Select from 'antd/es/select';
import React from 'react';
import { useQueryClient } from 'react-query';
import { NavLink } from 'react-router-dom';
import { UserType } from '../constant-enum-type';
import { UserSchema } from '../form-schema/user/UserSchema';
import { CancelButton } from './Button';

const { Option } = Select;
interface Props {
  buttonLabel?: string;
  onChangeSearchType?: (type: string) => void;
  onChangeSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isFullWidth?: boolean;
}

let linkTo = '';

const SearchBar: React.FC<Props> = (props) => {
  const { buttonLabel, onChangeSearchType, onChangeSearch, isFullWidth } = props;

  const queryClient = useQueryClient();
  const userQuery = queryClient.getQueryData<{ success: boolean; user_info: UserSchema }>(['user']);

  if (buttonLabel === '+ Add New Patient') {
    linkTo = '/patient/add-patient';
  }
  if (buttonLabel === '+ Add New Nurse') {
    linkTo = '/nurse/add-nurse';
  }
  if (buttonLabel === '+ Add New Physician') {
    linkTo = '/doctor/add-doctor';
  }

  if (isFullWidth || userQuery?.user_info.user_type === UserType.Doctor) {
    return (
      <div className="flex flex-row w-full h-auto">
        <input
          className="items-center w-full px-2 py-1 text-base font-normal align-middle outline-none rounded-3xl custom-search-box"
          onChange={onChangeSearch}
          placeholder="Search..."
        />
      </div>
    );
  }

  return (
    <div className="flex flex-row w-full h-auto">
      {
        onChangeSearchType !== undefined ? (
          <>
            <Select
              className="w-52 py-1 bg-white border rounded-l-3xl outline-none custom-search-box text-base font-normal"
              bordered={false}
              defaultValue="hn"
              onChange={onChangeSearchType}
              dropdownStyle={{ borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }}
            >
              <Option value="hn">HN</Option>
              <Option value="first_name">ชื่อ</Option>
              <Option value="last_name">นามสกุล</Option>
              <Option value="diagnosis_code">Code Diagnosis</Option>
              <Option value="code_id">Code ID</Option>
            </Select>
            <input
              className="items-center w-full px-2 py-1 text-base font-normal align-middle outline-none rounded-r-3xl custom-search-box"
              onChange={onChangeSearch}
              placeholder="Search..."
            />
          </>
        ) : (
          <input
            className="items-center w-full px-2 py-1 text-base font-normal align-middle outline-none rounded-3xl custom-search-box"
            onChange={onChangeSearch}
            placeholder="Search..."
          />
        )
      }
      <CancelButton
        className="h-full ml-2 w-max"
      >
        <NavLink to={linkTo}>
          <p className="font-normal sm:text-xs md:text-xs lg:text-xs xl:text-base whitespace-nowrap overflow-ellipsis">
            {buttonLabel}
          </p>
        </NavLink>
      </CancelButton>
    </div>
  );
};

export default SearchBar;
