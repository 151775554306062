/* eslint-disable react/require-default-props */
import React from 'react';
import Select from 'antd/es/select';
import { FormikProps } from 'formik';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import useFetchPatients from '../../connecting-api/patient/hooks/use-fetch-patients';
import { TModifiedAppointment } from '../../form-schema/appointments/AppointmentSchema';
import { UserSortType } from '../../constant-enum-type';

interface ISelectPatientsComponentProps {
  disabled?: boolean;
  formik: FormikProps<TModifiedAppointment>;
}

const SelectPatientsComponent: React.FC<ISelectPatientsComponentProps> = (props) => {
  const { formik, disabled } = props;
  const patientsQuery = useFetchPatients(0, 0, '', UserSortType.name_th_desc);

  if (patientsQuery.status !== 'success' || !patientsQuery.data.success) return <div><LoadingOutlined /></div>;

  return (
    <Select
      id="patient_id"
      bordered={false}
      disabled={disabled}
      className={`${!!disabled && 'bg-gray-100 border-transparent'} items-center w-full text-base border-2 outline-none rounded-3xl`}
      dropdownStyle={{ borderRadius: 20 }}
      showSearch
      suffixIcon={<SearchOutlined className="text-base" />}
      notFoundContent={<p className="text-sm opacity-60">ไม่มีข้อมูล</p>}
      value={formik.values.patient_id === '' ? '' : formik.values.patient_id}
      filterOption={(input, option) => (option?.label as string).toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0}
      onChange={(patientID) => formik.setFieldValue('patient_id', patientID.toString())}
      options={patientsQuery.data.patients.map((patient) => ({ label: `${patient.hn} - ${patient.first_name} ${patient.last_name}`, value: patient._id }))}
    />
  );
};

export default SelectPatientsComponent;
