/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import { TNurse, TNurseEdit, TNurseInitialValue } from '../../form-schema/nurses/NurseSchema';
import { APIResponse } from '../../constant-enum-type';
import api from '../../utils/api';
import { NurseResponses } from '../../components/form/common/responses';

export type FetchNursesResponse = APIResponse<{ nurse: TNurse[] }>;
export type FetchNurseByIDResponse = APIResponse<{ success: boolean; nurse: TNurse }>;

export const fetchNurses = (): Promise<FetchNursesResponse> => {
  return api
    .get('/nurses')
    .then((res) => res.data);
};

export const addNurse = (body: TNurseInitialValue): Promise<NurseResponses> => {
  return api
    .post('/nurses', body)
    .then((res) => res.data);
};
export const fetchNurseByID = (id: string): Promise<FetchNurseByIDResponse> => {
  if (!id) return Promise.resolve({ success: false } as APIResponse<FetchNurseByIDResponse>);
  return api
    .get(`/nurses/${id}`)
    .then((res) => res.data);
};

export const editNurseByID = (data: { id: string; body: TNurseEdit }): Promise<NurseResponses> => {
  return api
    .put(`/nurses/${data.id}`, data.body)
    .then((res) => res.data);
};
