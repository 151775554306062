import React from 'react';
import cx from 'classnames';
import { PersonalNoteChronicHBVType } from '../../../common/components/modals/PersonalNoteModal/type/PersonalNoteUD/PersonalNoteChronicHBV';

const PersonalNoteChronicHBVResult: React.FC<{
  value: PersonalNoteChronicHBVType;
}> = ({ value }) => {
  if (!value.isChecked) {
    return <div />;
  }
  const isSomeChecked = value.hbsAg.isChecked || value.hbeAg.isChecked || value.hccPredictation.isChecked || value.onMedication.isChecked;

  const hccPredictationConditionValue = value.hccPredictation.selected?.condition_value ?? 99;
  const isOverScore = hccPredictationConditionValue <= value.hccPredictation.totalScore;

  return (
    <div className="pb-4">
      <p className="pb-2 text-base font-bold">Chronic HBV</p>
      {!isSomeChecked ? (
        <p>-</p>
      ) : (
        <div className={cx('px-4 py-2 bg-gray-100 rounded-lg flex flex-col')}>
          {value.hbsAg.isChecked && (
            <div className="pb-2">
              <p className="text-base font-bold">HBsAg</p>
              <p className="text-base text-gray-500">
                {value.hbsAg.selected || '-'}
              </p>
            </div>
          )}
          {value.hbeAg.isChecked && (
            <div className="pb-2">
              <p className="text-base font-bold">HBeAg</p>
              <p className="text-base text-gray-500">
                {value.hbsAg.selected || '-'}
              </p>
            </div>
          )}
          {value.onMedication.isChecked && (
            <div className="pb-2">
              <p className="text-base font-bold">On medication</p>
              <p className="text-base text-gray-500">
                {value.onMedication.selected || '-'}
              </p>
            </div>
          )}
          {value.hccPredictation.isChecked && (
            <div className="pb-2">
              <p className="text-base font-bold">HCC Predictation</p>
              <p className="text-base text-gray-500">
                {value.hccPredictation.selected?.name ?? '-'} &nbsp;&nbsp;
                <span
                  className={cx({
                    'text-red-500': isOverScore,
                  })}
                >
                  {value.hccPredictation.selected && value.hccPredictation.totalScore}
                </span>
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PersonalNoteChronicHBVResult;
