/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import {
  Checkbox,
  DatePicker,
  Modal, Radio,
} from 'antd';
import Select from 'antd/es/select';
import TextArea from 'antd/lib/input/TextArea';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import { useFormik } from 'formik';
import locale from 'antd/es/date-picker/locale/th_TH';
import useCreateAppointment from '../../common/connecting-api/appointment/hooks/use-create-appointment';
import { TModifiedAppointment, validationSchema } from '../../common/form-schema/appointments/AppointmentSchema';
import { PrimaryButton } from '../../common/components/Button';
import { ErrorMsg } from '../../common/components/Lable';
import AppointmentModal from '../../common/components/modals/AppointmentModal';
import { UserSchema } from '../../common/form-schema/user/UserSchema';
import SelectDoctorsComponent from '../../common/components/persons/SelectDoctorsComponent';
import { AppointmentType } from '../../common/constant-enum-type';
import { TDoctor } from '../../common/form-schema/doctors/DoctorSchema';
import ELearningNotification from '../../patient-appointments/ELearningNotification';
import { future100Years } from '../../common/birth-date-data';
import { CreateAppointmentResponse } from '../../common/connecting-api/appointment/api-action';
import TinyMECInput from '../../common/components/TinyMECInput';

const { Option } = Select;

const checkUnavailabilityDay = (day: moment.Moment | string, unavailableDaysOfWeek: string[]) => {
  if (moment(day).startOf('day').isBefore(moment().startOf('day'))) return true;
  return unavailableDaysOfWeek.some((d) => {
    return moment(day).locale('en').format('dddd') === d;
  });
};

interface Props {
  handleClickPatientMenu: (key: string) => void;
}

const CreateAppointmentInPatientPage: React.FC<Props> = (props) => {
  const { handleClickPatientMenu } = props;
  const { id } = useParams<{ id: string }>();
  const patientID = id;

  const { mutate: createAppointment } = useCreateAppointment();
  const enableReinitialize = true;

  const queryClient = useQueryClient();
  const userQuery = queryClient.getQueryData<{ success: boolean; user_info: UserSchema }>(['user']);

  const [clearWhenSuccessCreate, setClearWhenSuccessCreate] = useState(false);
  const [doctorUnavailableDaysOfWeek, setDoctorUnavailableDaysOfWeek] = useState<string[]>([]);

  const [focusAppointmentDate, setFocusAppointment] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues: TModifiedAppointment = {
    appointment_date: undefined,
    patient_id: patientID,
    doctor_id: '',
    appointment_type: '',
    blood_test: 'not_required',
    is_ultrasound: false,
    doctor_note: '',
    nurse_note: '',
    mark_doctor_note_as_important: false,
    e_learning: [],
    avoid_food: false,
  };

  const handleAlertError = () => {
    Modal.error({
      title: 'เกิดข้อผิดพลาด!',
      content: 'มีข้อผิดพลาดเกิดขึ้น กรุณาลองใหม่อีกครั้งภายหลัง',
      okText: ' Close ',
    });
  };

  const handleReload = (appointmentDate: string, res: CreateAppointmentResponse) => {
    if (res.success) {
      const monthYearDatePointer = moment(appointmentDate).format('MMM YYYY');
      queryClient.invalidateQueries(['get_appointments', monthYearDatePointer, (userQuery?.success ? `${userQuery.user_info._id}_${userQuery.user_info.user_type}` : 'no_user')]);
      queryClient.invalidateQueries(['incoming_appointments_by_patient_id', id]);
      queryClient.invalidateQueries(['all_medical_note', res.appointment.patient_id]);
    }
  };

  const onSubmit = (values: TModifiedAppointment) => {
    if (isSubmitting === false) {
      setIsSubmitting(true);
      let appointmentDate = values.appointment_date;
      if (values.appointment_type === AppointmentType.onsite) {
        const newDateToSaveOnsite = moment(values.appointment_date)
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .toISOString();
        appointmentDate = newDateToSaveOnsite;
      }
      const valueToCreate = {
        e_learning: values.e_learning,
        is_ultrasound: values.is_ultrasound,
        mark_doctor_note_as_important: values.mark_doctor_note_as_important,
        nurse_note: values.nurse_note && values.nurse_note.trim(),
        doctor_note: values.doctor_note && values.doctor_note.trim(),
        appointment_date: appointmentDate,
        patient_id: values.patient_id,
        doctor_id: values.doctor_id,
        appointment_type: values.appointment_type,
        blood_test: values.blood_test,
        created_by: userQuery?.user_info._id,
        avoid_food: values.avoid_food,
      };
      createAppointment(valueToCreate, {
        onSuccess: (res) => {
          if (res.success) {
            const appointmentDateISO = res.appointment.appointment_date;
            const momentAppointmentDate = moment(appointmentDateISO);
            const successModal = AppointmentModal(momentAppointmentDate.toISOString(), false);
            handleReload(momentAppointmentDate.toISOString(), res);
            setClearWhenSuccessCreate(true);
            setTimeout(() => {
              successModal.destroy();
            }, 4000);
            setTimeout(() => {
              setIsSubmitting(false);
            }, 500);
            handleClickPatientMenu('appointments');
          }
        },
        onError: handleAlertError,
      });
    }
  };

  const formik = useFormik<TModifiedAppointment>({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize,
  });

  const {
    handleSubmit,
    errors,
    setFieldValue,
    values,
    touched,
    resetForm,
  } = formik;

  const handleResetForm = () => {
    resetForm();
  };

  const [year, setYear] = useState(values.appointment_date === undefined ? (moment().year() + 543).toString() : moment(values.appointment_date).year() + 543);
  useEffect(() => {
    if (values.appointment_date) {
      const copiedDate = values.appointment_date;
      const yearMinus543 = Number(year) - 543; // convert the selected year to christian-year
      const onlyYearOfAppointmentdate = `${values.appointment_date[0]}${values.appointment_date[1]}${values.appointment_date[2]}${values.appointment_date[3]}`;
      const changedDate = copiedDate.replace(onlyYearOfAppointmentdate, yearMinus543.toString());
      setFieldValue('appointment_date', changedDate);
    }
  }, [year]);

  useEffect(() => {
    handleResetForm();
    setFieldValue('appointment_date', undefined);
    setFieldValue('patient_id', patientID);
    setFieldValue('doctor_id', '');
    setFieldValue('appointment_type', '');
    setFieldValue('blood_test', 'not_required');
    setFieldValue('is_ultrasound', false);
    setFieldValue('avoid_food', false);
    setFieldValue('doctor_note', '');
    setFieldValue('nurse_note', '');
    setFieldValue('mark_doctor_note_as_important', false);
    setFieldValue('e_learning', []);
  }, [clearWhenSuccessCreate]);

  const onDoctorIsChanged = (doctor?: TDoctor) => {
    setDoctorUnavailableDaysOfWeek(doctor?.unavailable_day ?? ['Free']);
    setFieldValue('appointment_date', undefined);
    setFieldValue('doctor_id', doctor?._id);
  };

  const isDoctorNoteEmpty = !values.doctor_note || values.doctor_note === '';
  useEffect(() => {
    if (isDoctorNoteEmpty && values.mark_doctor_note_as_important) {
      setFieldValue('mark_doctor_note_as_important', false);
    }
  }, [isDoctorNoteEmpty, setFieldValue, values.mark_doctor_note_as_important]);

  return (
    <form onSubmit={handleSubmit} className="py-4 mb-4 xl:px-20 lg:px-50 md:text-sm lg:text-base 2xl:px-52">
      <div className="order-1 mb-4">
        <p className="text-base font-medium">แพทย์</p>
        <div className="h-10">
          <SelectDoctorsComponent formik={formik} onDoctorIsChanged={onDoctorIsChanged} />
        </div>
        {errors.doctor_id && touched.doctor_id && <ErrorMsg>{errors.doctor_id}</ErrorMsg>}
      </div>
      <div className="order-2 mb-4">
        <p className="text-base font-medium">ประเภทการนัดหมาย</p>
        <Select
          id="appointment_type"
          value={values.appointment_type === '' ? '' : values.appointment_type}
          onChange={(value: string) => setFieldValue('appointment_type', value)}
          bordered={false}
          className="h-10 min-w-full text-base font-normal border-2 outline-none opacity-80 rounded-3xl"
          dropdownStyle={{ borderRadius: 20 }}
        >
          <Option value="onsite">On-site</Option>
          <Option value="online">Online</Option>
        </Select>
        {errors.appointment_type && touched.appointment_type && <ErrorMsg>{errors.appointment_type}</ErrorMsg>}
      </div>
      <div className="flex flex-col order-3 w-full mb-4">
        <div className="flex-col w-full">
          <p className="font-medium">วันนัดหมาย</p>
          <div className="flex flex-row w-full">
            <div className="w-full mr-2">
              <DatePicker
                disabled={!values.doctor_id && !values.appointment_type}
                className="h-10 min-w-full px-3 font-normal bg-center border-2 opacity-90 rounded-3xl"
                name="appointment_date"
                showTime={values.appointment_type === AppointmentType.online && { format: 'HH:mm' }}
                format={values.appointment_type === AppointmentType.online ? 'Do MMMM เวลา HH.mm น.' : 'Do MMMM'}
                direction="rtl"
                inputReadOnly
                locale={locale}
                placeholder="เลือกวันนัดหมาย"
                disabledDate={(current) => checkUnavailabilityDay(current, doctorUnavailableDaysOfWeek)}
                popupStyle={{ borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }}
                value={values.appointment_date ? moment(values.appointment_date) : undefined}
                onChange={(date) => setFieldValue('appointment_date', date?.toISOString())}
                onFocus={() => setFocusAppointment(true)}
                onBlur={() => setFocusAppointment(false)}
              />
            </div>
            <div className="w-3/12">
              <Select
                disabled={(!values.doctor_id && !values.appointment_type) || !values.appointment_date}
                className={`${(!values.doctor_id && !values.appointment_type) || !values.appointment_date ? 'bg-gray-100' : ''} items-center w-full h-10 text-base border-2 outline-none rounded-3xl`}
                dropdownStyle={{ borderRadius: 20 }}
                value={year}
                bordered={false}
                options={future100Years.map((value) => ({ label: value, value }))}
                onChange={(value) => setYear(value)}
              />
            </div>
          </div>
        </div>
        {(errors.appointment_date && touched.appointment_date)
        || (!values.appointment_date && !focusAppointmentDate)
          ? <ErrorMsg>{errors.appointment_date}</ErrorMsg>
          : null}
      </div>
      {values.appointment_type === AppointmentType.onsite && (
        <>
          <div className="order-4 mb-4 text-base">
            <p className="font-medium">การตรวจเลือด</p>
            <div className="font-normal">
              <Radio.Group
                id="blood_test"
                onChange={(e) => setFieldValue('blood_test', e.target.value)}
                name="blood_test"
                value={values.blood_test !== 'not_required' ? values.blood_test : 'not_required'}
                defaultValue={values.blood_test}
                className="flex-col md:flex"
              >
                <Radio className="md:text-sm lg:text-base" value="before_two_weeks">อย่างน้อย 2 สัปดาห์</Radio>
                <Radio className="md:text-sm lg:text-base" value="before_one_week">อย่างน้อย 1 สัปดาห์</Radio>
                <Radio className="md:text-sm lg:text-base" value="morning">ช่วงเช้าก่อนพบแพทย์</Radio>
                <Radio className="md:text-sm lg:text-base" value="not_required">ไม่ต้องตรวจเลือด</Radio>
              </Radio.Group>
            </div>
          </div>
          <div className="order-5 mb-4 text-base">
            <p className="font-medium">การงดอาหาร</p>
            <div className="font-normal">
              <Radio.Group
                id="avoid_food"
                name="avoid_food"
                onChange={(e) => setFieldValue('avoid_food', e.target.value)}
                value={values.avoid_food !== false ? values.avoid_food : false}
                defaultValue={values.avoid_food}
              >
                <Radio className="md:text-sm lg:text-base" value>งดอาหาร 8-10 ชม. ก่อนตรวจเลือด</Radio>
                <Radio className="md:text-sm lg:text-base" value={false}>ไม่ต้องงดอาหาร</Radio>
              </Radio.Group>
            </div>
          </div>
          <div className="order-6 mb-4 text-base">
            <p className="font-medium">Ultrasound</p>
            <div className="font-normal">
              <Radio.Group
                id="is_ultrasound"
                name="is_ultrasound"
                onChange={(e) => setFieldValue('is_ultrasound', e.target.value)}
                value={values.is_ultrasound !== false ? values.is_ultrasound : false}
                defaultValue={false}
              >
                <Radio className="md:text-sm lg:text-base" value>ต้อง Ultrasound</Radio>
                <Radio className="md:text-sm lg:text-base" value={false}>ไม่ต้อง Ultrasound</Radio>
              </Radio.Group>
            </div>
          </div>
        </>
      )}
      {/* <div className="order-7 mb-4 text-base">
        <p className="font-medium">Doctor Note</p>
        <TinyMECInput
          inputValue={values.doctor_note}
          handleChangeValue={(value) => setFieldValue('doctor_note', value)}
        />

        <Checkbox
          className="text-sm"
          name="mark_doctor_note_as_important"
          checked={!!values.mark_doctor_note_as_important}
          onChange={(e) => setFieldValue('mark_doctor_note_as_important', !!e.target.checked)}
          disabled={values.doctor_note?.length === 0}
        >
          Medical History View
        </Checkbox>
      </div> */}
      <div className="order-8 mb-4 text-base">
        <p className="font-medium">Nurse Note</p>
        <TinyMECInput
          inputValue={values.nurse_note}
          handleChangeValue={(value) => setFieldValue('nurse_note', value)}
        />
      </div>
      <div className="order-9 mb-4 text-base">
        <ELearningNotification
          handleChange={(v) => setFieldValue('e_learning', v)}
          value={values.e_learning}
          mediumHeader
        />
      </div>
      <div className="ml-auto w-content">
        {!isSubmitting ? (
          <PrimaryButton htmlType="submit">
            สร้างนัดหมาย
          </PrimaryButton>
        ) : <div>Loading...</div>}
      </div>
    </form>
  );
};

export default CreateAppointmentInPatientPage;
