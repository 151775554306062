/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useQuery } from 'react-query';
import { UserSortType } from '../../../constant-enum-type';
import { fetchPatients, FetchPatientsResponse as _FetchPatientsResponse } from '../api-action';

export type FetchPatientsResponse = _FetchPatientsResponse;

const useFetchPatients = (skip: number, limit: number, searchKey: string, sortType: UserSortType) => {
  return useQuery(
    [skip, limit, searchKey, sortType],
    () => fetchPatients(skip, limit, searchKey, sortType),
    { refetchOnWindowFocus: false, cacheTime: 3600 },
  );
};

export default useFetchPatients;
