/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { Checkbox } from 'antd';
import PersonalNoteChronicHCV, {
  PersonalNoteChronicHCVType,
  personalNoteChronicHCVNewState,
} from './PersonalNoteChronicHCV';
import PersonalNoteChronicHBV, {
  PersonalNoteChronicHBVType,
  personalNoteChronicHBVNewState,
} from './PersonalNoteChronicHBV';
import {
  EPersonalNoteType,
  TPersonalNoteTemplate,
} from '../../PersonalNoteModal';
import PersonalNoteText, {
  PersonalNoteTextType,
  personalNoteTextNewState,
} from '../common/PersonalNoteText';
import PersonalNoteFattyLiver, {
  PersonalNoteFattyLiverType,
  personalNoteFattyLiverNewState,
} from './PersonalNoteFattyLiver';
import PersonalNoteAutoimmuneHepatitis, {
  PersonalNoteAutoimmuneHepatitisType,
  personalNoteAutoimmuneHepatitisNewState,
} from './PersonalNoteAutoimmuneHepatitis';

export type PersonalNoteUDType = {
  chronicHBV: PersonalNoteChronicHBVType;
  chronicHCV: PersonalNoteChronicHCVType;
  fattyLiver: PersonalNoteFattyLiverType;
  autoimmuneHepatitis: PersonalNoteAutoimmuneHepatitisType;
  others: PersonalNoteTextType;
};

export const personalNoteUDNewState = {
  chronicHBV: personalNoteChronicHBVNewState,
  chronicHCV: personalNoteChronicHCVNewState,
  fattyLiver: personalNoteFattyLiverNewState,
  autoimmuneHepatitis: personalNoteAutoimmuneHepatitisNewState,
  others: personalNoteTextNewState,
};

const PersonalNoteUD: React.FC<{
  personalNote: TPersonalNoteTemplate;
  value: PersonalNoteUDType | null;
  onHandleChange: (_value: PersonalNoteUDType) => void;
}> = ({ personalNote, value, onHandleChange }) => {
  const { key, name, items } = personalNote;

  const [state, setState] = useState<PersonalNoteUDType>(
    value ?? personalNoteUDNewState,
  );

  useEffect(() => {
    onHandleChange(state);
  }, [state, setState]);

  const onChangeChronicHBV = (_value: PersonalNoteChronicHBVType) => {
    setState({
      ...state,
      chronicHBV: _value,
    });
  };

  const onChangeChronicHCV = (_value: PersonalNoteChronicHCVType) => {
    setState({
      ...state,
      chronicHCV: _value,
    });
  };

  const onChangeFattyLiver = (_value: PersonalNoteFattyLiverType) => {
    setState({
      ...state,
      fattyLiver: _value,
    });
  };

  const onChangeAutoimmuneHepatitis = (
    _value: PersonalNoteAutoimmuneHepatitisType,
  ) => {
    setState({
      ...state,
      autoimmuneHepatitis: _value,
    });
  };

  const onChangeOthers = (_value: PersonalNoteTextType) => {
    setState({
      ...state,
      others: _value,
    });
  };

  return (
    <div key={key} className="pb-4">
      <p className="font-bold pb-2">{name}</p>
      <div className="">
        {items?.map((item) => {
          if (item.type === EPersonalNoteType.CHRONIC_HBV) {
            return (
              <PersonalNoteChronicHBV
                key={item.key}
                personalNote={item}
                value={state.chronicHBV}
                onHandleChange={onChangeChronicHBV}
              />
            );
          }
          if (item.type === EPersonalNoteType.CHRONIC_HCV) {
            return (
              <PersonalNoteChronicHCV
                key={item.key}
                personalNote={item}
                value={state.chronicHCV}
                onHandleChange={onChangeChronicHCV}
              />
            );
          }
          if (item.type === EPersonalNoteType.FATTY_LIVER) {
            return (
              <PersonalNoteFattyLiver
                key={item.key}
                personalNote={item}
                value={state.fattyLiver}
                onHandleChange={onChangeFattyLiver}
              />
            );
          }
          if (item.type === EPersonalNoteType.AUTOIMMUNE_HEPATITIS) {
            return (
              <PersonalNoteAutoimmuneHepatitis
                key={item.key}
                personalNote={item}
                value={state.autoimmuneHepatitis}
                onHandleChange={onChangeAutoimmuneHepatitis}
              />
            );
          }
          if (item.type === EPersonalNoteType.OTHERS) {
            return (
              <PersonalNoteText
                key={item.key}
                personalNote={item}
                value={state.others}
                onHandleAnswer={onChangeOthers}
              />
            );
          }
          return <div>{item.name}</div>;
        })}
      </div>
    </div>
  );
};

export default PersonalNoteUD;
