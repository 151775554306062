/* eslint-disable no-await-in-loop */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  Suspense,
  lazy,
} from 'react';
import {
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import LayoutComponent from './features/common/components/LayoutComponent';
import AddNewPatient from './pages/patient/AddNewPatient';
import AddNewNurse from './pages/nurse/AddNewNurse';
import DoctorPage from './pages/doctor/DoctorPage';
import DoctorDetail from './pages/doctor/DoctorDetail';
import AddNewDoctor from './pages/doctor/AddNewDoctor';
import { authTokenLocalStorage } from './features/common/utils/local-storage';
import Loading from './features/common/components/Loading';

const lazyReactNaiveRetry: typeof React.lazy = (importer) => {
  const retryImport = async () => {
    try {
      return await importer();
    } catch (error) {
      // retry 30 times with 1 second delay
      for (let i = 0; i < 30; i += 1) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        try {
          return await importer();
        } catch (e) {
          console.error('retrying import');
        }
      }
      throw error;
    }
  };
  return React.lazy(retryImport);
};

const AppointmentPage = lazyReactNaiveRetry(() => import(/* webpackChunkName: "appointments.page" */'./pages/appointment/AppointmentsPage'));

const PatientPage = lazyReactNaiveRetry(() => import(/* webpackChunkName: "patients.page" */'./pages/patient/PatientsPage'));

const PatientDetail = lazyReactNaiveRetry(() => import(/* webpackChunkName: "patient.page" */'./pages/patient/PatientDetail'));

const XrayPage = lazyReactNaiveRetry(() => import(/* webpackChunkName: "xray.page" */'./pages/xray/XrayPage'));

const NursePage = lazyReactNaiveRetry(() => import(/* webpackChunkName: "nurses.page" */'./pages/nurse/NursePage'));

const NurseDetail = lazyReactNaiveRetry(() => import(/* webpackChunkName: "nurse.page" */'./pages/nurse/NurseDetail'));

const LoginPage = lazyReactNaiveRetry(() => import(/* webpackChunkName: "login.page" */'./pages/auth/LoginPage'));

const NotFound = lazyReactNaiveRetry(() => import(/* webpackChunkName: "not-found.page" */'./features/common/components/NotFound'));

// const NotificationsPage = lazyReactNaiveRetry(() => import(/* webpackChunkName: "patient.page" */'./pages/patient/PatientDetail'));
//   () => import(/* webpackChunkName: "notification.page" */'./pages/notification/NotificationsPage'),
// );

const TeleconferenceDoctorRoomPage = lazyReactNaiveRetry(() => import(/* webpackChunkName: "doctor-room.page" */'./pages/teleconference/doctor-room'));

const TeleconferenceNurseRoomPage = lazyReactNaiveRetry(() => import(/* webpackChunkName: "nurse-room.page" */'./pages/teleconference/nurse-room'));

const ElearningPage = lazyReactNaiveRetry(() => import(/* webpackChunkName: "e-learning.page" */'./pages/e-learning/ElearningPage'));

const PatientSummarypage = lazyReactNaiveRetry(() => import(/* webpackChunkName: "patient-summary.page" */'./pages/patient/PatientSummaryPage'));

const ChatPage = lazyReactNaiveRetry(() => import(/* webpackChunkName: "chat.page" */'./pages/chat/ChatPage'));

const ShortcutMessage = lazyReactNaiveRetry(() => import(/* webpackChunkName: "shortcut-message.page" */'./pages/shortcut-message/ShortcutMessage'));

const EditShortcutMessage = lazyReactNaiveRetry(() => import(/* webpackChunkName: "edit-shortcut-message.page" */'./pages/shortcut-message/EditShortcutMessage'));

const AddShortcutMessage = lazyReactNaiveRetry(() => import(/* webpackChunkName: "add-shortcut-message.page" */'./pages/shortcut-message/AddShortcutMessage'));

const renderPageComponent = (Comp: React.FC) => (
  <Suspense fallback={<div><Loading /></div>}>
    <Comp />
  </Suspense>
);

interface IRoute {
  path: string;
  component: any;
  requireLogin?: boolean;
}

const routes: IRoute[] = [
  {
    path: '/',
    component: renderPageComponent(AppointmentPage),
  },
  {
    path: '/appointments/',
    component: renderPageComponent(AppointmentPage),
  },
  {
    path: '/login',
    component: renderPageComponent(LoginPage),
  },
  {
    path: '/patients',
    component: renderPageComponent(PatientPage),
  },
  {
    path: '/patient/add-patient',
    component: renderPageComponent(AddNewPatient),
  },
  {
    path: '/patient/edit/:id',
    component: renderPageComponent(AddNewPatient),
  },
  {
    path: '/patient/:id',
    component: renderPageComponent(PatientDetail),
  },
  {
    path: '/patient/:id/:appointmentDate',
    component: renderPageComponent(PatientDetail),
  },
  {
    path: '/xray',
    component: renderPageComponent(XrayPage),
  },
  {
    path: '/nurses',
    component: renderPageComponent(NursePage),
  },
  {
    path: '/nurse/add-nurse',
    component: renderPageComponent(AddNewNurse),
  },
  {
    path: '/nurse/edit/:id',
    component: renderPageComponent(AddNewNurse),
  },
  {
    path: '/nurse/:id',
    component: renderPageComponent(NurseDetail),
  },
  {
    path: '/nurse/tele/:id/:patientID',
    component: renderPageComponent(TeleconferenceNurseRoomPage),
  },
  {
    path: '/doctors',
    component: renderPageComponent(DoctorPage),
  },
  {
    path: '/doctor/add-doctor',
    component: renderPageComponent(AddNewDoctor),
  },
  {
    path: '/doctor/edit/:id',
    component: renderPageComponent(AddNewDoctor),
  },
  {
    path: '/doctor/:id',
    component: renderPageComponent(DoctorDetail),
  },
  {
    path: '/doctor/:id/:appointmentDate',
    component: renderPageComponent(DoctorDetail),
  },
  {
    path: '/doctor/tele/:id/:patientID',
    component: renderPageComponent(TeleconferenceDoctorRoomPage),
  },
  {
    path: '/e-learning',
    component: renderPageComponent(ElearningPage),
  },
  {
    path: '/patient-summary',
    component: renderPageComponent(PatientSummarypage),
  },
  {
    path: '/chat',
    component: renderPageComponent(ChatPage),
  },
  {
    path: '/chat/:id',
    component: renderPageComponent(ChatPage),
  },
  {
    path: '/shortcut-message',
    component: renderPageComponent(ShortcutMessage),
  },
  {
    path: '/shortcut-message/create',
    component: renderPageComponent(AddShortcutMessage),
  },
  {
    path: '/shortcut-message/edit/:id',
    component: renderPageComponent(EditShortcutMessage),
  },
  {
    path: '*',
    component: renderPageComponent(NotFound),
  },
];

const withRedirect = (route: IRoute) => {
  const token = authTokenLocalStorage.get();
  if (!token && route.path !== '/login') return <Redirect exact to="/login" />;
  if (token && route.path === '/login') return <Redirect exact to="/" />;
  return route.component;
};

const Routes: React.FC = () => {
  return (
    <LayoutComponent>
      <Switch>
        {routes.map((route: IRoute) => (
          <Route key={route.path} path={route.path} exact>
            {withRedirect(route)}
          </Route>
        ))}
      </Switch>
    </LayoutComponent>
  );
};

export default Routes;
