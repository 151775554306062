/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import { Checkbox } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { TPersonalNoteTemplate } from '../../PersonalNoteModal';

export type PersonalNoteTextType = {
  isChecked: boolean;
  isExpand: boolean;
  text: string;
};

export const personalNoteTextNewState = {
  isChecked: false,
  isExpand: false,
  text: '',
};

const PersonalNoteText: React.FC<{
  personalNote: TPersonalNoteTemplate;
  value: PersonalNoteTextType | null;
  onHandleAnswer: (data: PersonalNoteTextType) => void;
}> = ({ personalNote, value, onHandleAnswer }) => {
  const { name } = personalNote;

  const [state, setState] = useState<PersonalNoteTextType>(
    value ?? personalNoteTextNewState,
  );

  const onChangeCheckbox = (checked: boolean) => {
    const newState = {
      ...state,
      isChecked: checked,
      isExpand: checked || state.isExpand,
      text: checked ? state.text : '',
    };
    setState(newState);
    onHandleAnswer(newState);
  };

  const toggleExpand = () => {
    const newState = {
      ...state,
      isExpand: !state.isExpand,
    };
    setState(newState);
    onHandleAnswer(newState);
  };

  const onChangeText = (text: string) => {
    const newState = {
      ...state,
      text,
    };
    setState(newState);
    onHandleAnswer(newState);
  };

  return (
    <div className="p-4 border">
      <div className="flex">
        <div className="flex flex-grow">
          <Checkbox
            name={name}
            className="pr-4"
            checked={state.isChecked}
            onChange={(e) => onChangeCheckbox(e.target.checked)}
          />
          <p>{name}</p>
        </div>
        <div className="flex flex-grow-0 items-center">
          <div onClick={toggleExpand} className=" cursor-pointer">
            <img
              className={`transform transition-transform ${
                state.isExpand ? 'rotate-0' : 'rotate-180'
              }`}
              src="/icons/arrow_down.svg"
              alt="arrow down"
            />
          </div>
        </div>
      </div>
      {state.isExpand && (
        <div
          className="mt-4 p-4 rounded-lg"
          style={{ backgroundColor: '#F8F8F8' }}
        >
          <TextArea
            className="p-2 rounded-xl h-full"
            autoSize={{ minRows: 5, maxRows: 10 }}
            value={value?.text}
            onChange={(e) => onChangeText(e.target.value)}
          />
        </div>
      )}
    </div>
  );
};

export default PersonalNoteText;
