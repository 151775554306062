import { APIResponse } from '../../constant-enum-type';
import { TNameTitle } from '../../form-schema/name-title/NameTitleSchema';
import api from '../../utils/api';

export type FetchNameTitleResponse = APIResponse<{ success: boolean; data: TNameTitle }>;

export const fetchNameTitles = (): Promise<FetchNameTitleResponse> => {
  return api
    .get('/system-configurations/name_title')
    .then((res) => res.data);
};
