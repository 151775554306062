const project = process.env.REACT_APP_PROJECT as 'livercu';

const defaultConfig = {
  uniqueCode: 'j3ygj',
  project,
  apiPath: 'https://staging.livercu.com/api',
  chatMediaUrl: 'https://staging.livercu.com/api/cos/chat/image',
  pdfUrl: 'https://staging.livercu.com/api/cos/attachment',
  socketIOAPIPath: 'https://staging.livercu.com/',
  agora: {
    appId: '160201912e774b9c9392cfe55e4db020',
  },
};

const stagingConfig = {
};

const productionConfig = {
  apiPath: 'https://livercu.com/api',
  chatMediaUrl: 'https://livercu.com/api/cos/chat/image',
  pdfUrl: 'https://livercu.com/api/cos/attachment',
  socketIOAPIPath: 'https://livercu.com/',
  agora: {
    appId: 'b9166be22801431bab7f0260a5e6e26c',
  },
};

const config = {
  ...defaultConfig,
  ...(process.env.REACT_APP_ENV === 'staging' ? stagingConfig : {}),
  ...(process.env.REACT_APP_ENV === 'production' ? productionConfig : {}),
};

export default config;
