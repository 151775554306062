/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useQuery } from 'react-query';
import { fetchNameTitles } from '../api-action';

const useFecthNameTitles = () => {
  return useQuery(
    ['name_titles'],
    () => fetchNameTitles(),
    { refetchOnWindowFocus: false },
  );
};

export default useFecthNameTitles;
