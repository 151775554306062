/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import QuestionCircleTwoTone from '@ant-design/icons/QuestionCircleTwoTone';
import UploadOutlined from '@ant-design/icons/UploadOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';

import { useHistory, useParams } from 'react-router-dom';
import {
  Checkbox,
  Popover,
} from 'antd';
import Select from 'antd/es/select';
import Avatar from 'antd/lib/avatar/avatar';
import 'moment/locale/th';
import moment from 'moment-timezone';
import { useQueryClient } from 'react-query';
import {
  useFormik,
} from 'formik';
// import { MuiBulletedTextArea } from 'react-bulleted-textarea';
// import styled from 'styled-components';
import initialValues, {
  validationSchema,
  TPatientInitialValue,
  XrayType,
  TPatientEdit,
} from '../../form-schema/patients/PatientSchema';
import { InputBox, TextAreaInput } from '../Input';
import { ErrorMsg, HeadText } from '../Lable';
import { SaveButton } from '../Button';
import useAddPatient from '../../connecting-api/patient/hooks/use-add-patient';
import useEditPatientByID from '../../connecting-api/patient/hooks/use-edit-patient-by-id';
import ValidateCitizenID from '../../validate-citizen-id';
import config from '../../../../config';
import useUploadPersonAvatar from '../../connecting-api/person-info/hooks/use-upload-person-avatar';
import useFetchPatientByID from '../../connecting-api/patient/hooks/use-fetch-patient-by-id';
import Loading from '../Loading';
import { UserSchema } from '../../form-schema/user/UserSchema';
import ValidateCodeDiagnosis from '../../validate-code-diagnosis';
import ValidateCodeID from '../../validate-code-id';
import { dates, months, years } from '../../birth-date-data';
import { NameTitle as EnumNameTitle, MedicalBenefitScheme as EnumMedicalBenefitScheme, UserType } from '../../constant-enum-type';
import NameTitlesPatient from '../../../appointment/components/NameTitlesPatient';
import { handleCalculateAge } from '../../all-about-string-method';
import { isRequireEGD } from '../../utils/patient';
import HandleInitDateOfBirth, { DateInterface } from '../../handle-init-date-of-birth';
import { ErrorType, FailedModal, ModalData, SuccessModal } from './common/modals/ReponsesModal';
import { ErrorText, PatientResponse } from './common/responses';
import TinyMECInput from '../TinyMECInput';

const { Option } = Select;

// const StyledBulletedTextArea = styled(MuiBulletedTextArea)`
//   width: 100%;
// `;

interface XrayDateInterface { date: string | undefined; month: string | undefined; year: string | undefined }

const AddNewPatientForm: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const { mutate: addPatient } = useAddPatient();
  const { mutate: editPatient } = useEditPatientByID();
  const { mutate: uploadAvatar } = useUploadPersonAvatar();

  const { data, isLoading, status } = useFetchPatientByID(id);

  const queryClient = useQueryClient();
  const queryData = queryClient.getQueryData<{ success: boolean; user_info: UserSchema }>(['user']);
  const user = queryData;

  const [dateOfBirth, setDateOfBirth] = useState<DateInterface>(HandleInitDateOfBirth(data?.success && data.patient ? data.patient : undefined));

  const [mustInputWeight, setMustInputWeight] = useState(false);
  const [mustInputHeight, setMustInputHeight] = useState(false);

  const [birthYearError, setBirthYearError] = useState(false);
  const [birthDateForCalAge, setBirthDateForCalAge] = useState('');
  const [birthMonthForCalAge, setBirthMonthForCalAge] = useState('');

  const getInitXrayDate = (date?: Date) => {
    if (
      !data?.success
      || (data.patient && !data.patient.last_xray_date)
    ) {
      if (date) {
        const momentDate = moment(date);
        return {
          date: momentDate.date().toString(),
          month: (momentDate.month() + 1).toString(),
          year: (momentDate.year() + 543).toString(),
        };
      }
      return { date: undefined, month: undefined, year: undefined };
    }
    const dateData = date || data.patient?.last_xray_date;
    const momentDate = moment(dateData);
    return {
      date: momentDate.date().toString(),
      month: (momentDate.month() + 1).toString(),
      year: (momentDate.year() + 543).toString(),
    };
  };

  const [xrayDate, setXrayDate] = useState<XrayDateInterface>(getInitXrayDate);
  const [egdDate, setEgdDate] = useState<XrayDateInterface>(getInitXrayDate);

  const [inputOtherNameTitle, setInputOtherNameTitle] = useState('');
  const [showInputOtherNameTitle, setShowInputOtherNameTitle] = useState(false);
  const [mustInputOtherNameTitle, setMustInputOtherNameTitle] = useState(false);

  const [isCitizenIDCorrect, setIsCititzenIDCorrect] = useState(true);
  const [isCodeDiagnosisCorrect, setIsCodeDiagnosisCorrect] = useState(true);
  const [isCodeIDCorrect, setIsCodeIDCorrect] = useState(true);

  const [isShowErrorUpload, setIsShowErrorUpload] = useState(false);

  const [inputOtherMedicalBenefitScheme, setInputOtherMedicalBenefitScheme] = useState('');
  const [showInputOtherMedicalBenefitScheme, setShowInputOtherMedicalBenefitScheme] = useState(false);
  const [mustInputOtherMedicalBenefitScheme, setMustInputOtherMedicalBenefitScheme] = useState(false);

  const [HNEmptyError, setHNEmptyError] = useState(false);
  const [HNError, setHNError] = useState(false);

  const handleAlertSuccess = (res: PatientResponse, createdPatient: TPatientEdit | TPatientInitialValue) => {
    if (res.success === false) {
      const failedModalData: ModalData = {
        personType: UserType.Patient,
        text: ErrorType.BACKEND_ERROR,
      };
      if (res.error === ErrorText.DUCPLICATED_CITIZEN_ID) {
        const duplicatedCitizenIDErrorData: ModalData = {
          ...failedModalData,
          exsitedPersonData: res.duplicated_patient,
          personData: createdPatient,
        };
        FailedModal(ErrorType.DUCPLICATED_CITIZEN_ID, duplicatedCitizenIDErrorData);
      } else {
        FailedModal(ErrorType.BACKEND_ERROR, failedModalData);
      }
    } else {
      queryClient.invalidateQueries('xray-notification-count');
      queryClient.invalidateQueries(['patient_by_id', id]);
      const successModalData: ModalData = {
        personType: UserType.Patient,
        text: 'Operation Successfully!',
      };
      const successModal = SuccessModal(successModalData);
      setTimeout(() => {
        successModal.destroy();
      }, 4000);
      history.push('/patients');
    }
  };

  const handleAlertError = () => {
    const apiErrorResponseModal: ModalData = {
      personType: UserType.Patient,
      text: ErrorType.BACKEND_ERROR,
    };
    FailedModal(ErrorType.BACKEND_ERROR, apiErrorResponseModal);
  };

  const onSubmit = (values: TPatientInitialValue) => {
    setBirthYearError(false);
    const validateCitizenID = ValidateCitizenID(values.citizen_id);

    if (!values.height) {
      setMustInputHeight(true);
    } else {
      setMustInputHeight(false);
    }

    if (!values.weight) {
      setMustInputWeight(true);
    } else {
      setMustInputWeight(false);
    }

    if (!validateCitizenID) {
      setIsCititzenIDCorrect(false);
    } else {
      setIsCititzenIDCorrect(true);
      if (values.name_title === EnumNameTitle.other && inputOtherNameTitle.length === 0) {
        setMustInputOtherNameTitle(true);
      } else if (values.medical_benefit_scheme === EnumMedicalBenefitScheme.other && inputOtherMedicalBenefitScheme.length === 0) {
        setMustInputOtherMedicalBenefitScheme(true);
      } else {
        setMustInputOtherMedicalBenefitScheme(false);
        setMustInputOtherNameTitle(false);

        let nameTitleToSave = '';
        let medicalBenefitSchemeToSave = '';

        if (inputOtherNameTitle.trim().length > 0 || values.name_title === EnumNameTitle.other) {
          nameTitleToSave = inputOtherNameTitle;
        } else {
          nameTitleToSave = values.name_title;
        }

        if (inputOtherMedicalBenefitScheme.trim().length > 0 || values.medical_benefit_scheme === EnumMedicalBenefitScheme.other) {
          medicalBenefitSchemeToSave = inputOtherMedicalBenefitScheme;
        } else {
          medicalBenefitSchemeToSave = values.medical_benefit_scheme;
        }

        // DATE OF BIRTH
        let isoStringDate = '';
        let BIRTH_DATE = dateOfBirth.date;
        let BIRTH_MONTH = dateOfBirth.month;
        let BIRTH_YEAR = dateOfBirth.year;

        if (BIRTH_DATE === undefined) {
          BIRTH_DATE = '01';
        }
        if (BIRTH_DATE && BIRTH_DATE.length === 1) {
          BIRTH_DATE = `0${BIRTH_DATE}`;
        }

        if (BIRTH_MONTH === undefined) {
          BIRTH_MONTH = '01';
        }
        if (BIRTH_MONTH && BIRTH_MONTH.length === 1) {
          BIRTH_MONTH = `0${BIRTH_MONTH}`;
        }

        if (BIRTH_YEAR === undefined) {
          // BIRTH_YEAR = '0001';
          setBirthYearError(true);
          return;
        }
        BIRTH_YEAR = (Number(BIRTH_YEAR) - 543).toString();

        isoStringDate = `${BIRTH_YEAR}-${BIRTH_MONTH}-${BIRTH_DATE}T00:00:00.000Z`;

        const value = {
          avatar_img_url: values.avatar_img_url.trim(),
          first_name: values.first_name.trim(),
          last_name: values.last_name.trim(),
          height: Number(values.height),
          weight: Number(values.weight),
          sbp: Number(values.sbp),
          dbp: Number(values.dbp),
          gender: values.gender.trim(),
          medical_benefit_scheme: medicalBenefitSchemeToSave.trim(),
          mobile_number: values.mobile_number.trim(),
          backup_mobile_number: values.backup_mobile_number.trim(),
          telephone: values.telephone.trim(),
          raw_address: values.raw_address.trim(),
          name_title: nameTitleToSave.trim(),
          date_of_birth: isoStringDate,
          personal_note: values.personal_note,
          medication_note: values.medication_note,
          xray_type: values.xray_type,
          last_xray_date: values.last_xray_date,
          is_egd_checked: values.is_egd_checked,
          last_egd_date: values.last_egd_date,
          code_id: values.code_id.trim(),
          diagnosis_code: values.diagnosis_code.trim(),
          hn: values.hn.trim(),
          citizen_id: values.citizen_id.trim(),
        };
        if (user?.success) {
          if (id) {
            const valueToEdit: TPatientEdit = {
              ...value,
            };
            editPatient({ id, body: valueToEdit }, { onSuccess: (res) => handleAlertSuccess(res, valueToEdit), onError: handleAlertError });
          } else {
            const valueToCreate: TPatientInitialValue = {
              ...value,
              code_id: values.code_id.trim(),
              diagnosis_code: values.diagnosis_code.trim(),
              hn: values.hn.trim(),
              citizen_id: values.citizen_id,
              hospital_id: user?.success && user?.user_info.hospital_id,
            };
            addPatient(valueToCreate, { onSuccess: (res) => handleAlertSuccess(res, valueToCreate), onError: handleAlertError });
          }
        }
      }
    }
  };

  const formik = useFormik<TPatientInitialValue>({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const {
    values,
    handleSubmit,
    handleBlur,
    setFieldValue,
    setValues,
    errors,
    touched,
  } = formik;

  const handleFetchImage = (res: any) => {
    setIsShowErrorUpload(false);
    setFieldValue('avatar_img_url', res.avatar_img_url);
  };

  const handleErrorUpload = () => {
    setIsShowErrorUpload(true);
  };

  const handleSelectImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const imageFile = e.target.files?.item(0);
    if (!imageFile) return;
    const fd = new FormData();
    fd.append('file', imageFile, imageFile.name);
    await uploadAvatar(fd, { onSuccess: handleFetchImage, onError: handleErrorUpload });
  };

  const handleSelectXrayType = (type: XrayType | null) => {
    setFieldValue('xray_type', type);
    if (type !== null) {
      const date = getInitXrayDate();
      setXrayDate(date);
    } else {
      setXrayDate({ date: undefined, month: undefined, year: undefined });
    }
  };

  const handleEgdCheck = (checked: boolean) => {
    setFieldValue('is_egd_checked', checked);
    if (checked) {
      const date = getInitXrayDate();
      setEgdDate(date);
    } else {
      setEgdDate({ date: undefined, month: undefined, year: undefined });
    }
  };

  const updateXrayDateFormValue = (newDate: XrayDateInterface) => {
    const processedDate = (newDate.date && newDate.month && newDate.year)
      ? moment(`${newDate.date}/${newDate.month}/${Number(newDate.year) - 543}`, 'D/M/YYYY').toDate()
      : null;
    setFieldValue('last_xray_date', processedDate);
  };

  const onChangeXrayDate = (date: string) => {
    const newDate = { ...xrayDate, date };
    setXrayDate(newDate);
    updateXrayDateFormValue(newDate);
  };

  const onChangeXrayMonth = (month: string) => {
    const newDate = { ...xrayDate, month };
    setXrayDate(newDate);
    updateXrayDateFormValue(newDate);
  };

  const onChangeXrayYear = (year: string) => {
    const newDate = { ...xrayDate, year };
    setXrayDate(newDate);
    updateXrayDateFormValue(newDate);
  };

  // EGD
  const updateEgdDateFormValue = (newDate: XrayDateInterface) => {
    const processedDate = (newDate.date && newDate.month && newDate.year)
      ? moment(`${newDate.date}/${newDate.month}/${Number(newDate.year) - 543}`, 'D/M/YYYY').toDate()
      : null;
    setFieldValue('last_egd_date', processedDate);
  };

  const onChangeEgdDate = (date: string) => {
    const newDate = { ...egdDate, date };
    setEgdDate(newDate);
    updateEgdDateFormValue(newDate);
  };

  const onChangeEgdMonth = (month: string) => {
    const newDate = { ...egdDate, month };
    setEgdDate(newDate);
    updateEgdDateFormValue(newDate);
  };

  const onChangeEgdYear = (year: string) => {
    const newDate = { ...egdDate, year };
    setEgdDate(newDate);
    updateEgdDateFormValue(newDate);
  };

  const formatHN = () => {
    const hospitalNumber = values.hn;
    if (hospitalNumber.length >= 3) {
      if (hospitalNumber.includes('/')) {
        hospitalNumber.replaceAll('/', '');
      }
      if (Number(hospitalNumber) / 1) {
        setHNEmptyError(false);
        setHNError(false);
        const firstPart = hospitalNumber.slice(0, hospitalNumber.length - 2);
        const secondPart = hospitalNumber.slice(hospitalNumber.length - 2, hospitalNumber.length);
        setFieldValue('hn', `${firstPart}/${secondPart}`);
      } else {
        setHNError(true);
      }
    } else if (hospitalNumber.length < 3 && hospitalNumber.length > 0) {
      setHNError(true);
    } else {
      setHNEmptyError(true);
    }
  };

  const removeSlash = () => {
    if (values.hn.includes('/')) {
      setFieldValue('hn', values.hn.replaceAll('/', ''));
    }
  };

  useEffect(() => {
    if (values.hn.length > 0) { // to remove the hn error message when user's typing
      setHNEmptyError(false);
      if (Number(values.hn) / 1) { setHNError(false); }
    } else {
      setHNError(false);
    }
  }, [values.hn]);

  // add new case
  useEffect(() => {
    if (values.citizen_id.length < 13) setIsCititzenIDCorrect(true);
  }, [values.citizen_id]);

  useEffect(() => {
    if (values.diagnosis_code) {
      if (values.diagnosis_code.length < 5) { // set to not show error text while code_diagnosis.lenght is still < 5
        setIsCodeDiagnosisCorrect(true);
        if (values.is_egd_checked) {
          handleEgdCheck(false);
        }
      } else {
        const isValidateCodeDiagnosis = ValidateCodeDiagnosis(values.diagnosis_code);
        if (isValidateCodeDiagnosis) {
          setIsCodeDiagnosisCorrect(true);
        } else {
          setIsCodeDiagnosisCorrect(false);
        }
      }
    }
  }, [values.diagnosis_code]);

  useEffect(() => {
    if (values.code_id) {
      if (values.code_id.length < 5) { // set to not show code_id text while code_id.length is still < 5
        setIsCodeIDCorrect(true);
      } else {
        const isValidateCodeID = ValidateCodeID(values.code_id);
        if (isValidateCodeID) {
          setIsCodeIDCorrect(true);
        } else {
          setIsCodeIDCorrect(false);
        }
      }
    }
  }, [values.code_id]);

  // update case
  useEffect(() => {
    if (id && status === 'success' && data?.success) {
      if (data.patient) {
        setValues({
          ...initialValues,
          ...data.patient,
        });
      }
    }
  }, [status, data?.success, id]);

  // change name title
  useEffect(() => {
    if (values.name_title === EnumNameTitle.other) {
      setShowInputOtherNameTitle(true);
    } else {
      setShowInputOtherNameTitle(false);
      setInputOtherNameTitle('');
    }
  }, [values.name_title]);

  // adding 0 to birth-date for cal age
  useEffect(() => {
    if (dateOfBirth.date) {
      if (dateOfBirth.date && dateOfBirth.date.length === 1) {
        setBirthDateForCalAge(`0${dateOfBirth.date}`);
      } else {
        setBirthDateForCalAge(dateOfBirth.date);
      }
    }
  }, [dateOfBirth.date]);

  // adding 0 to birth-month for cal age
  useEffect(() => {
    if (dateOfBirth.month) {
      if (dateOfBirth.month && dateOfBirth.month.length === 1) {
        setBirthMonthForCalAge(`0${dateOfBirth.month}`);
      } else {
        setBirthMonthForCalAge(dateOfBirth.month);
      }
    }
  }, [dateOfBirth.month]);

  // automatic set birth date and month to 01 01
  useEffect(() => {
    if (dateOfBirth.year) {
      if (!dateOfBirth.date && !dateOfBirth.month) {
        setDateOfBirth({
          ...dateOfBirth,
          date: '01',
          month: '1',
        });
      } else if (!dateOfBirth.date && dateOfBirth.month) {
        setDateOfBirth({
          ...dateOfBirth,
          date: '01',
        });
      }
    }
  }, [dateOfBirth.year]);

  // recheck date of birth every time refresh page
  useEffect(() => {
    if (data?.success && data.patient) {
      const patientDateOfBirth = HandleInitDateOfBirth(data.patient);
      if (patientDateOfBirth.date?.includes('1') && patientDateOfBirth.month?.includes('1') && patientDateOfBirth.year === '544') {
        setDateOfBirth({
          date: undefined,
          month: undefined,
          year: undefined,
        });
      } else {
        setDateOfBirth(patientDateOfBirth);
      }
    }
  }, [data?.success && data.patient?.date_of_birth]);

  // modified other medical-benefit-scheme
  useEffect(() => {
    if (values.medical_benefit_scheme) {
      if (values.medical_benefit_scheme === EnumMedicalBenefitScheme.other) {
        setShowInputOtherMedicalBenefitScheme(true);
      } else {
        setShowInputOtherMedicalBenefitScheme(false);
        setInputOtherMedicalBenefitScheme('');
      }
    }
  }, [values.medical_benefit_scheme]);

  useEffect(() => {
    if (values.last_xray_date) {
      const date = getInitXrayDate(values.last_xray_date);
      setXrayDate(date);
    }
  }, [values.last_xray_date]);

  useEffect(() => {
    if (values.last_egd_date) {
      const date = getInitXrayDate(values.last_egd_date);
      setEgdDate(date);
    }
  }, [values.last_egd_date]);

  if (id) {
    if (isLoading) return <div><Loading /></div>;
  }

  const showEgd = isRequireEGD(values?.diagnosis_code ?? '');

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col lg:flex-row gap-x-8">
        <div className="flex-1">
          <HeadText>รูป</HeadText>
          <div className="w-full">
            <div className="flex flex-col">
              <Avatar
                icon={!values.avatar_img_url && <UserOutlined />}
                src={values.avatar_img_url && `${config.apiPath}/cos/image/${values.avatar_img_url}`}
                className="block mx-auto mb-4"
                size={{
                  md: 80,
                  lg: 80,
                  xl: 100,
                  xxl: 100,
                }}
              />
              <label
                htmlFor="image"
                className="items-center px-2 py-1 mx-auto text-base font-medium text-center border-2 outline-none cursor-pointer w-content opacity-80 rounded-3xl"
              >
                <UploadOutlined className="mr-3" />
                {id ? 'เปลี่ยนรูปประจำตัว' : 'เพิ่มรูปประจำตัว'}
                <input
                  id="image"
                  name="image"
                  className="hidden"
                  type="file"
                  accept="image/*"
                  onChange={handleSelectImage}
                />
              </label>
              {isShowErrorUpload && <div className="my-2 text-center"><ErrorMsg>เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้งในภายหลัง</ErrorMsg></div>}
            </div>
          </div>
          <div className="mb-2 text-base font-medium">
            <p className="mb-1">Code ID Patient <span className="text-red-500">*</span></p>
            <InputBox
              id="code_id"
              name="code_id"
              disabled={!!id && !user?.user_info.is_super_admin}
              onBlur={handleBlur}
              onChange={(e) => setFieldValue('code_id', e.target.value)}
              value={values.code_id}
              type="text"
              className={`${!!id && !user?.user_info.is_super_admin && 'border-transparent'}`}
              placeholder="ตัวอย่าง: A0001"
            />
            {errors.code_id && touched.code_id && <ErrorMsg>{errors.code_id}</ErrorMsg>}
            {!isCodeIDCorrect && <ErrorMsg>Code ID Patient ไม่ถูกต้อง กรุณาตรวจสอบอีกครั้ง</ErrorMsg>}
          </div>
          <div className="mb-2 text-base font-medium">
            <p className="mb-1">Code Diagnosis <span className="text-red-500">*</span></p>
            <InputBox
              id="diagnosis_code"
              name="diagnosis_code"
              disabled={!!id && !user?.user_info.is_super_admin}
              onBlur={handleBlur}
              onChange={(e) => setFieldValue('diagnosis_code', e.target.value)}
              value={values.diagnosis_code}
              type="text"
              className={`${!!id && !user?.user_info.is_super_admin && 'border-transparent'}`}
              placeholder="ตัวอย่าง: BOOOA"
            />
            {errors.diagnosis_code && touched.diagnosis_code && <ErrorMsg>{errors.diagnosis_code}</ErrorMsg>}
            {!isCodeDiagnosisCorrect && <ErrorMsg>Code Diagnosis ไม่ถูกต้อง กรุณาตรวจสอบอีกครั้ง</ErrorMsg>}
          </div>
          <div className="mb-2 text-base font-medium">
            <p className="mb-1">Hospital Number <span className="text-red-500">*</span> ( ไม่ต้องพิมพ์ &apos;/&apos; ) </p>
            <InputBox
              autoComplete={undefined}
              name="hn"
              disabled={!!id && !user?.user_info.is_super_admin}
              onFocus={removeSlash}
              onBlur={formatHN}
              onChange={(e) => !e.target.value.includes('/') && setFieldValue('hn', e.target.value)}
              value={values.hn}
              type="text"
              className={`${!!id && !user?.user_info.is_super_admin && 'border-transparent'}`}
              placeholder="ตัวอย่าง: 15265/63"
            />
            {HNEmptyError && <ErrorMsg>กรุณากรอกเลข Hospital Number</ErrorMsg>}
            {/* {(errors.hn && touched.hn) || HNEmptyError ? <ErrorMsg>{errors.hn}</ErrorMsg> : null} */}
            {HNError && <ErrorMsg>Hospital Number ไม่ถูกต้อง</ErrorMsg>}
          </div>
          <div className="mt-4 mb-2 text-base font-medium">
            <HeadText>ข้อมูลการตรวจเพิ่มเติม (MRI, CT Scan, Ultrasound)</HeadText>
            <div className="flex flex-col items-center mt-4">
              <div className="grid w-full grid-cols-2">
                <div className="flex flex-row">
                  <Checkbox onChange={() => handleSelectXrayType(null)} checked={!values.xray_type} />
                  <p className="ml-2">ไม่มี</p>
                </div>
                <div className="flex flex-row">
                  <Checkbox onChange={() => handleSelectXrayType(XrayType.MRI)} checked={values.xray_type === XrayType.MRI} />
                  <p className="ml-2">MRI</p>
                </div>
              </div>
              <div className="grid w-full grid-cols-2 mt-2">
                <div className="flex flex-row">
                  <Checkbox onChange={() => handleSelectXrayType(XrayType.CT_SCAN)} checked={values.xray_type === XrayType.CT_SCAN} />
                  <p className="ml-2">CT Scan</p>
                </div>
                <div className="flex flex-row">
                  <Checkbox onChange={() => handleSelectXrayType(XrayType.ULTRASOUND)} checked={values.xray_type === XrayType.ULTRASOUND} />
                  <p className="ml-2">Ultrasound</p>
                </div>
              </div>
            </div>
          </div>
          {values.xray_type && (
            <div className="mt-4 text-base font-medium">
              <div className="flex flex-row items-center">
                <p className="mb-1 mr-2">วันที่ได้รับการตรวจครั้งล่าสุด</p>
              </div>
              <div className="flex mb-2 md:flex-col md:gap-y-4 xl:flex-row xl:gap-x-3">
                <Select
                  bordered={false}
                  placeholder="วันที่"
                  className="items-center w-full text-base border-2 outline-none 2xl:w-full xl:rounded-tr-lg xl:rounded-br-lg md:rounded-tr-3xl md:rounded-br-3xl rounded-bl-3xl rounded-tl-3xl"
                  dropdownStyle={{ borderRadius: 20 }}
                  value={!xrayDate ? undefined : xrayDate.date}
                  options={dates.map((date) => ({ label: date.label, value: date.value }))}
                  showSearch
                  filterOption={(input, option) => (option?.label as string).toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0}
                  onChange={(value) => onChangeXrayDate(value)}
                  allowClear
                />
                <Select
                  bordered={false}
                  placeholder="เดือน"
                  className="items-center w-full text-base border-2 xl:rounded-md md:rounded-3xl 2xl:w-full"
                  dropdownStyle={{ borderRadius: 20 }}
                  value={!xrayDate ? undefined : xrayDate.month}
                  options={months.map((month) => ({ label: month.label, value: month.value }))}
                  showSearch
                  filterOption={(input, option) => (option?.label as string).toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0}
                  onChange={(value) => onChangeXrayMonth(value)}
                  allowClear
                />
                <Select
                  bordered={false}
                  placeholder="ปี"
                  className="items-center w-full text-base border-2 outline-none xl:rounded-tl-lg 2xl:w-full xl:rounded-bl-lg md:rounded-3xl xl:rounded-tr-3xl xl:rounded-br-3xl"
                  dropdownStyle={{ borderRadius: 20 }}
                  value={!xrayDate ? undefined : xrayDate.year}
                  options={years.map((year) => ({ label: year, value: year }))}
                  showSearch
                  filterOption={(input, option) => (option?.label as string).toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0}
                  onChange={(value) => onChangeXrayYear(value)}
                  allowClear
                />
              </div>
              {errors.last_xray_date && touched.last_xray_date && <ErrorMsg>{errors.last_xray_date}</ErrorMsg>}
            </div>
          )}
          {showEgd && (
            <>
              <div className="mt-4 mb-2 text-base font-medium">
                <div className="flex flex-row justify-start mt-4">
                  <div className="flex flex-row">
                    <Checkbox onChange={(e) => handleEgdCheck(e.target.checked)} checked={values.is_egd_checked} />
                    <p className="ml-2">เคยได้รับการตรวจ EGD</p>
                  </div>
                </div>
              </div>
              {values.is_egd_checked && (
                <div className="mt-4 text-base font-medium">
                  <div className="flex flex-row items-center">
                    <p className="mb-1 mr-2">วันที่ได้รับการตรวจ EGD ครั้งล่าสุด</p>
                  </div>
                  <div className="flex mb-2 md:flex-col md:gap-y-4 xl:flex-row xl:gap-x-3">
                    <Select
                      bordered={false}
                      placeholder="วันที่"
                      className="items-center w-full text-base border-2 outline-none 2xl:w-full xl:rounded-tr-lg xl:rounded-br-lg md:rounded-tr-3xl md:rounded-br-3xl rounded-bl-3xl rounded-tl-3xl"
                      dropdownStyle={{ borderRadius: 20 }}
                      value={!egdDate ? undefined : egdDate.date}
                      options={dates.map((date) => ({ label: date.label, value: date.value }))}
                      showSearch
                      filterOption={(input, option) => (option?.label as string).toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0}
                      onChange={(value) => onChangeEgdDate(value)}
                      allowClear
                    />
                    <Select
                      bordered={false}
                      placeholder="เดือน"
                      className="items-center w-full text-base border-2 xl:rounded-md md:rounded-3xl 2xl:w-full"
                      dropdownStyle={{ borderRadius: 20 }}
                      value={!egdDate ? undefined : egdDate.month}
                      options={months.map((month) => ({ label: month.label, value: month.value }))}
                      showSearch
                      filterOption={(input, option) => (option?.label as string).toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0}
                      onChange={(value) => onChangeEgdMonth(value)}
                      allowClear
                    />
                    <Select
                      bordered={false}
                      placeholder="ปี"
                      className="items-center w-full text-base border-2 outline-none xl:rounded-tl-lg 2xl:w-full xl:rounded-bl-lg md:rounded-3xl xl:rounded-tr-3xl xl:rounded-br-3xl"
                      dropdownStyle={{ borderRadius: 20 }}
                      value={!egdDate ? undefined : egdDate.year}
                      options={years.map((year) => ({ label: year, value: year }))}
                      showSearch
                      filterOption={(input, option) => (option?.label as string).toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0}
                      onChange={(value) => onChangeEgdYear(value)}
                      allowClear
                    />
                  </div>
                  {errors.last_egd_date && touched.last_egd_date && <ErrorMsg>{errors.last_egd_date}</ErrorMsg>}
                </div>
              )}
            </>
          )}
        </div>
        <div className="flex-1 mt-6 lg:mt-0">
          <HeadText>ข้อมูลผู้รับบริการ</HeadText>
          <div className="mb-2 text-base font-medium">
            <p className="mb-1">คำนำหน้าชื่อ</p>
            <div className={`flex flex-row ${showInputOtherNameTitle && 'gap-x-2'}`}>
              <div className={`${showInputOtherNameTitle ? 'w-1/3' : 'w-full'}`}>
                <NameTitlesPatient formik={formik} />
              </div>
              {showInputOtherNameTitle && (
                <InputBox
                  type="text"
                  autoFocus
                  value={inputOtherNameTitle}
                  onChange={(e) => setInputOtherNameTitle(e.target.value)}
                  placeholder="กรุณากรอกคำนำหน้าชื่อ"
                />
              )}
            </div>
            {mustInputOtherNameTitle && inputOtherNameTitle.length === 0 && <ErrorMsg>กรุณากรอกคำนำหน้าชื่อ</ErrorMsg>}
          </div>
          <div className="mb-2 text-base font-medium">
            <p className="mb-1">ชื่อ <span className="text-red-500">*</span></p>
            <InputBox
              id="first_name"
              name="first_name"
              onBlur={handleBlur}
              onChange={(e) => setFieldValue('first_name', e.target.value)}
              value={values.first_name}
              type="text"
            />
            {errors.first_name && touched.first_name && <ErrorMsg>{errors.first_name}</ErrorMsg>}
          </div>
          <div className="mb-2 text-base font-medium">
            <p className="mb-1">นามสกุล <span className="text-red-500">*</span></p>
            <InputBox
              id="last_name"
              name="last_name"
              onBlur={handleBlur}
              onChange={(e) => setFieldValue('last_name', e.target.value)}
              value={values.last_name}
              type="text"
            />
            {errors.last_name && touched.last_name && <ErrorMsg>{errors.last_name}</ErrorMsg>}
          </div>
          <div className="mb-2 text-base font-medium">
            <p className="mb-1">เลขบัตรประชาชน <span className="text-red-500">*</span></p>
            <InputBox
              id="citizen_id"
              name="citizen_id"
              disabled={!!id && !user?.user_info.is_super_admin}
              onBlur={handleBlur}
              onChange={(e) => {
                if (/^\d{0,13}$/.test(e.target.value)) {
                  setFieldValue('citizen_id', e.target.value);
                }
              }}
              value={values.citizen_id}
              type="text"
              className={`${!!id && !user?.user_info.is_super_admin && 'border-transparent'}`}
              maxLength={13}
            />
            {errors.citizen_id && touched.citizen_id && <ErrorMsg>{errors.citizen_id}</ErrorMsg>}
            {!isCitizenIDCorrect && <ErrorMsg>เลขบัตรประชาชนไม่ถูกต้อง กรุณาตรวจสอบอีกครั้ง</ErrorMsg>}
          </div>
          <div className="mb-2 text-base font-medium">
            <p className="mb-1">น้ำหนัก (กก.) <span className="text-red-500">*</span></p>
            <InputBox
              id="weight"
              name="weight"
              onBlur={handleBlur}
              onChange={(e) => setFieldValue('weight', e.target.value)}
              value={values.weight === 0 ? '' : values.weight}
              type="text"
            />
            {errors.weight && touched.weight && <ErrorMsg>{errors.weight}</ErrorMsg>}
            {mustInputWeight && <ErrorMsg>กรุณากรอกน้ำหนัก (กก.)</ErrorMsg>}
          </div>
          <div className="mb-2 text-base font-medium">
            <p className="mb-1">ส่วนสูง (ซม.) <span className="text-red-500">*</span></p>
            <InputBox
              id="height"
              name="height"
              onBlur={handleBlur}
              onChange={(e) => setFieldValue('height', e.target.value)}
              value={values.height === 0 ? '' : values.height}
              type="text"
            />
            {errors.height && touched.height && <ErrorMsg>{errors.height}</ErrorMsg>}
            {mustInputHeight && <ErrorMsg>กรุณากรอกส่วนสูง (ซม.)</ErrorMsg>}
          </div>
          <div className="mb-2 text-base font-medium">
            <p className="mb-1">ความดันโลหิตตัวบน</p>
            <InputBox
              id="sbp"
              name="sbp"
              onBlur={handleBlur}
              onChange={(e) => setFieldValue('sbp', e.target.value)}
              value={values.sbp === 0 ? '' : values.sbp}
              type="text"
            />
          </div>
          <div className="mb-2 text-base font-medium">
            <p className="mb-1">ความดันโลหิตตัวล่าง</p>
            <InputBox
              id="dbp"
              name="dbp"
              onBlur={handleBlur}
              onChange={(e) => setFieldValue('dbp', e.target.value)}
              value={values.dbp === 0 ? '' : values.dbp}
              type="text"
            />
          </div>
          <div className="mb-2 text-base font-medium">
            <div className="flex flex-row items-center">
              <p className="mb-1 mr-2">วันเดือนปีเกิด <span className="text-red-500">*</span></p>
              <Popover content={<p className="mb-10 text-base">• สามารถกรอก &apos;ปี&apos; เพียงอย่างเดียวได้ ซึ่งระบบจะเลือกวันเกิดให้เป็น <span className="underline">วันที่ 1 มกราคม</span> ของปีนั้นๆ<br />• หากกรอก &apos;เดือน&apos; และ &apos;ปี&apos; ระบบจะคำนวณให้วันเกิดเป็น <span className="underline">วันที่ 1 ของเดือนที่เลือก</span> โดยอัตโนมัติ</p>} className="w-content">
                <QuestionCircleTwoTone className="cursor-pointer" />
              </Popover>
              {dateOfBirth.date && dateOfBirth.month && dateOfBirth.year && <p className="mb-1 ml-2 opacity-80">(อายุ {handleCalculateAge(`${(Number(dateOfBirth.year) - 543).toString()}-${birthMonthForCalAge}-${birthDateForCalAge}T00:00:00.000Z`)} ปี)</p>}
            </div>
            <div className="flex md:flex-col md:gap-y-4 xl:flex-row xl:gap-x-3">
              <Select
                bordered={false}
                placeholder="วันที่"
                className="items-center w-full text-base border-2 outline-none 2xl:w-full xl:rounded-tr-lg xl:rounded-br-lg md:rounded-tr-3xl md:rounded-br-3xl rounded-bl-3xl rounded-tl-3xl"
                dropdownStyle={{ borderRadius: 20 }}
                value={!dateOfBirth.date ? undefined : dateOfBirth.date}
                options={dates.map((date) => ({ label: date.label, value: date.value }))}
                showSearch
                filterOption={(input, option) => (option?.label as string).toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0}
                onChange={(value) => setDateOfBirth({ ...dateOfBirth, date: value })}
                allowClear
              />
              <Select
                bordered={false}
                placeholder="เดือน"
                className="items-center w-full text-base border-2 xl:rounded-md md:rounded-3xl 2xl:w-full"
                dropdownStyle={{ borderRadius: 20 }}
                value={!dateOfBirth.month ? undefined : dateOfBirth.month}
                options={months.map((month) => ({ label: month.label, value: month.value }))}
                showSearch
                filterOption={(input, option) => (option?.label as string).toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0}
                onChange={(value) => setDateOfBirth({ ...dateOfBirth, month: value })}
                allowClear
              />
              <Select
                bordered={false}
                placeholder="ปี"
                className="items-center w-full text-base border-2 outline-none xl:rounded-tl-lg 2xl:w-full xl:rounded-bl-lg md:rounded-3xl xl:rounded-tr-3xl xl:rounded-br-3xl"
                dropdownStyle={{ borderRadius: 20 }}
                value={!dateOfBirth.year ? undefined : dateOfBirth.year}
                options={years.map((year) => ({ label: year, value: year }))}
                showSearch
                filterOption={(input, option) => (option?.label as string).toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0}
                onChange={(value) => setDateOfBirth({ ...dateOfBirth, year: value })}
                allowClear
              />
            </div>
            {errors.date_of_birth && touched.date_of_birth && <ErrorMsg>{errors.date_of_birth}</ErrorMsg>}
            {birthYearError && <ErrorMsg>กรุณากรอกวันเดือนปีเกิด (กรอกปีเกิดเป็นอย่างน้อย)</ErrorMsg>}
          </div>
          <div className="mb-2 text-base font-medium">
            <p className="mb-1">เพศ <span className="text-red-500">*</span></p>
            <Select
              id="gender"
              bordered={false}
              onBlur={handleBlur}
              disabled={!!id && !user?.user_info.is_super_admin}
              onChange={(value: string) => setFieldValue('gender', value)}
              defaultValue=""
              value={values.gender}
              dropdownStyle={{ borderRadius: 20 }}
              className={`${!!id && !user?.user_info.is_super_admin && 'bg-gray-100 border-transparent'} items-center w-full text-base border-2 outline-none rounded-3xl`}
            >
              <Option value="male">ชาย</Option>
              <Option value="female">หญิง</Option>
            </Select>
            {errors.gender && touched.gender && <ErrorMsg>{errors.gender}</ErrorMsg>}
          </div>
          <div className="mb-2 text-base font-medium">
            <p className="mb-1">สิทธิการรักษา <span className="text-red-500">*</span></p>
            <div className={`flex flex-row ${showInputOtherMedicalBenefitScheme && 'gap-x-2'}`}>
              <div className={`${showInputOtherMedicalBenefitScheme ? 'w-1/3' : 'w-full'}`}>
                <Select
                  id="medical_benefit_scheme"
                  bordered={false}
                  onBlur={handleBlur}
                  onChange={(value: string) => setFieldValue('medical_benefit_scheme', value)}
                  value={values.medical_benefit_scheme}
                  dropdownStyle={{ borderRadius: 20 }}
                  className="items-center w-full text-base border-2 outline-none rounded-3xl"
                >
                  <Option value={EnumMedicalBenefitScheme.cash}>เงินสด</Option>
                  <Option value={EnumMedicalBenefitScheme.goverment_officer}>สิทธิข้าราชการ</Option>
                  <Option value={EnumMedicalBenefitScheme.sso}>สิทธิประกันสังคม</Option>
                  <Option value={EnumMedicalBenefitScheme.gold_card}>สิทธิบัตรทอง</Option>
                  <Option value={EnumMedicalBenefitScheme.monk}>พระสงฆ์</Option>
                  <Option value={EnumMedicalBenefitScheme.other}>อื่นๆ</Option>
                </Select>
              </div>
              {showInputOtherMedicalBenefitScheme && (
                <InputBox
                  type="text"
                  autoFocus
                  value={inputOtherMedicalBenefitScheme}
                  onChange={(e) => setInputOtherMedicalBenefitScheme(e.target.value)}
                  placeholder="กรุณากรอกสิทธิการรักษา"
                />
              )}
            </div>
            {errors.medical_benefit_scheme && touched.medical_benefit_scheme && <ErrorMsg>{errors.medical_benefit_scheme}</ErrorMsg>}
            {mustInputOtherMedicalBenefitScheme && inputOtherMedicalBenefitScheme.length === 0 && <ErrorMsg>กรุณากรอกสิทธิการรักษา</ErrorMsg>}
          </div>
        </div>
        <div className="flex-1 mt-6 lg:mt-0">
          <HeadText>ที่อยู่</HeadText>
          <div className="mb-2 text-base font-medium">
            <p className="mb-1">ข้อมูลที่อยู่ของผู้รับบริการ</p>
            <TextAreaInput
              autoSize={{ minRows: 4 }}
              id="raw_address"
              name="raw_address"
              onBlur={handleBlur}
              onChange={(e) => setFieldValue('raw_address', e.target.value)}
              value={values.raw_address}
            />
          </div>
          <div className="mb-2 text-base font-medium">
            <p className="mb-1">เบอร์โทรศัพท์ <span className="text-red-500">*</span></p>
            <InputBox
              id="mobile_number"
              name="mobile_number"
              onBlur={handleBlur}
              onChange={(e) => {
                if (/^\d{0,10}$/.test(e.target.value)) {
                  setFieldValue('mobile_number', e.target.value);
                }
              }}
              value={values.mobile_number}
              maxLength={10}
            />
            {errors.mobile_number && touched.mobile_number && <ErrorMsg>{errors.mobile_number}</ErrorMsg>}
          </div>
          <div className="mb-2 text-base font-medium">
            <p className="mb-1">เบอร์โทรศัพท์สำรอง</p>
            <InputBox
              id="backup_mobile_number"
              name="backup_mobile_number"
              onBlur={handleBlur}
              onChange={(e) => {
                if (/^\d{0,10}$/.test(e.target.value)) {
                  setFieldValue('backup_mobile_number', e.target.value);
                }
              }}
              value={values.backup_mobile_number}
              maxLength={10}
            />
            {errors.backup_mobile_number && touched.backup_mobile_number && <ErrorMsg>{errors.backup_mobile_number}</ErrorMsg>}
          </div>
          <div className="mb-2 text-base font-medium">
            <p className="mb-1">เบอร์โทรศัพท์บ้าน</p>
            <InputBox
              id="telephone"
              name="telephone"
              onBlur={handleBlur}
              onChange={(e) => {
                if (/^\d{0,9}$/.test(e.target.value)) {
                  setFieldValue('telephone', e.target.value);
                }
              }}
              value={values.telephone}
              maxLength={10}
            />
            {errors.telephone && touched.telephone && <ErrorMsg>{errors.telephone}</ErrorMsg>}
          </div>
          <div className="my-4">
            <HeadText>บันทึกประจำตัวผู้รับบริการ</HeadText>
            {/* TODO: Add patient note here */}
            {/* IF WONDER THAT WHY DO I HAVE TO CONDITIONAL RENDER THESE 2 COMPONENTS */}
            {/* IT IS BECAUSE THE BULLETED ISSUES */}
            {/* BULLETEDS WILL DISPLAY ONLY 1 PLACE AT THE MIDDLE OF ALL LISTS WHEN COME TO EDIT EXISTING NOTES */}
            {/* AND YES, I DO NOT KNOW WHY THAT THING HAPPENES */}
            {/* SO THIS IS HOW I SOLVED IT, OHTER WAYS MIGHT BETTER BUT RIGHT NOW I WILL KEEP THIS THING TO NOT WASTE TIME */}
            {/* {id && values.personal_note.length >= 0 && (
              <StyledBulletedTextArea
                bulletChar="•"
                values={values.personal_note}
                onChange={(value) => setFieldValue('personal_note', value)}
              />
            )}
            {!id && values.personal_note.length >= 0 ? (
              <StyledBulletedTextArea
                bulletChar="•"
                values={values.personal_note}
                onChange={(value) => setFieldValue('personal_note', value)}
              />
            ) : null} */}
            <TinyMECInput height="200" inputValue={values.personal_note} handleChangeValue={(value) => setFieldValue('personal_note', value)} />
          </div>
          <div className="items-center mt-5 text-right align-middle">
            <SaveButton
              onClick={() => {
                if (dateOfBirth.year === undefined) {
                  setBirthYearError(true);
                } else {
                  setBirthYearError(false);
                }

                if (!values.hn) {
                  setHNEmptyError(true);
                } else {
                  setHNEmptyError(false);
                }

                if (HNError) {
                  setHNError(true);
                } else {
                  setHNError(false);
                }

                handleSubmit();
              }}
              disabled={!errors && !dateOfBirth.year}
            >
              Save
            </SaveButton>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddNewPatientForm;
