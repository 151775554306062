/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import {
  Row, Col,
} from 'antd';
import moment from 'moment-timezone';
import { TAppointment } from '../../common/form-schema/appointments/AppointmentSchema';
import AvatarImage from '../../common/components/AvatarImage';
import AppointmentDiagnosisModal from './AppointmentDiagnosisModal';
import { AppointmentType, UserType } from '../../common/constant-enum-type';
import { AppointmentTypeLabel } from './AppointmentTypes';
import TinyMECShowDetail from '../../common/components/TinyMECShowDetail';

interface IAppointmentInfoCardProps {
  userType: string;
  appointment: TAppointment;
  isFromAppointmentHistories?: boolean;
  isLastElement: boolean;
}

const AppointmentInfoCard: React.FC<IAppointmentInfoCardProps> = (props) => {
  const {
    userType,
    appointment,
    isFromAppointmentHistories,
    isLastElement,
  } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleClickHideModal = () => {
    setIsModalVisible(false);
  };

  const handleClickShowModal = () => {
    setIsModalVisible(true);
  };

  const handleClickEditAppointmet = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div
        className={`${isLastElement ? '' : 'mb-4'} bg-white cursor-pointer outline-none`}
        onClick={() => handleClickShowModal()}
      >
        <Row className="mb-3">
          <Col className="flex flex-row">
            <span
              className="text-lg font-medium"
              style={appointment.appointment_type === AppointmentType.onsite ? { color: '#1E73BE' } : { color: '#ED6024' }}
            >
              {moment(appointment.appointment_date).add(543, 'year').format('LL')}&nbsp;&nbsp;
            </span>
            <AppointmentTypeLabel appointmentType={appointment.appointment_type} />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            {userType === UserType.Patient && <AvatarImage imgURL={appointment.doctor_id.avatar_img_url} />}
            {userType === UserType.Doctor && <AvatarImage imgURL={appointment.patient_id.avatar_img_url} />}
          </Col>
          <Col className="ml-3">
            {userType === UserType.Patient && <p className="text-base text-gray-600">{`${!appointment.doctor_id?.name_title ? '' : appointment.doctor_id?.name_title}${appointment.doctor_id?.first_name} ${appointment.doctor_id?.last_name}`}</p>}
            {userType === UserType.Doctor && <p className="text-base text-gray-600">{`${!appointment.patient_id?.name_title ? '' : appointment.patient_id?.name_title}${appointment.patient_id?.first_name} ${appointment.patient_id?.last_name}`}</p>}
          </Col>
        </Row>
        {/* <Row className="mb-3">
          {appointment.doctor_note.note
            && (
              <div>
                <span className="text-base font-medium">Doctor Note</span>
                <span className="text-base opacity-90 line-clamp-2">
                  <TinyMECShowDetail details={appointment.doctor_note.note} />
                </span>
              </div>
            )}
        </Row> */}
        <Row className={`${isLastElement ? 'mb-4' : 'mb-3'}`}>
          {appointment.nurse_note
            && (
              <div>
                <span className="text-base font-medium">Nurse Note</span>
                <span className="text-base opacity-90 line-clamp-2">
                  <TinyMECShowDetail details={appointment.nurse_note} />
                </span>
              </div>
            )}
        </Row>
        {!isLastElement && <hr />}
      </div>
      <AppointmentDiagnosisModal
        isModalVisible={isModalVisible}
        handleClickHideModal={handleClickHideModal}
        handleClickShowAppointmentModal={handleClickShowModal}
        handleClickEditAppointmet={handleClickEditAppointmet}
        appointment={appointment}
        isFromAppointmentHistories={isFromAppointmentHistories}
      />
    </>
  );
};

export default AppointmentInfoCard;
