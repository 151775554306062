import { atom } from 'recoil';

export const patientMenu = atom({
  key: 'patientMenu',
  default: {
    currentMenu: 'personal-note-template',
  },
});

export const doctorMenu = atom({
  key: 'doctorMenu',
  default: {
    currentMenu: 'appointments',
  },
});

export const appointmentMenu = atom({
  key: 'appointmentMenu',
  default: {
    currentMenu: 'calendar',
  },
});

export const xrayMenu = atom({
  key: 'xrayMenu',
  default: {
    currentMenu: 'upcoming',
  },
});

export const ChatMenuState = atom({
  key: 'ChatMenuState',
  default: 'all',
});
