/* eslint-disable no-useless-concat */
/* eslint-disable import/no-absolute-path */
import React from 'react';
import LoginForm from '../../features/common/components/form/LoginForm';

const LoginPage: React.FC = () => {
  return (
    <div
      className="login-background-image"
      style={
        {
          backgroundImage: 'url(' + '/static/image/chula-desktop-picture.jpg' + ')',
          position: 'fixed',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }
      }
    >
      <div
        className="flex-col font-serif login-center-form"
      >
        <LoginForm
          headText="ศูนย์เชี่ยวชาญเฉพาะทางด้านโรคตับอักเสบและมะเร็งตับ"
          subHeadText="(Center or Excellence in Hepatitis and Liver Cancer, Faculty of Medicine, Chulalongkorn University)"
        />
      </div>
    </div>
  );
};

export default LoginPage;
