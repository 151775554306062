/* eslint-disable react/require-default-props */
import StarFilled from '@ant-design/icons/StarFilled';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { handleCalculateAge, handleFormatGender } from '../../all-about-string-method';
import { TDoctor } from '../../form-schema/doctors/DoctorSchema';
import { TNurse } from '../../form-schema/nurses/NurseSchema';
import { TPatient } from '../../form-schema/patients/PatientSchema';
import AvatarImage from '../AvatarImage';

interface Props {
  linkTo: string;
  patient?: TPatient;
  nurse?: TNurse;
  doctor?: TDoctor;
}

const PersonCard: React.FC<Props> = (props) => {
  const {
    linkTo,
    patient,
    nurse,
    doctor,
  } = props;

  if (nurse) {
    return (
      <NavLink to={linkTo}>
        <div className="relative p-4 text-center bg-white hover:shadow-md rounded-3xl">
          {nurse.is_super_admin && <StarFilled className="absolute text-base text-yellow-300 top-2 right-2" />}
          <AvatarImage imgURL={nurse.avatar_img_url} size={90} />
          <div className="flex flex-col w-full mt-2 text-center">
            <p className="text-lg font-medium truncate">{nurse.first_name} {nurse.last_name}</p>
          </div>
        </div>
      </NavLink>
    );
  }

  if (doctor) {
    return (
      <NavLink to={linkTo}>
        <div className="p-4 text-center bg-white hover:shadow-md rounded-3xl">
          <AvatarImage imgURL={doctor.avatar_img_url} size={90} />
          <div className="flex flex-col w-full mt-2 text-center">
            <p className="text-lg font-medium truncate">{doctor.name_title && doctor.name_title}{doctor.first_name} {doctor.last_name}</p>
          </div>
        </div>
      </NavLink>
    );
  }

  if (patient) {
    return (
      <NavLink to={linkTo}>
        <div className="flex flex-row p-4 bg-white hover:shadow-md rounded-3xl">
          <div className="flex flex-col my-auto w-max">
            <AvatarImage imgURL={patient.avatar_img_url} size={80} />
          </div>
          <div className="flex flex-col w-full ml-4 text-left">
            <p className="font-medium truncate text-primary" style={{ fontSize: '1.05rem' }}>{patient.first_name}</p>
            <p className="font-medium truncate text-primary" style={{ fontSize: '1.05rem' }}>{patient.last_name}</p>
            <p className="text-sm font-medium opacity-80">HN: {patient.hn}</p>
            <p className="text-sm font-medium opacity-80">Age: {handleCalculateAge(patient.date_of_birth)} | {handleFormatGender(patient.gender, true)}</p>
            <p className="text-sm font-medium opacity-80">Code Dx: {patient.diagnosis_code}</p>
          </div>
        </div>
      </NavLink>
    );
  }

  return null;
};

export default PersonCard;
