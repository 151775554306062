import { atom } from 'recoil';
import { UserSchema } from '../features/common/form-schema/user/UserSchema';

const UserState = atom<UserSchema>({
  key: 'UserState',
  default: {
    name_title: '',
    citizen_id: '',
    created_at: '',
    date_of_birth: '',
    first_name: '',
    gender: '',
    hospital_id: '',
    last_name: '',
    mobile_number: '',
    raw_address: '',
    telephone: '',
    role: '',
    user_type: '',
    username: '',
    _id: '',
    unavailable_day: [],
    avatar_img_url: '',
    is_super_admin: false,
  },
});

export default UserState;
