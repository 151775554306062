/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

const handleCheckDiagnosisCode1 = (code: string) => {
  const codeForm = ['A', 'B', 'b', 'C', 'G', 'I', 'L', 'Y', 'M'];
  const checkCode = codeForm.includes(code);
  return checkCode;
};

const handleCheckDiagnosisCode2 = (code: string) => {
  const codeForm = ['C', 'O'];
  const checkCode = codeForm.includes(code);
  return checkCode;
};

const handleCheckDiagnosisCode3 = (code: string) => {
  const codeForm = ['H', 'N', 'O'];
  const checkCode = codeForm.includes(code);
  return checkCode;
};

const handleCheckDiagnosisCode4 = (code: string) => {
  const codeForm = ['T', 'O', 'S'];
  const checkCode = codeForm.includes(code);
  return checkCode;
};

const handleCheckDiagnosisCode5 = (code: string) => {
  const codeForm = ['A', 'B', 'b', 'C', 'G', 'I', 'L', 'Y', 'M', 'O', 'V'];
  const checkCode = codeForm.includes(code);
  return checkCode;
};

const ValidateCodeDiagnosis = (code?: string) => {
  if (code) {
    const splitCode1 = code.charAt(0);
    const splitCode2 = code.charAt(1);
    const splitCode3 = code.charAt(2);
    const splitCode4 = code.charAt(3);
    const splitCode5 = code.charAt(4);

    const checked1 = handleCheckDiagnosisCode1(splitCode1);
    const checked2 = handleCheckDiagnosisCode2(splitCode2);
    const checked3 = handleCheckDiagnosisCode3(splitCode3);
    const checked4 = handleCheckDiagnosisCode4(splitCode4);
    const checked5 = handleCheckDiagnosisCode5(splitCode5);

    if (checked1 && checked2 && checked3 && checked4 && checked5) {
      return true;
    }
    return false;
  }
  return true; // this case won't happend, but this function has to has a return statement at the last line
};

export default ValidateCodeDiagnosis;
