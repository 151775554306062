/* eslint-disable react/require-default-props */
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router';
import { EditorState, convertFromHTML, ContentState } from 'draft-js';
import styled from 'styled-components';
import { Popconfirm } from 'antd';
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined';

import Editor, { decorator, getHTML as parseHTML } from '../common/components/Editor';
import { SendNotificationButton } from '../common/components/Button';
import LinkUtils from '../common/components/Editor/LinkUtils';
import useSendNotification from '../common/connecting-api/notification/hooks/use-send-notification';
import { NotificationType } from '../common/connecting-api/notification/api-action';
import handleSendNotificaitonSuccess from '../common/components/modals/SuccessSendNotificationModal';

interface ELearningProp {
  handleChange?: (message: string[]) => void;
  value?: string[];
  mediumHeader?: boolean;
}

const Button = styled(SendNotificationButton)`
  color: #FFFFFF !important;
`;

const DeleteButton = styled(Popconfirm)`
  padding: 0 0.75rem;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  background: #ED1C24 !important;
  color: #FFFFFF !important;
  font-size: 16px;
  line-height: 2;
`;

const RedQuestionIcon = () => <QuestionCircleOutlined style={{ color: 'red' }} />;

const okButtonProps = {
  style: {
    color: '#FFFFFF',
    background: '#40A9FF',
    border: 'none',
  },
};

const cancelButtonProps = {
  style: {
    background: '#ED1C24',
    color: '#FFFFFF',
    border: 'none',
  },
};

const getEmptyStates = () => [EditorState.createEmpty(decorator)];

const ELearningNotification: React.FC<ELearningProp> = (props) => {
  const { id } = useParams<{ id: string }>();
  const isSendingNotificationButtonShown = !props.handleChange;
  const memoizedValue = useRef(props.value);
  const [editorStates, setEditorStates] = useState<EditorState[]>(() => {
    return props.value ? props.value.map((v) => {
      const convertedData = convertFromHTML(v);
      const editorState = EditorState.createWithContent(
        ContentState.createFromBlockArray(convertedData.contentBlocks, convertedData.entityMap),
        decorator,
      );
      return editorState;
    }) : [];
  });

  const { mutateAsync: sendNotifcation, isLoading } = useSendNotification();

  const getHTML = (states: EditorState[]) => states.map((rowState) => parseHTML(rowState.getCurrentContent()));

  const handleChange = (newEditorStates: EditorState[]) => {
    if (props.handleChange) {
      const htmlList = getHTML(newEditorStates);
      memoizedValue.current = htmlList;
      props.handleChange(htmlList);
    }
  };

  const handleReturn = (idx: number): 'handled' => {
    const lastIndex = editorStates.length - 1;
    if (idx !== lastIndex) return 'handled';
    const newEditorState = EditorState.createEmpty(decorator);
    setEditorStates([
      ...editorStates,
      newEditorState,
    ]);
    return 'handled';
  };

  const handleDeleteRowState = (idx: number) => {
    const updatedStates = editorStates.filter((_, i) => i !== idx);
    setEditorStates(updatedStates);
    handleChange(updatedStates);
  };

  const onChangeRowState = (idx: number, updatedState: EditorState): void => {
    const states: EditorState[] = editorStates.map((rowState, i) => {
      if (i === idx) {
        return updatedState;
      }
      return rowState;
    });
    setEditorStates(states);
    handleChange(states);
  };

  const handleSendNotification = async () => {
    const htmlData = getHTML(editorStates);
    if (id) {
      const data = {
        id,
        type: NotificationType.ELEARNING,
        content: htmlData,
      };
      await sendNotifcation(data, { onSuccess: handleSendNotificaitonSuccess });
      setEditorStates(getEmptyStates());
    }
  };

  // when the props.value is changed by not from props.handleChange
  useEffect(() => {
    if (memoizedValue.current?.length !== props.value?.length
      || ((memoizedValue.current ?? []).join('') !== (props.value ?? []).join(''))
    ) {
      memoizedValue.current = props.value;
      setEditorStates((props.value ?? []).map((v) => {
        const convertedData = convertFromHTML(v);
        const editorState = EditorState.createWithContent(
          ContentState.createFromBlockArray(convertedData.contentBlocks, convertedData.entityMap),
          decorator,
        );
        return editorState;
      }));
    }
  }, [props.value]);

  return (
    <>
      <p className={`${props.mediumHeader ? 'font-medium' : 'font-semibold'} mb-2`}>E-Learning</p>
      <LinkUtils
        editorStates={editorStates}
        setEditorStates={setEditorStates}
      />
      {editorStates.map((rowState, i) => (
        <div
          key={`${rowState.getCurrentContent().getPlainText()}_${i}`} /* eslint-disable-line */
          className="flex mb-2"
        >
          <Editor
            withCancel={i !== 0}
            editorStateProp={rowState}
            onHandleReturn={() => handleReturn(i)}
            onChangeProp={(newState: EditorState) => onChangeRowState(i, newState)}
          />
          <div className="flex items-center">
            <DeleteButton
              title="Please confirm to delete this"
              onConfirm={() => handleDeleteRowState(i)}
              okText="Confirm"
              okButtonProps={okButtonProps}
              cancelText="Cancel"
              cancelButtonProps={cancelButtonProps}
              icon={<RedQuestionIcon />}
            >
              ลบ
            </DeleteButton>
          </div>
        </div>
      ))}
      <div
        role="button"
        tabIndex={0}
        className="flex items-center justify-center mt-2 border border-gray-300 border-dashed cursor-pointer rounded-3xl"
        onClick={() => handleReturn(editorStates.length - 1)}
        aria-hidden="true"
        style={{ outline: 'none' }}
      >
        + เพิ่ม E-Learning
      </div>
      {isSendingNotificationButtonShown && (
        <div className="flex justify-end">
          <Button disabled={isLoading} className="mt-2 text-white" onClick={handleSendNotification}>ส่งการแจ้งเตือน</Button>
        </div>
      )}
    </>
  );
};

export default ELearningNotification;
