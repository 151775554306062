/* eslint-disable no-nested-ternary */
import ExclamationCircleTwoTone from '@ant-design/icons/ExclamationCircleTwoTone';
import React from 'react';
import { useParams } from 'react-router';
import Loading from '../../features/common/components/Loading';
import PageTitle from '../../features/common/components/PageTitle';
import MainInfo from '../../features/common/components/persons/MainInfo';
import useFetchDoctorByID from '../../features/common/connecting-api/doctor/hooks/use-fetch-doctor-by-id';
import { UserType } from '../../features/common/constant-enum-type';
import OfficerBasicInfo from '../../features/person-basic-info/OfficerBasicInfo';

const DoctorDetail: React.FC = () => {
  const { id, appointmentDate } = useParams<{ id: string; appointmentDate: string }>();
  const { data, status, isLoading } = useFetchDoctorByID(id);

  return (
    <div>
      <PageTitle backTo={appointmentDate ? '/appointments' : '/doctors'} title="Doctor Detail" />
      {isLoading
        ? (
          <Loading />
        )
        : !data || status === 'error'
          ? (
            <div className="p-4 mx-auto mt-10 mb-4 text-lg text-center bg-white max-w-max rounded-2xl">
              <ExclamationCircleTwoTone twoToneColor="#f88d00" className="text-3xl" />
              <p>เกิดข้อผิดพลาด! กรุณาลองใหม่อีกครั้งในภายหลัง</p>
            </div>
          )
          : (
            <div className="flex flex-col justify-start mt-6 lg:flex-row">
              <div className="content-center lg:w-80 xl:w-96">
                {data?.success && data.doctor && <OfficerBasicInfo officer={data.doctor} />}
              </div>
              <div className="content-center w-full mt-6 lg:ml-6 lg:mt-0">
                <MainInfo userType={UserType.Doctor} />
              </div>
            </div>
          )}
    </div>
  );
};

export default DoctorDetail;
