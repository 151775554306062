import React from 'react';
import { useParams } from 'react-router';
import PageTitle from '../../features/common/components/PageTitle';
import AddNewPatientForm from '../../features/common/components/form/AddNewPatientForm';

const AddNewPatient: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  return (
    <div>
      <PageTitle title={id ? 'Edit Patient Detail' : 'Add New Patient'} backTo={id ? `/patient/${id}` : '/patients'} />
      <div className="w-full p-6 mt-6 bg-white rounded-3xl">
        <AddNewPatientForm />
      </div>
    </div>
  );
};

export default AddNewPatient;
