/* eslint-disable react/require-default-props */
import React from 'react';
import { Menu } from 'antd';
import UserOutlined from '@ant-design/icons/UserOutlined';
import CalendarOutlined from '@ant-design/icons/CalendarOutlined';
import { useRecoilValue } from 'recoil';
import { NavLink } from 'react-router-dom';
import { UserSchema } from '../form-schema/user/UserSchema';
import { UserType } from '../constant-enum-type';
import UnreadChatState from '../../../common-state/unread-chat-state';

interface ISideMenuProps {
  user?: UserSchema;
}

const SideMenu: React.FC<ISideMenuProps> = (props) => {
  const { user } = props;
  const queryURL = window.location.pathname;
  const selectedKeys = queryURL === '/' || queryURL === '' ? ['/appointments'] : [queryURL];
  const unreadChatState = useRecoilValue(UnreadChatState);

  return (
    <Menu className="text-base mb-4" theme="light" mode="inline" defaultSelectedKeys={[`${selectedKeys}`]} selectedKeys={[`${selectedKeys}`]}>
      <Menu.Item key="/appointments" icon={<CalendarOutlined />} className="flex items-center" style={{ marginTop: '0px' }}>
        <NavLink className="md:text-base lg:text-lg  m-0 p-0" to="/appointments">Appointments</NavLink>
      </Menu.Item>
      <>
        {user?.user_type === UserType.Nurse && (
        <>
          <Menu.Item key="/patients" icon={<UserOutlined />} className="flex items-center">
            <NavLink className="md:text-base lg:text-lg" to="/patients">Patients</NavLink>
          </Menu.Item>
          <Menu.Item key="/patient-summary" icon={<span><img alt="patient-summary" src="/static/icon/people.svg" className={queryURL.includes('/patient-summary') ? 'custom-class-img opacity-80' : ''} style={{ width: 18 }} loading="lazy" /></span>} className="flex items-center">
            <NavLink className="md:text-base lg:text-lg" to="/patient-summary">Patient Summary</NavLink>
          </Menu.Item>
          <Menu.Item key="/xray" icon={<span><img alt="xray" src="/static/icon/xray.svg" style={{ width: 18 }} /></span>} className="flex items-center">
            <NavLink className="md:text-base lg:text-lg" to="/xray">X-ray</NavLink>
          </Menu.Item>
          <Menu.Item key="/doctors" icon={<span><img alt="doctors" src="/static/icon/doctors.svg" className={queryURL.includes('/doctors') ? 'custom-class-img opacity-80' : ''} style={{ width: 18 }} loading="lazy" /></span>} className="flex items-center">
            <NavLink className="md:text-base lg:text-lg" to="/doctors">Physicians</NavLink>
          </Menu.Item>
          <Menu.Item key="/nurses" icon={<span><img alt="nurses" src="/static/icon/nurses.svg" className={queryURL.includes('/nurses') ? 'custom-class-img opacity-80' : ''} style={{ width: 18 }} loading="lazy" /></span>} className="flex items-center">
            <NavLink className="md:text-base lg:text-lg" to="/nurses">Nurses</NavLink>
          </Menu.Item>
          <Menu.Item key="/chat" icon={<span><img alt="chats" src="/static/icon/chat-gray.svg" className={queryURL.includes('/chat') ? 'custom-class-img opacity-80' : ''} style={{ width: 18 }} loading="lazy" /></span>} className="flex items-center w-full">
            <span className="flex items-center justify-between w-full">
              <NavLink className="flex items-center justify-between w-full md:text-base lg:text-lg" to="/chat">Chat</NavLink>
              {unreadChatState > 0 && (
                <span className="flex flex-col items-center justify-center h-5 px-2 text-white rounded-full" style={{ background: '#FB5E2A' }}>{unreadChatState > 0 && unreadChatState}</span>
              )}
            </span>
          </Menu.Item>
          <Menu.Item key="/e-learning" icon={<span><img alt="notifications" src="/static/icon/notifications.svg" className={queryURL.includes('/e-learning') ? 'custom-class-img opacity-80' : ''} style={{ width: 18 }} loading="lazy" /></span>} className="flex items-center">
            <NavLink className="md:text-base lg:text-lg" to="/e-learning">e-Learning</NavLink>
          </Menu.Item>
          <Menu.Item key="/shortcut-message" icon={<span><img alt="notifications" src="/static/icon/mail.svg" className={queryURL.includes('/shortcut-message') ? 'custom-class-img opacity-80' : ''} style={{ width: 20 }} loading="lazy" /></span>} className="flex items-center">
            <NavLink className="md:text-base lg:text-lg" to="/shortcut-message">Shortcut Message</NavLink>
          </Menu.Item>
        </>
        )}
        {user?.user_type === UserType.Doctor && (
        <>
          <Menu.Item key="/patients" icon={<UserOutlined />} className="flex items-center">
            <NavLink className="md:text-base lg:text-lg" to="/patients">Patients</NavLink>
          </Menu.Item>
          <Menu.Item key="/patient-summary" icon={<span><img alt="patient-summary" src="/static/icon/people.svg" className={queryURL.includes('/patient-summary') ? 'custom-class-img opacity-80' : ''} style={{ width: 18 }} loading="lazy" /></span>} className="flex items-center">
            <NavLink className="md:text-base lg:text-lg" to="/patient-summary">Patient Summary</NavLink>
          </Menu.Item>
        </>
        )}
      </>
    </Menu>
  );
};

export default SideMenu;
