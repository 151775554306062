/* eslint-disable react/require-default-props */
import UserOutlined from '@ant-design/icons/UserOutlined';
import Avatar from 'antd/lib/avatar/avatar';
import React from 'react';
import config from '../../../config';
import FirstLetter from './persons/FirstLetter';

interface IAvatarImageProps {
  imgURL?: string;
  firstName?: string;
  size?: number;
  isHasBorder?: boolean;
}

const AvatarImage: React.FC<IAvatarImageProps> = (props) => {
  const { imgURL, firstName, size, isHasBorder } = props;
  const baseURL = `${config.apiPath}`;
  const image = `${baseURL}/cos/image/${imgURL}`;
  const firstLetter = FirstLetter(firstName);
  return (
    <Avatar
      src={imgURL && image}
      icon={!imgURL && firstName ? firstLetter : <UserOutlined />}
      size={firstName ? 80 : size || 32}
      className={`${isHasBorder && 'border-primary border-2 border-opacity-90'}`}
    />
  );
};

export default AvatarImage;
