/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/prefer-default-export */
import { TMessageSchema, TMessageSchemaWithUserInfo, TPatientsRecentChat } from '../../../chats/schema/MessageSchema';
import api from '../../utils/api';
import { APIResponse } from '../../constant-enum-type';

export type FetchLatestMessagesResponse = APIResponse<{ success: boolean; chat_message: (TMessageSchema | TMessageSchemaWithUserInfo)[] }>;
export type HistoryLatestMessagesResponse = APIResponse<{ success: boolean; chat_message: TMessageSchema[] }>;
export type TPatientsRecentChatResponse = APIResponse<{ success: boolean; recent_chat: TPatientsRecentChat[] }>;
export type UnsendMessageResponse = APIResponse<{ success: boolean; message: TMessageSchema }>;

export const fetchLatestMessages = (limit: number, room_id: string): Promise<FetchLatestMessagesResponse> => {
  return api
    .get(`/chat/latest?limit=${limit}&room_id=${room_id}&last_id=0`)
    .then((res) => res.data);
};

export const fetchHistoryMessages = (limit: number, room_id: string, last_id?: number): Promise<HistoryLatestMessagesResponse> => {
  return api
    .get(`/chat/history?limit=${limit}&room_id=${room_id}${last_id ? `&last_id=${last_id}` : ''}`)
    .then((res) => res.data);
};

export const fetchPatientsRecentChat = (limit = 30): Promise<TPatientsRecentChatResponse> => {
  return api
    .get(`/chat/v2/recent?limit=${limit}`)
    .then((res) => res.data);
};

export const uploadFileFromChat = async (body: FormData) => {
  return api
    .uploadImage('/cos/chat/upload', body)
    .then((res) => res.data);
};
