/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Modal } from 'antd';

export const FailedModal = (errorText: string) => {
  return (
    Modal.error({
      title: 'เกิดข้อผิดพลาด!',
      content: errorText,
      okText: 'ตกลง',
    })
  );
};

export const SuccessModal = (text = '') => {
  return (
    Modal.success({
      title: 'ดำเนินการเสร็จสิ้น!',
      content: `${text}`,
      okText: 'ตกลง',
    })
  );
};
