/* eslint-disable import/prefer-default-export */
import moment from 'moment-timezone';
import { GenderValue } from './constant-enum-type';

export const handleFormatMobileNumber = (number: string): string => {
  if (!number || number === '') return '-';
  const threeFirstDegits = number.substring(0, 3);
  const threeSecondDegits = number.substring(3, 6);
  const leftDegits = number.substring(6, number.length);
  const formatted = `(${threeFirstDegits}) ${threeSecondDegits} ${leftDegits}`;
  return formatted;
};

export const handleFormatHouseTelephoneNumber = (number: string): string => {
  if (!number || number === '') return '-';
  const threeFirstDegits = number.substring(0, 3);
  const threeSecondDegits = number.substring(3, 6);
  const leftDegits = number.substring(6, number.length);
  const formatted = `${threeFirstDegits} ${threeSecondDegits} ${leftDegits}`;
  return formatted;
};

export const handleCalculateBMI = (height: number | undefined, weight: number | undefined): string => {
  if (!height || !weight || height === 0 || weight === 0) return '-';
  const metersHeight = height / 100;
  const bmi = weight / (metersHeight * metersHeight);
  const formattedBMI = (Math.round(bmi * 100) / 100).toFixed(2);
  return formattedBMI.toString();
};

export const handleFormatCititzenID = (citizenID: string): string => {
  if (!citizenID || citizenID === '') return '-';
  const firstNumber = citizenID.charAt(0);
  const secondNumbers = citizenID.substring(1, 5);
  const thirdNumbers = citizenID.substring(5, 10);
  const fourthNumber = citizenID.substring(10, 12);
  const lastNumber = citizenID.substring(12, citizenID.length);
  const formatted = `${firstNumber} ${secondNumbers} ${thirdNumbers} ${fourthNumber} ${lastNumber}`;
  return formatted;
};

export const handleCalculateAge = (birthDate: string): number | string => {
  const momentYearBirthDate = moment(birthDate).year();
  if (momentYearBirthDate === 1) return '-';
  const age = moment().diff(moment(birthDate), 'years');
  return age;
};

export const handleRenderDateOfBirth = (birthDate: string): string => {
  let returnValue = '';
  if ((moment(birthDate).year()) === 1) {
    returnValue = '-';
  } else {
    // if ((moment(birthDate).year()) !== 1 && (moment(birthDate).month()) !== 0) {
    // returnValue = `${moment(birthDate).format('MMMM')} ${Number(moment(birthDate).format('YYYY')) + 543}`;
    // } else {
    returnValue = `${moment(birthDate).format('D')} ${moment(birthDate).format('MMMM')} ${Number(moment(birthDate).format('YYYY')) + 543}`;
    // }
  }
  return returnValue;
};

export const handleFormatGender = (gender: string, isEnglish?: boolean): string => {
  let genderWord = '';
  if (isEnglish) {
    if (gender === GenderValue.male) { genderWord = 'Male'; }
    if (gender === GenderValue.female) { genderWord = 'Female'; }
  } else {
    if (gender === GenderValue.male) { genderWord = 'ชาย'; }
    if (gender === GenderValue.female) { genderWord = 'หญิง'; }
  }
  return genderWord;
};

export const HandleFormatDate = (date: string): string => {
  if (!date) return '-';
  return moment(date).add(543, 'year').format('LL');
};

const handleSetMonth = (month: number): string => {
  const realMoth = month + 1;
  switch (realMoth) {
    case 1: return 'มกราคม';
      break;
    case 2: return 'กุมภาพันธ์';
      break;
    case 3: return 'มีนาคม';
      break;
    case 4: return 'เมษายน';
      break;
    case 5: return 'พฤษภาคม';
      break;
    case 6: return 'มิถุนายน';
      break;
    case 7: return 'กรกฎาคม';
      break;
    case 8: return 'สิงหาคม';
      break;
    case 9: return 'กันยายน';
      break;
    case 10: return 'ตุลาคม';
      break;
    case 11: return 'พฤศจิกายน';
      break;
    case 12: return 'ธันวาคม';
      break;
    default: return '-';
      break;
  }
};

export const handleFormatDateWithDay = (date: string): string => {
  if (!date) return '-';
  const dateTimeWithDay = `${moment(date).format('dddd')} ${moment(date).date()} ${handleSetMonth(moment(date).month())} ${moment(date).add(543, 'year').format('yyyy')}`;
  return dateTimeWithDay;
};

export const handleFormatTime = (date: string): string => {
  if (!date) return '-';
  // if (moment(new Date()).date() - moment(date).date() >= 1) return moment(date).add(543, 'year').format('D/M/YY LT');
  return moment(date).add(543, 'year').format('LT');
};

export const HandleFormatDateMonth = (dateString: string): string => {
  const date = moment(dateString).date();
  const month = moment(dateString).format('MMMM');
  // const noteYear = Number(selectedProgressNoteYear);
  const formatString = `${date} ${month}`;
  return formatString;
};
