/* eslint-disable react/require-default-props */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import config from '../../../../../config';
import { TAttachment } from '../../../form-schema/attachments/AttachmentSchema';
import PdfViewer from './Pdf';

type Props = {
  list: TAttachment[];
  handleDelete: (attachment_id: string) => void;
  btnDeleteDisabled?: boolean;
};

const PdfList: React.FC<Props> = ({ list, handleDelete, btnDeleteDisabled = false }) => {
  const [isOpenIndex, setIsOpenIndex] = useState<number>(-1);
  return (
    <div>
      {list.map(({ _id, name, url }, index) => (
        <PdfViewer
          visiable={isOpenIndex === -1 ? true : index === isOpenIndex ? true : false}
          isBackBtn={isOpenIndex === -1 ? false : index === isOpenIndex ? true : false}
          key={_id}
          name={name}
          url={`${config.pdfUrl}/${url}`}
          isOpen={index === isOpenIndex}
          toggleExpand={() => setIsOpenIndex(index === isOpenIndex ? -1 : index)}
          handleDelete={() => handleDelete(_id)}
          disableDelete={btnDeleteDisabled}
        />
      ))}
    </div>
  );
};

export default PdfList;
