/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useQuery } from 'react-query';
import { fetchDoctors, FetchDoctorsResponse as _FetchDoctorsResponse } from '../api-action';

export type FetchDoctorsResponse = _FetchDoctorsResponse;
const useFetchDoctors = (userID: string | undefined) => {
  return useQuery(
    ['doctors', userID],
    () => fetchDoctors(),
    { refetchOnWindowFocus: false, cacheTime: 3600 },
  );
};

export default useFetchDoctors;
