/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';

import { Layout } from 'antd';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import SideMenu from './SideMenu';
import { UserSchema } from '../form-schema/user/UserSchema';
import { UserType } from '../constant-enum-type';

const { Sider, Content } = Layout;

// const getImgPath = (imgPath: string) => {
//   const basePath = '/static/image/';
//   return `${basePath}${imgPath}`;
// };

const setLayoutClass = (className: string) => {
  return `site-layout-background content-background-${className}`;
};

const LayoutComponent: React.FC = ({ children }) => {
  const [collapsed, setCollapse] = useState(false);
  const location = useLocation();
  const queryClient = useQueryClient();
  const queryData = queryClient.getQueryData<{ success: boolean; user_info: UserSchema }>(['user']);
  const user = queryData;

  if (location.pathname === '/login') {
    return <>{children}</>;
  }
  if (location.pathname.match(/^\/doctor\/tele\/([^\0])*/im)) {
    return <>{children}</>;
  }
  if (location.pathname.match(/^\/nurse\/tele\/([^\0])*/im)) {
    return <>{children}</>;
  }

  return (
    <>
      <Sider className="min-h-screen" theme="light" trigger={null} collapsible collapsed={collapsed}>
        <div className="h-20 p-0 bg-white">
          <img src="/logo-liver-new-no-bg.png" className={!collapsed ? 'w-3/5 mx-auto' : 'w-4/5 mx-auto'} alt="Logo" loading="lazy" />
        </div>
        <SideMenu user={user?.user_info} />
      </Sider>
      <Layout className="min-h-full">
        {user?.success
          && (
            <Navbar
              collapsed={collapsed}
              setCollapse={setCollapse}
              user={user.user_info}
            />
          )}
        <Content
          className={user?.user_info.user_type === UserType.Nurse
            ? setLayoutClass('nurse')
            : setLayoutClass('doctor')}
          key={0}
        >
          {children}
        </Content>
      </Layout>
    </>
  );
};

export default LayoutComponent;
