import React from 'react';
import { Radio } from 'antd';

import { PersonalNoteCirrhosisType } from '../../common/components/modals/PersonalNoteModal/type/PersonalNoteCirrhosis';

const PersonalNoteCirrhosisResult: React.FC<{
  value: PersonalNoteCirrhosisType;
}> = ({ value }) => {
  return (
    <div className="'flex flex-col pt-2 border-b">
      <p className="text-primary font-bold text-lg pb-2">Cirrhosis</p>
      <div className="pb-4">
        <Radio.Group value={value.radioSelected}>
          <Radio key="cirrhosis" value="cirrhosis">
            Cirrhosis
          </Radio>
          <Radio key="none_cirrhosis" value="none_cirrhosis">
            Non Cirrhosis
          </Radio>
        </Radio.Group>
      </div>
    </div>
  );
};

export default PersonalNoteCirrhosisResult;
