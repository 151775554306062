import React from 'react';
import { NavLink } from 'react-router-dom';

import { handleCalculateAge, handleFormatGender } from '../../common/all-about-string-method';
import AvatarImage from '../../common/components/AvatarImage';
import { UserType } from '../../common/constant-enum-type';
import { TDoctor } from '../../common/form-schema/doctors/DoctorSchema';
import { TPatient } from '../../common/form-schema/patients/PatientSchema';

interface Props {
  personInfo: TPatient | TDoctor;
  linkTo: string;
}

const PersonCardOnAppointment: React.FC<Props> = (props) => {
  const { personInfo, linkTo } = props;

  if (personInfo.user_type === UserType.Patient) {
    return (
      <NavLink to={linkTo}>
        <div className="h-40 pt-4 pb-4 pr-4 hover:bg-gray-50 rounded-2xl">
          <div className="flex items-center">
            <AvatarImage
              size={45}
              imgURL={personInfo.avatar_img_url}
            />
            <p className="ml-3 text-lg">{personInfo.name_title}{personInfo.first_name}  {personInfo.last_name}</p>
          </div>
          <div className="grid grid-cols-2 mt-2 text-base">
            <span className="">HN: <span className="font-normal">{(personInfo as TPatient).hn}</span></span>
            <span className="">Age: <span className="font-normal">{handleCalculateAge((personInfo as TPatient).date_of_birth)}</span></span>
            <span className="">Gender: <span className="font-normal">{handleFormatGender(personInfo.gender)}</span></span>
            <span className="">Code Dx: {(personInfo as TPatient).diagnosis_code}</span>
            <span className="">{(personInfo as TPatient).medical_benefit_scheme}</span>
          </div>
        </div>
      </NavLink>
    );
  }

  return (
    <NavLink to={linkTo}>
      <div className="h-40 pt-4 pb-4 pr-4 hover:bg-gray-50 rounded-2xl">
        <div className="flex items-center">
          <AvatarImage
            size={45}
            imgURL={personInfo.avatar_img_url}
          />
          <p className="ml-3 text-lg">{personInfo.name_title}{personInfo.first_name}  {personInfo.last_name}</p>
        </div>
      </div>
    </NavLink>
  );
};

export default PersonCardOnAppointment;
