/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const ErrorMsg = styled.p`
  margin-top: 2px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25;
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
`;

export const WarningMsg = styled.p`
  margin-top: 2px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25;
  --tw-text-opacity: 1;
  color: #FF8C00;
`;

export const HeadText = styled.p`
  margin-bottom: 0.5rem;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.75rem;
`;

export const Title = styled.div`
  color: #ED1C24;
  font-size: larger;
  margin-right: 10px;
  font-weight: 500;
`;

export const HeadParagrahp = styled.div`
font-size: 1rem;
line-height: 1.5rem;
font-weight: 600;
`;

export const SubHeadParagrahp = styled.div`
--tw-text-opacity: 1;
color: rgba(75, 85, 99, var(--tw-text-opacity));
font-size: 1rem;
line-height: 1.5rem;
margin-bottom: 0.5rem;
`;
