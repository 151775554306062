import React, { useEffect, useState } from 'react';
import { Input, InputRef } from 'antd';
import cx from 'classnames';

const MultiInputForm: React.FC<{
  numberOfInput: number;
  defaultValues: number[] | null;
  onChange: (value: number[]) => void;
}> = ({ numberOfInput = 4, onChange, defaultValues }) => {
  const inputs = [];
  const [values, setValues] = useState<string[]>(
    defaultValues?.map((v) => v.toString()) ?? [],
  );
  const refs: React.RefObject<InputRef>[] = [];

  useEffect(() => {
    setValues(defaultValues?.map((v) => v.toString()) ?? []);
  }, [defaultValues]);

  for (let i = 0; i < numberOfInput; i += 1) {
    const ref = React.createRef<InputRef>();
    refs.push(ref);
    if (values.length < numberOfInput) {
      values.push('');
    }
    inputs.push(
      <Input
        ref={ref}
        name={`multi-input-${i}`}
        className={cx(
          'w-10',
          { 'rounded-l-full': i === 0 },
          { 'rounded-r-full': i === numberOfInput - 1 },
        )}
        inputMode="numeric"
        onKeyUp={(e) => {
          if (e.key.charCodeAt(0) === 66 && values[i] === '' && refs[i - 1]) {
            refs[i - 1].current?.focus();
          }
        }}
        onChange={(e) => {
          const length = 2;
          if (e.target.value === '' && refs[i - 1]) {
            refs[i - 1].current?.focus();
          } else if (
            e.target.value !== ''
            && values[i].length === length - 1
            && refs[i + 1]
          ) {
            refs[i + 1].current?.focus();
          }
          if (/^\d{0,1}$/.test(e.target.value)) {
            setValues((_) => {
              values[i] = e.target.value;
              onChange(
                values
                  .map((value) => parseInt(value, 10))
                  .filter((value) => Number.isFinite(value)),
              );
              return [...values];
            });
          } else {
            const getLastChar = e.target.value.at(e.target.value.length - 1);
            if (getLastChar) {
              setValues((_) => {
                values[i] = values[i].length === 2
                  ? `${values[i].substring(0, 1)}${getLastChar}`
                  : `${values[i]}${getLastChar}`;
                onChange(
                  values
                    .map((value) => parseInt(value, 10))
                    .filter((value) => Number.isFinite(value)),
                );
                return [...values];
              });
            }
          }
        }}
        value={values[i]}
        type="number"
      />,
    );
  }
  return <div className="flex h-10">{[...inputs]}</div>;
};

export default MultiInputForm;
