/* eslint-disable react/require-default-props */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { Typography } from 'antd';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import EyeOutlined from '@ant-design/icons/EyeOutlined';
import useLogin, { LoginResponse } from '../../connecting-api/auth/hooks/use-login';
import { authTokenLocalStorage } from '../../utils/local-storage';
import initialValues, { TLogin, validationSchema } from '../../form-schema/login/LoginSchema';
import { InputBox } from '../Input';
import { LoginButton } from '../Button';
import { ErrorMsg } from '../Lable';

interface ILoginFromProps {
  headText?: string;
  subHeadText?: string;
}

const { Title, Paragraph } = Typography;

const LoginForm: React.FC<ILoginFromProps> = (props) => {
  const {
    headText,
    subHeadText,
  } = props;

  const history = useHistory();
  const queryClient = useQueryClient();

  const [isShowWrongAuthen, setIsShowWrongAuthen] = useState(false);
  const [isShowError, setIsShowError] = useState(false);
  const [revealPassword, setRevealPassword] = useState(false);
  const { mutate: login } = useLogin();

  const handleAuthen = async (res: LoginResponse) => {
    if (res.success) {
      authTokenLocalStorage.set(res.access_token);
      await queryClient.refetchQueries(['user']);
      history.push('/appointments');
    } else {
      setIsShowWrongAuthen(true);
    }
  };

  const handleErrorAuthen = () => {
    setIsShowError(true);
  };

  const onSubmit = (values: TLogin) => {
    login(values, { onSuccess: handleAuthen, onError: () => handleErrorAuthen() });
  };

  const formik = useFormik<TLogin>({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = formik;

  const handleRevealPassword = (toggle: boolean) => {
    setRevealPassword(!toggle);
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="flex flex-col p-6 mx-auto bg-white shadow-md rounded-3xl" style={{ width: '448px' }}>
        <p className="mb-5 text-3xl font-semibold" style={{ color: '#1E73BE' }}>
          Welcome
        </p>
        <>
          <div className="flex justify-center w-full mb-4">
            {/* <img className="w-36" src="/static/image/chula-hospital-logo.png" alt="Hospital logo" /> */}
            <img className="w-48 mx-auto" src="/logo-liver-new-no-bg.png" alt="CE logo" loading="lazy" />
          </div>
          <div className="mx-auto text-center">
            <Title level={5}>{headText}</Title>
          </div>
          <div className="mx-auto text-center">
            <Paragraph type="secondary" className="text-base">{subHeadText}</Paragraph>
          </div>
          <div className="flex flex-col mb-2">
            <p className="mb-1 text-base text-left">Username</p>
            <InputBox
              id="username"
              name="username"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.username}
              onPressEnter={() => handleSubmit()}
            />
            {errors.username && touched.username && <ErrorMsg className="mt-1 text-left">{errors.username}</ErrorMsg>}
          </div>
          <div className="flex flex-col mb-2">
            <p className="mb-1 text-base text-left">Password</p>
            <InputBox
              id="password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              type={revealPassword ? 'text' : 'password'}
              suffix={<EyeOutlined onClick={() => handleRevealPassword(revealPassword)} />}
              onPressEnter={() => handleSubmit()}
            />
            {errors.password && touched.password && <ErrorMsg className="mt-1 text-left">{errors.password}</ErrorMsg>}
          </div>
        </>
        <div className="mt-2">
          <LoginButton
            onClick={() => handleSubmit()}
          >
            Log in
          </LoginButton>
          {isShowWrongAuthen && <ErrorMsg className="mt-4">ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง</ErrorMsg>}
          {isShowError && <ErrorMsg className="mt-4">เกิดข้อผิดพลาด กรุณาลองอีกครั้งภายหลัง</ErrorMsg>}
        </div>
      </form>
    </>
  );
};
export default LoginForm;
