/* eslint-disable import/prefer-default-export */
import config from '../../../config';

const AUTH_TOKEN_KEY = `auth_token:${config.uniqueCode}`;

export const authTokenLocalStorage = {
  get: (): string | null => localStorage.getItem(AUTH_TOKEN_KEY),
  set: (value: string): void => localStorage.setItem(AUTH_TOKEN_KEY, value),
  remove: (): void => localStorage.removeItem(AUTH_TOKEN_KEY),
};

export const teleconferenceLocalStorage = {
  used: (): boolean => {
    if (localStorage.getItem('teleconference') === 'used') {
      return true;
    }
    return false;
  },
  set: (): void => localStorage.setItem('teleconference', 'used'),
  remove: (): void => localStorage.removeItem('teleconference'),
};
