/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import moment from 'moment-timezone';
import { useParams } from 'react-router';

import Loading from '../common/components/Loading';
import useFetchPatientByID from '../common/connecting-api/patient/hooks/use-fetch-patient-by-id';
import { TPatient } from '../common/form-schema/patients/PatientSchema';
import { isRequireEGD } from '../common/utils/patient';

const calculateXrayInterval = (patient: TPatient): number => {
  const { gender, date_of_birth: birthDate, diagnosis_code: code } = patient;
  const age = moment().diff(moment(birthDate), 'years');
  if (!code) return 0;
  if (code.length !== 5) return 0;
  const [c1, c2, c3] = code.split(''); // need only first 3 characters
  if (c2 === 'C') {
    if (c3 === 'H') return 6;
    if (c3 === 'N') return 6;
    if (c3 === 'O') return 6;
  }
  if (
    (['b', 'B'].includes(c1) && c2 === 'O')
    && ((gender === 'male' && age >= 40) || (gender === 'female' && age >= 50))
  ) {
    return 12;
  }
  return 0;
};

const XrayInfo: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useFetchPatientByID(id);

  if (isLoading) return <Loading />;

  if (!data || !data.success) {
    return (
      <div className="p-4 text-center">
        <p className="text-base font-normal">
          เกิดข้อผิดพลาด กรุณาลองอีกครั้งในภายหลัง
        </p>
      </div>
    );
  }

  const { patient } = data;
  if (!patient || (!patient.last_xray_date && !patient.last_egd_date)) {
    return (
      <div className="p-4 text-center">
        <p className="text-base font-normal">
          ไม่มีข้อมูล
        </p>
      </div>
    );
  }

  const lastXrayDate = `${moment(patient.last_xray_date).format('DD/MM')}/${moment(patient.last_xray_date).year() + 543}`;
  const lastEgdDate = `${moment(patient.last_egd_date).format('DD/MM')}/${moment(patient.last_xray_date).year() + 543}`;
  const xrayInterval = calculateXrayInterval(patient);

  if (!lastXrayDate || !xrayInterval) {
    return (
      <div className="p-4 text-center">
        <p className="text-base font-normal">
          ยังไม่มีประวัติการตรวจ
        </p>
      </div>
    );
  }

  const intervalText = xrayInterval < 12
    ? `${xrayInterval} เดือน`
    : `${Math.floor(xrayInterval / 12)} ปี`;

  const lastDate = moment(lastXrayDate, 'DD/MM/YYYY').format('LL');
  const nextDate = moment(lastXrayDate, 'DD/MM/YYYY').add(xrayInterval, 'months').format('LL');

  const showEgd = isRequireEGD(patient?.diagnosis_code ?? '');
  const lastEgdDateText = moment(lastEgdDate, 'DD/MM/YYYY').format('LL');

  return (
    <div className="flex flex-col p-4 text-base">
      {patient.last_xray_date && (
        <>
          <h3 className="font-semibold">วันที่ได้รับการ {patient.xray_type} ครั้งล่าสุด</h3>
          <p>{lastDate}</p>
          <br />
          <h3 className="font-semibold">วันครบกำหนด {patient.xray_type} {intervalText}</h3>
          <p>{nextDate}</p>
          <br />
        </>
      )}
      {showEgd && (
        <>
          <h3 className="font-semibold">วันที่ได้รับการตรวจ EGD ครั้งล่าสุด</h3>
          <p>{lastEgdDateText}</p>
        </>
      )}
    </div>
  );
};

export default XrayInfo;
