/* eslint-disable react/require-default-props */
/* eslint-disable react/no-string-refs */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import {
  Popconfirm,
  Modal,
} from 'antd';
import { useQueryClient } from 'react-query';
import 'moment/locale/th';
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';
import RollbackOutlined from '@ant-design/icons/RollbackOutlined';
import moment from 'moment-timezone';
import { useFormik } from 'formik';
import useEditAppointment from '../../common/connecting-api/appointment/hooks/use-edit-appointment';
import { TAppointment, TModifiedAppointment, validationSchema } from '../../common/form-schema/appointments/AppointmentSchema';
import { BackToAppointmentModalButton, CancelButton, PrimaryButton } from '../../common/components/Button';
import { Title } from '../../common/components/Lable';
import useDeleteAppointmentByAppointmentID from '../../common/connecting-api/appointment/hooks/use-delete-appointment-by-appointment-id';
import AppointmentModal from '../../common/components/modals/AppointmentModal';
import useConfirmShiftAppoitment from '../../common/connecting-api/appointment/hooks/use-confirm-shift-appointment';
import AppointmentInputs from './AppointmentInputs';
import { UserSchema } from '../../common/form-schema/user/UserSchema';
import useFetchDoctorByID from '../../common/connecting-api/doctor/hooks/use-fetch-doctor-by-id';
import Loading from '../../common/components/Loading';

interface IEditAppointmentDiagnosisModalProps {
  isOpenEditAppointmentModal: boolean;
  handleCancelEditModal: () => void;
  handleToAppointmentInfo: () => void;

  appointment: TAppointment;
  isShiftAppointment?: boolean;

  handleGoToAppointmentDate?: (date: moment.Moment) => void;
}

const Footer = (props: {
  onCancel: () => void;
  text: { submit: string; cancel: { title: string; button: string } };
}) => {
  const [isVisibleConfirmPopup, setIsVisibleConfirmPopup] = useState(false);
  const onSurelyCancel = () => {
    props.onCancel();
    setIsVisibleConfirmPopup(false);
  };
  return (
    <div className="flex justify-between mt-2 text-base font-medium flow-row">
      <Popconfirm
        key={0}
        title={props.text.cancel.title}
        open={isVisibleConfirmPopup}
        onConfirm={onSurelyCancel}
        onCancel={() => setIsVisibleConfirmPopup(false)}
        className="text-lg font-normal"
        okText="Yes, I'm sure"
        cancelText="Back"
        icon={<ExclamationCircleFilled style={{ color: '#CF1322' }} />}
      >
        <CancelButton onClick={() => setIsVisibleConfirmPopup(true)}>
          {props.text.cancel.button}
        </CancelButton>
      </Popconfirm>
      <PrimaryButton htmlType="submit">
        {props.text.submit}
      </PrimaryButton>
    </div>
  );
};

const EditAppointmentDiagnosisModal: React.FC<IEditAppointmentDiagnosisModalProps> = (props) => {
  const {
    isOpenEditAppointmentModal,
    handleCancelEditModal,
    handleToAppointmentInfo,
    appointment,
    isShiftAppointment,

    handleGoToAppointmentDate,
  } = props;

  const queryClient = useQueryClient();
  const userQuery = queryClient.getQueryData<{ success: boolean; user_info: UserSchema }>(['user']);

  const { mutate: editAppointment } = useEditAppointment();
  const { mutate: deleteAppointment } = useDeleteAppointmentByAppointmentID();
  const { mutate: confirmShiftAppointment } = useConfirmShiftAppoitment();

  const { data, isLoading } = useFetchDoctorByID(appointment.doctor_id._id);

  const initialValues: TModifiedAppointment = {
    e_learning: appointment.e_learning,
    is_ultrasound: appointment.is_ultrasound,
    mark_doctor_note_as_important: appointment.doctor_note.marked_note_as_important,
    nurse_note: appointment.nurse_note,
    doctor_note: appointment.doctor_note.note,
    appointment_date: isShiftAppointment ? appointment.requested_date[appointment.requested_date.length - 1].date[0] : appointment.appointment_date,
    patient_id: appointment.patient_id._id,
    doctor_id: appointment.doctor_id._id,
    appointment_type: appointment.appointment_type,
    blood_test: appointment.blood_test,
    avoid_food: appointment.avoid_food,
    // come_by_schedule: appointment.come_by_schedule === null || appointment.come_by_schedule === undefined ? undefined : appointment.come_by_schedule,
  };
  const oldAppointmentDate = appointment.appointment_date;

  const handleReloadAfterShiftAppointment = (appointmentDate: string) => {
    queryClient.invalidateQueries('all_incoming_appointments');
    const monthYearDatePointer = moment(appointmentDate).format('MMM YYYY');
    queryClient.invalidateQueries(['get_appointments', monthYearDatePointer, (userQuery?.success ? `${userQuery.user_info._id}_${userQuery.user_info.user_type}` : 'no_user')]);
  };

  const handleOnEditAppointmentSuccess = (res: any, isCancelAppointment?: boolean) => {
    queryClient.invalidateQueries(['all_medical_note', appointment.patient_id._id]);
    queryClient.invalidateQueries(['incoming_appointments_by_patient_id', appointment.patient_id._id]);
    queryClient.invalidateQueries(['incoming_appointments_by_patient_id', appointment.doctor_id._id]);
    queryClient.invalidateQueries('get_appointments');
    queryClient.invalidateQueries(['patient_by_id', appointment.patient_id._id]);
    const successModal = AppointmentModal(res.appointment.appointment_date, true, isCancelAppointment ? 'ยกเลิกการนัดหมายเสร็จเรียบร้อย' : 'แก้ไขนัดหมายเสร็จเรียบร้อย');

    if (handleGoToAppointmentDate) {
      handleGoToAppointmentDate(res.appointment.appointment_date);
    }

    setTimeout(() => {
      successModal.destroy();
    }, 4000);
    handleCancelEditModal();
  };

  const handleOnShiftAppointmentSuccess = (res: any) => {
    const appointmentDateISO = res.appointment.appointment_date;
    const momentAppointmentDate = moment(appointmentDateISO);
    handleReloadAfterShiftAppointment(momentAppointmentDate.toISOString());
    const successModal = AppointmentModal(res.appointment.appointment_date, true, 'เลื่อนนัดหมายเสร็จเรียบร้อย');
    setTimeout(() => {
      successModal.destroy();
    }, 4000);
    handleCancelEditModal();
  };

  const handleAlertError = () => {
    handleCancelEditModal();
    Modal.error({
      title: 'เกิดข้อผิดพลาด!',
      content: 'มีข้อผิดพลาดเกิดขึ้น กรุณาลองใหม่อีกครั้งภายหลัง',
      okText: ' Close ',
    });
  };

  const handleGoBackToAppointmentInfo = () => {
    handleCancelEditModal();
    handleToAppointmentInfo();
  };

  const onSubmit = (values: TModifiedAppointment) => {
    let existedDoctorNote = values.doctor_note;
    if (!existedDoctorNote) {
      existedDoctorNote = '';
    }
    if (isShiftAppointment) {
      const {
        patient_id: p,
        doctor_id: d,
        ...resValues
      } = values;
      confirmShiftAppointment({
        id: appointment._id,
        body: {
          ...resValues,
          doctor_note: existedDoctorNote,
          answer: 'approved',
        },
      }, { onSuccess: handleOnShiftAppointmentSuccess, onError: handleAlertError });
    } else {
      editAppointment({
        id: appointment._id,
        body: {
          ...values,
          doctor_note: existedDoctorNote,
        },
      },
      { onSuccess: (res) => handleOnEditAppointmentSuccess(res), onError: handleAlertError });
    }
    if (values.appointment_date !== oldAppointmentDate) {
      // TODO: change notified status to false, so patient is able to get new notification
    }
  };

  const formik = useFormik<TModifiedAppointment>({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  const { handleSubmit } = formik;

  const onConfirmToCancel = () => {
    if (isShiftAppointment) {
      confirmShiftAppointment({
        id: props.appointment._id,
        body: { answer: 'rejected' },
      }, { onSuccess: handleOnShiftAppointmentSuccess, onError: handleAlertError });
    } else {
      deleteAppointment(appointment._id,
        { onSuccess: (res) => handleOnEditAppointmentSuccess(res, true), onError: handleAlertError });
    }
  };

  const disabledFields = {
    patient_id: true,
    appointment_type: true,
    ...(isShiftAppointment ? { doctor_id: true } : {}),
  };

  return (
    <Modal
      title={(
        <div className="flex flex-row items-center align-middle">
          {/* <div className="flex flex-col"> */}
          <Title>{isShiftAppointment ? 'Approve Patient\'s Request' : 'Edit Appointment'}</Title>
          {/* <span className="text-sm opacity-50">{moment(formik.values.appointment_date).add(543, 'year').format('LL')} เวลา {moment(formik.values.appointment_date).format('LT')} น.</span> */}
          {/* </div> */}
          <BackToAppointmentModalButton onClick={isShiftAppointment ? handleCancelEditModal : handleGoBackToAppointmentInfo} className="ml-4 hover:bg-purple-400 hover:text-white hover:border-transparent">
            <RollbackOutlined />
            <p>Return</p>
          </BackToAppointmentModalButton>
        </div>
      )}
      open={isOpenEditAppointmentModal}
      centered
      width="60%"
      onCancel={handleCancelEditModal}
      footer={null}
    >
      {isLoading
        ? (
          <Loading />
        )
        : (
          data?.success && data.doctor
            && (
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col text-base text-left">
                  {isShiftAppointment
                    && (
                      <div className="flex justify-start mb-3 text-base font-medium">
                        {/* <div className="grid gap-x-2" style={{ gridTemplateColumns: 'fit-content(100%) fit-content(100%)' }}>
                    <p>ผู้รับบริการขอเปลี่ยนวันนัดหมาย จากวันที่</p>
                    <p>{moment(appointment.appointment_date).format('LL')}</p>
                    <p className="text-right">เป็นวันที่</p>
                    {appointment.requested_date[0].date.map((date, idx) => <>{idx > 0 && <p className="text-right">หรือ</p>}<p className="text-right">{moment(date).format('LL')}</p></>)}
                  </div> */}
                        <div>
                          {/* <span>เลื่อนวันนัดหมายจาก <br /> จากวันที่ {moment(appointment.appointment_date).add(543, 'year').format('LL')} - เป็นวันที่ {moment(appointment.requested_date[appointment.requested_date.length - 1].date[0]).add(543, 'year').format('LL')}</span> */}
                          <span>เลื่อนวันนัดหมาย <br /> วันนัดหมายเดิม : วันที่ {moment(appointment.appointment_date).add(543, 'year').format('LL')}</span>
                          {appointment.requested_date[0].date.length > 1 && appointment.requested_date[0].date.map((date, index) => {
                            if (index >= 1) {
                              return (
                                <p className="text-left">{index > 0 && 'หรือ'} {moment(date).format('LL')}</p>
                              );
                            }
                            return false;
                          })}
                        </div>
                      </div>
                    )}
                  <AppointmentInputs
                    formik={formik}
                    patientPhoneNumber={isShiftAppointment ? appointment.patient_id.mobile_number : undefined}
                    isAppointmentDateFieldShownFirst={isShiftAppointment}
                    disabledFields={disabledFields}
                    doctorUnavailableDays={data.doctor.unavailable_day}
                    oldAppointmentDate={formik.values.appointment_date}
                  />
                </div>
                <Footer
                  onCancel={onConfirmToCancel}
                  text={isShiftAppointment
                    ? {
                      submit: 'Approve Request',
                      cancel: { title: 'Are you sure to deny this request?', button: 'Deny Request' },
                    }
                    : {
                      submit: 'Save',
                      cancel: { title: 'Are you sure to cancel this appointment?', button: 'Cancel Appointment' },
                    }}
                />
              </form>
            )
        )}
    </Modal>
  );
};

export default EditAppointmentDiagnosisModal;
