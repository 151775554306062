import { APIResponse } from '../../constant-enum-type';
import api from '../../utils/api';

export enum NotificationType {
  APPOINTMENT = 'APPOINTMENT',
  CANCEL_APPOINTMENT = 'CANCEL_APPOINTMENT',
  TELECONFERENCE = 'TELECONFERENCE',
  ELEARNING = 'ELEARNING',
  MESSAGE = 'MESSAGE',
  NOTIFY_ONLINE_APPOINTMENT = 'NOTIFY_ONLINE_APPOINTMENT',
  SHIFT_APPOINTMENT = 'SHIFT_APPOINTMENT',
}

export interface SendNotificationBody {
  id: string;
  type: NotificationType;
  content: string[]; // htmlString[]
  appointment_id?: string;
}

export interface SendNotificationByPatientIDGroupBody {
  id: string[];
  type: NotificationType;
  notificationContent: string;
  content: string[]; // htmlString[]
}

export interface SendChatByPatientIDGroupBody {
  id: string[];
  notificationContent: string;
}

export interface SendNotificationByGroupShiftAppointmentIDBody {
  id: string[];
  type: NotificationType;
  content: string[]; // htmlString[]
}

interface SendNotificationByTagsBody {
  tags: string[]; // array of tag id
  content: string[];
}

export interface SendElearningByCodeDiagnosis {
  codeDiagnosis: string;
  type: NotificationType;
  notificationContent: string;
  content: string[]; // htmlString[]
}

export type FetchPatientTagsResponse = APIResponse<{ data: { [key: string]: any } }>; /* eslint-disable-line */

export const createNotification = (body: SendNotificationBody): Promise<APIResponse> => {
  const {
    id,
    type,
    content,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    appointment_id,
  } = body;
  return api
    .post('/notification/send-by-id', {
      type,
      content,
      patientId: id,
      appointmentId: appointment_id,
    })
    .then((res) => res.data);
};

export const createNotificationByTags = (body: SendNotificationByTagsBody): Promise<APIResponse> => {
  const {
    tags,
    content,
  } = body;
  return api
    .post('/notification/send-by-tags', {
      tags,
      content,
    })
    .then((res) => res.data);
};

export const fetchPatientTags = (): Promise<FetchPatientTagsResponse> => {
  return api
    .get('/system-configurations/patient_tags')
    .then((res) => res.data);
};

export const createNotificationByPatientIDGroup = (body: SendNotificationByPatientIDGroupBody): Promise<APIResponse> => {
  const {
    id,
    type,
    notificationContent,
    content,
  } = body;
  return api
    .post('/notification/send-by-group-id', {
      patientId: id,
      type,
      notification_content: notificationContent,
      content,
    })
    .then((res) => res.data);
};

export const sendChatByPatientIDGroup = (body: SendChatByPatientIDGroupBody): Promise<APIResponse> => {
  const {
    id,
    notificationContent,
  } = body;
  return api
    .post('/notification/send-chat-by-group-id', {
      patientId: id,
      notification_content: notificationContent,
    })
    .then((res) => res.data);
};

export const createNotificationByGroupShiftAppointment = (body: SendNotificationByGroupShiftAppointmentIDBody): Promise<APIResponse> => {
  const {
    id,
    type,
    content,
  } = body;
  return api
    .post('/notification/send-by-group-shift-appoitment-id', {
      appointmentId: id,
      type,
      content,
    })
    .then((res) => res.data);
};

export const createElearningByCodeDiagnosis = (body: SendElearningByCodeDiagnosis): Promise<APIResponse> => {
  const {
    codeDiagnosis,
    type,
    notificationContent,
    content,
  } = body;
  return api
    .post('/notification/send-by-code-diagnosis', {
      code_diagnosis: codeDiagnosis,
      type,
      notification_content: notificationContent,
      content,
    })
    .then((res) => res.data);
};
