import Select from 'antd/es/select';
import { FormikProps } from 'formik';
import React from 'react';
import Loading from '../../common/components/Loading';
import useFecthNameTitles from '../../common/connecting-api/name-title/hooks/use-fetch-name-title';
import { TNurseInitialValue } from '../../common/form-schema/nurses/NurseSchema';

interface Props {
  formik: FormikProps<TNurseInitialValue>;
}

const NameTitlesNurse: React.FC<Props> = (props) => {
  const {
    formik,
  } = props;

  const queryNameTitles = useFecthNameTitles();

  if (queryNameTitles.isLoading) return <Loading />;

  if (queryNameTitles.status !== 'success' || !queryNameTitles.data.success) return null;

  const handleSelectNameTitle = (key: string) => {
    if (queryNameTitles.data.success && queryNameTitles.data.data && queryNameTitles.data.data.value) {
      const foundedNameTitle = queryNameTitles.data.data.value.find((value) => value.key === key);
      formik.setFieldValue('name_title', foundedNameTitle?.value);
    }
  };

  return (
    <Select
      bordered={false}
      className="w-full text-base border-2 rounded-3xl"
      onChange={(e) => handleSelectNameTitle(e.toString())}
      options={queryNameTitles.data.data.value.map((title) => ({ label: title.value, value: title.key }))}
      value={formik.values.name_title === '' ? '' : formik.values.name_title}
      dropdownStyle={{ borderRadius: 20 }}
    />
  );
};

export default NameTitlesNurse;
