import React from 'react';
import { Radio } from 'antd';

import { PersonalNoteFamilyHistoryType } from '../../common/components/modals/PersonalNoteModal/type/PersonalNoteFamilyHistory';

const PersonalNoteFamilyHistoryResult: React.FC<{
  value: PersonalNoteFamilyHistoryType;
}> = ({ value }) => {
  return (
    <div className="'flex flex-col pt-2 border-b">
      <p className="text-primary font-bold text-lg pb-2">Family history</p>
      <div className="pb-4">
        <Radio.Group value={value.isShow}>
          <Radio key="yes" value>
            Yes
          </Radio>
          <Radio key="no" value={false}>
            No
          </Radio>
        </Radio.Group>
      </div>
      {value.isShow && (
        <>
          {value.hcc.isChecked && (
            <div className="pb-4">
              <p className="text-base font-bold">HCC</p>
              <p className="text-base text-gray-500">
                {value.hcc.text !== '' ? value.hcc.text : '-'}
              </p>
            </div>
          )}
          {value.caColon.isChecked && (
            <div className="pb-4">
              <p className="text-base font-bold">CA colon</p>
              <p className="text-base text-gray-500">
                {value.caColon.text !== '' ? value.caColon.text : '-'}
              </p>
            </div>
          )}
          {value.others.isChecked && (
            <div className="pb-4">
              <p className="text-base font-bold">Others</p>
              <p className="text-base text-gray-500">
                {value.others.text !== '' ? value.others.text : '-'}
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PersonalNoteFamilyHistoryResult;
