/* eslint-disable react/require-default-props */
import React, { useEffect, useRef } from 'react';
import {
  Editor,
  EditorState,
  CompositeDecorator,
  ContentState,
  RawDraftContentBlock,
  // convertToRaw,
  // convertFromHTML,
} from 'draft-js';

import { convertToHTML } from 'draft-convert';
import { EditorLink, findLinkEntries } from './EditorLink';
import 'draft-js/dist/Draft.css';

const LINK_MANAGEMENT_ELEMENTS = ['_LinkUtils-add-link-button', '_LinkUtils-remove-link-button'];

const styleMap = {
  HIGHLIGHT: {
    backgroundColor: '#1d8efa',
    color: '#ffffff',
  },
};

export const getHTML = (content: ContentState): string => {
  return convertToHTML({
    blockToHTML: (block: RawDraftContentBlock) => {
      if (block.type === 'PARAGRAPH' || block.type === 'unstyled') {
        return <p />;
      }
      return '';
    },
    entityToHTML: (entity, originalText) => {
      if (entity.type === 'LINK') {
        return <a href={entity.data.url}>{originalText}</a>;
      }
      return originalText;
    },
  })(content);
};

export const decorator = new CompositeDecorator([
  {
    strategy: findLinkEntries,
    component: EditorLink,
  },
]);

export const hasSelection = (rowState: EditorState): boolean => {
  const selection = rowState.getSelection();
  return !selection.isCollapsed();
};

interface EditorProp {
  withCancel?: boolean;
  editorStateProp: EditorState;
  onChangeProp: (newState: EditorState) => void;
  onHandleReturn?: () => 'handled';
  clearSelection?: () => void;
}

const EditorBlock: React.FC<EditorProp> = (props) => {
  const {
    withCancel,
    editorStateProp,
    onChangeProp,
    onHandleReturn,
    clearSelection,
  } = props;

  const editorRef = useRef<Editor>(null);
  const handleReturn = onHandleReturn || (() => 'handled');

  const [editorState, setEditorState] = [editorStateProp, onChangeProp];

  useEffect(() => {
    if (editorRef && editorRef.current) {
      editorRef.current.focus();
    }
  }, []);

  const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    const relatedElement = e.relatedTarget as React.HTMLAttributes<HTMLButtonElement | HTMLInputElement>;
    // click outside and got e.relatedTarget as null
    if (!relatedElement) {
      if (hasSelection(editorState)) {
        setEditorState(
          EditorState.moveFocusToEnd(editorState),
        );
      }
      return;
    }
    // Other relatedTarget but not the one withing Link Management group
    if (!LINK_MANAGEMENT_ELEMENTS.includes(relatedElement.id as string)) {
      if (hasSelection(editorState)) {
        setEditorState(
          EditorState.moveFocusToEnd(editorState),
        );
      }
    }
  };

  return (
    <div
      className={`py-1 px-4 border border-gray-300 rounded-3xl w-full ${withCancel ? 'mr-2' : 'mr-2'}`}
      style={{ cursor: 'text', maxWidth: `calc(100% - ${withCancel ? '50px' : '0'})` }}
      onBlur={handleBlur}
      aria-label="editor"
      role="button"
      tabIndex={-2}
      onKeyDown={() => null}
    >
      <Editor
        ref={editorRef}
        customStyleMap={styleMap}
        editorState={editorState}
        onChange={setEditorState}
        handleReturn={handleReturn}
        onFocus={clearSelection}
      />
    </div>
  );
};

export default EditorBlock;
