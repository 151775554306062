import { atom } from 'recoil';

import { TPatientsRecentChat } from '../features/chats/schema/MessageSchema';

const RecentChatState = atom<TPatientsRecentChat[]>({
  key: 'RecentChatState',
  default: [],
});

export default RecentChatState;
