/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/prefer-default-export */
import { TLogin } from '../../form-schema/login/LoginSchema';
import { APIResponse } from '../../constant-enum-type';
import api from '../../utils/api';
import { UserSchema } from '../../form-schema/user/UserSchema';

export type LoginResponse = APIResponse<{ success: boolean; access_token: string }>;
export type AuthMeResponse = APIResponse<{ success: boolean; user_info: UserSchema }>;
export const login = (body: TLogin): Promise<LoginResponse> => {
  return api
    .post('/auth/medical-officer/login/', body)
    .then((res) => res.data);
};

export const fetchUserData = (): Promise<AuthMeResponse> => {
  return api
    .get('/auth/me')
    .then((res) => res.data);
};
