import React from 'react';
import { ContentBlock, ContentState } from 'draft-js';

export const findLinkEntries = (contentBlock: ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState): void => {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK'
      );
    },
    callback,
  );
};

interface LinkProp {
  contentState: ContentState;
  entityKey: string;
}

export const EditorLink: React.FC<LinkProp> = (props) => {
  const { contentState, entityKey, children } = props;
  const { url } = contentState.getEntity(entityKey).getData();
  return (
    <a
      className="text-blue-500"
      href={url}
      rel="noopener noreferrer"
      target="_blank"
      aria-label={url}
    >
      {children}
    </a>
  );
};
