/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Modal } from 'antd';

const SuccessSendNotificationModal = () => {
  return (
    Modal.success({
      title: 'ส่งข้อความสำเร็จ',
      content: 'ระบบได้ส่งข้อความให้กับผู้รับบริการแล้ว',
      okText: 'OK',
    })
  );
};

// export default SuccessSendNotificationModal;

const handleSendNotificaitonSuccess = () => {
  const successModal = SuccessSendNotificationModal();
  setTimeout(() => {
    successModal.destroy();
  }, 4000);
};

export default handleSendNotificaitonSuccess;
