/* eslint-disable for-direction */
/* eslint-disable import/prefer-default-export */

export const days = [
  'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday',
];

export const dates = [
  {
    label: '1',
    value: '01',
  },
  {
    label: '2',
    value: '02',
  },
  {
    label: '3',
    value: '03',
  },
  {
    label: '4',
    value: '04',
  },
  {
    label: '5',
    value: '05',
  },
  {
    label: '6',
    value: '06',
  },
  {
    label: '7',
    value: '07',
  },
  {
    label: '8',
    value: '08',
  },
  {
    label: '9',
    value: '09',
  },
  {
    label: '10',
    value: '10',
  },
  {
    label: '11',
    value: '11',
  },
  {
    label: '12',
    value: '12',
  },
  {
    label: '13',
    value: '13',
  },
  {
    label: '14',
    value: '14',
  },
  {
    label: '15',
    value: '15',
  },
  {
    label: '16',
    value: '16',
  },
  {
    label: '17',
    value: '17',
  },
  {
    label: '18',
    value: '18',
  },
  {
    label: '19',
    value: '19',
  },
  {
    label: '20',
    value: '20',
  },
  {
    label: '21',
    value: '21',
  },
  {
    label: '22',
    value: '22',
  },
  {
    label: '23',
    value: '23',
  },
  {
    label: '24',
    value: '24',
  },
  {
    label: '25',
    value: '25',
  },
  {
    label: '26',
    value: '26',
  },
  {
    label: '27',
    value: '27',
  },
  {
    label: '28',
    value: '28',
  },
  {
    label: '29',
    value: '29',
  },
  {
    label: '30',
    value: '30',
  },
  {
    label: '31',
    value: '31',
  },
];

export const months = [
  {
    label: 'มกราคม',
    value: '1',
  },
  {
    label: 'กุมภาพันธ์',
    value: '2',
  },
  {
    label: 'มีนาคม',
    value: '3',
  },
  {
    label: 'เมษายน',
    value: '4',
  },
  {
    label: 'พฤษภาคม',
    value: '5',
  },
  {
    label: 'มิถุนายน',
    value: '6',
  },
  {
    label: 'กรกฎาคม',
    value: '7',
  },
  {
    label: 'สิงหาคม',
    value: '8',
  },
  {
    label: 'กันยายน',
    value: '9',
  },
  {
    label: 'ตุลาคม',
    value: '10',
  },
  {
    label: 'พฤศจิกายน',
    value: '11',
  },
  {
    label: 'ธันวาคม',
    value: '12',
  },
];

export const years: string[] = [];
for (let index = new Date().getFullYear(); index > new Date().getFullYear() - 200; index -= 1) {
  years.push((index + 543).toString());
}

export const future100Years: string[] = [];
for (let index = new Date().getFullYear(); index < new Date().getFullYear() + 100; index += 1) {
  future100Years.push((index + 543).toString());
}

export const shiftTimeReschedule: { time: number; label: string }[] = [
  {
    time: 0,
    label: 'ไม่เปลี่ยนแปลง',
  },
  {
    time: 3 * 60 * 1000,
    label: 'Add 3 minutes',
  },
  {
    time: 5 * 60 * 1000,
    label: 'Add 5 minutes',
  },
  {
    time: 10 * 60 * 1000,
    label: 'Add 10 minutes',
  },
  {
    time: 1 * 60 * 60 * 1000,
    label: 'Add an hour',
  },
  {
    time: -3 * 60 * 1000,
    label: 'Minus -3 minutes',
  },
  {
    time: -5 * 60 * 1000,
    label: 'Minus -5 minutes',
  },
  {
    time: -10 * 60 * 1000,
    label: 'Minus -10 minutes',
  },
  {
    time: -1 * 60 * 60 * 1000,
    label: 'Minus -an hour',
  },
];
