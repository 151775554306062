/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import { Checkbox } from 'antd';
import { TPersonalNoteTemplate } from '../../PersonalNoteModal';

export type PersonalNoteAutoimmuneHepatitisType = {
  isChecked: boolean;
};

export const personalNoteAutoimmuneHepatitisNewState: PersonalNoteAutoimmuneHepatitisType = {
  isChecked: false,
};

const PersonalNoteAutoimmuneHepatitis: React.FC<{
  personalNote: TPersonalNoteTemplate;
  value: PersonalNoteAutoimmuneHepatitisType | null;
  onHandleChange: (value: PersonalNoteAutoimmuneHepatitisType) => void;
}> = ({ personalNote, value, onHandleChange }) => {
  const { name } = personalNote;

  const [state, setState] = useState<PersonalNoteAutoimmuneHepatitisType>(value ?? personalNoteAutoimmuneHepatitisNewState);

  const onChangeCheckbox = (checked: boolean) => {
    const newState = {
      ...state,
      isChecked: checked,
    };
    setState(newState);
    onHandleChange(newState);
  };

  return (
    <div className="p-4 border">
      <div className="flex">
        <div className="flex flex-grow">
          <Checkbox
            name={name}
            className="pr-4"
            checked={state.isChecked}
            onChange={(e) => onChangeCheckbox(e.target.checked)}
          />
          <p>{name}</p>
        </div>
      </div>
    </div>
  );
};

export default PersonalNoteAutoimmuneHepatitis;
