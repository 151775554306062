/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useMutation } from 'react-query';
import { editAppointment, EditResponses as _EditResponse } from '../api-action';

export type EditResponses = _EditResponse;
const useEditAppointment = () => {
  return useMutation(editAppointment);
};

export default useEditAppointment;
