/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import ExclamationCircleTwoTone from '@ant-design/icons/ExclamationCircleTwoTone';
import FileExclamationTwoTone from '@ant-design/icons/FileExclamationTwoTone';

import { NavLink } from 'react-router-dom';
import PageTitle from '../../features/common/components/PageTitle';
import PersonCard from '../../features/common/components/persons/PersonCard';
import { TDoctor } from '../../features/common/form-schema/doctors/DoctorSchema';
import useFetchDoctors from '../../features/common/connecting-api/doctor/hooks/use-fetch-doctors';
import Loading from '../../features/common/components/Loading';
import { UserSchema } from '../../features/common/form-schema/user/UserSchema';
import SearchBar from '../../features/common/components/Search';

const handleFilterDoctors = (doctorsLists: false | TDoctor[] | undefined, searchKey: string) => {
  if (!doctorsLists) return false;
  return doctorsLists.filter((doctor: TDoctor) => {
    return (
      doctor.first_name.toLocaleLowerCase().includes(searchKey.toLowerCase())
      || doctor.last_name.toLocaleLowerCase().includes(searchKey.toLowerCase())
    );
  });
};

const DoctorPage: React.FC = () => {
  const [showDoctors, setShowDoctors] = useState('');

  const queryClient = useQueryClient();
  const queryData = queryClient.getQueryData<{ success: boolean; user_info: UserSchema }>(['user']);
  const user = queryData;

  const { data, status, isLoading } = useFetchDoctors(user?.user_info._id);

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowDoctors(e.target.value);
  };

  const filteredDoctors = handleFilterDoctors(data?.success && data.doctor, showDoctors);

  return (
    <>
      <PageTitle title="Physicians" />
      <div className="flex mt-6" style={{ height: 40 }}>
        <SearchBar onChangeSearch={onChangeSearch} buttonLabel="+ Add New Physician" />
      </div>
      {isLoading
        ? (
          <div><Loading /></div>
        )
        : (
          data?.success && data.doctor.length === 0
            ? (
              <div className="p-4 mx-auto mt-10 mb-4 text-lg text-center bg-white max-w-max rounded-2xl">
                <FileExclamationTwoTone twoToneColor="#f8db00" className="text-3xl" />
                <div className="flex flex-row">
                  <p>ไม่มีข้อมูลของแพทย์ กรุณาเพิ่มข้อมูล</p>
                  <NavLink className="mx-2 font-medium text-red-500 no-underline" to="/doctor/add-doctor">
                    กดที่นี่
                  </NavLink>
                </div>
              </div>
            )
            : !data || status === 'error'
              ? (
                <div className="p-4 mx-auto mt-10 mb-4 text-lg text-center bg-white max-w-max rounded-2xl">
                  <ExclamationCircleTwoTone twoToneColor="#f88d00" className="text-3xl" />
                  <p>เกิดข้อผิดพลาด! กรุณาลองใหม่อีกครั้งในภายหลัง</p>
                </div>
              )
              : (
                <div className="w-full mt-6">
                  <div className="grid w-full gap-x-10 gap-y-6 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
                    {filteredDoctors
                      && filteredDoctors.map((doctor: TDoctor) => {
                        return (
                          <PersonCard
                            key={doctor._id}
                            linkTo={`/doctor/${doctor._id}`}
                            doctor={doctor}
                          />
                        );
                      })}
                  </div>
                </div>
              ))}
    </>
  );
};

export default DoctorPage;
