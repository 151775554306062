import Modal from 'antd/lib/modal/Modal';
import React from 'react';
import { CancelButton, PrimaryButton } from '../Button';

interface Props{
  isShuttingDownModal: boolean;
  onCancel: () => void;
  onOk: (id:string) => void;
  id: string;
}
const ConfirmDelete: React.FC<Props> = (props) => {
  const {
    isShuttingDownModal,
  } = props;

  return (
    <Modal
      centered
      footer={null}
      title={null}
      width={480}
      open={isShuttingDownModal}
      onCancel={props.onCancel}
    >
      <div className="flex flex-col justify-center w-full h-full text-base">
        <div className="flex flex-col mx-auto text-center w-max">
          <p className="mx-auto mt-2 mb-0">คุณแน่ใจที่จะลบข้อมูลนี้ใช่หรือไม่</p>
          <p className="mx-auto mt-2 mb-0">กรุณายืนยันการทำงาน</p>
        </div>
        <div className="flex justify-center w-full mt-4">
          <CancelButton onClick={() => props.onCancel()} className="mx-1 hover:bg-white hover:text-red-600 hover:border-red-600">ยกเลิก</CancelButton>
          <PrimaryButton onClick={() => { props.onOk(props.id); }} className="mx-1">ยืนยัน</PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};
export default ConfirmDelete;
