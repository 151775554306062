/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import { Button, Checkbox, Modal } from 'antd';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import { UserSchema } from '../../common/form-schema/user/UserSchema';
import PersonalNoteModal, {
  PersonalNote,
  personalNoteNewState,
} from '../../common/components/modals/PersonalNoteModal/PersonalNoteModal';
import { useCreatePlainMedicalNote } from '../../common/connecting-api/appointment/hooks/use-manage-all-medical-note';
import {
  AddPersonalNoteButton,
  EditPersonalNoteButton,
} from '../../common/components/Button';
import PersonalNoteCirrhosisResult from './PersonalNoteCirrhosis';
import PersonalNoteFamilyHistoryResult from './PersonalNoteFamilyHistory';
import PersonalNoteFibroScanResult from './PersonalNoteFibroScan';
import PersonalNoteEgdResult from './PersonalNoteEgd';
import PersonalNoteColonoScopeResult from './PersonalNoteColonoScope';
import PersonalNoteUSResult from './PersonalNoteUS';
import PersonalNoteMriResult from './PersonalNoteMri';
import useFetchPatientByID from '../../common/connecting-api/patient/hooks/use-fetch-patient-by-id';
import { TPatient } from '../../common/form-schema/patients/PatientSchema';
import useEditPatientNoteTemplateByID from '../../common/connecting-api/patient/hooks/use-edit-patient-note-template-by-id';
import TinyMECShowDetail from '../../common/components/TinyMECShowDetail';
import PersonalNoteUDResult from './PersonalNoteUD/PersonalNoteUD';

interface Props {
  patientID: string;
  isCanAddOrEdit: boolean;
}

const PersonalNoteTemplate: React.FC<Props> = (props) => {
  const { patientID, isCanAddOrEdit } = props;
  const { data, isLoading } = useFetchPatientByID(patientID);
  const [patient, setPatient] = useState<TPatient | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { mutate: editPatientNoteTemplateByID } = useEditPatientNoteTemplateByID();

  const onOpenModalForAddOrEdit = () => {
    setIsOpen(true);
  };

  const handleAlertSuccessCreate = () => {
    setIsOpen(false);
    // queryClient.invalidateQueries(['all_medical_note', id]);
    Modal.success({
      title: 'สำเร็จ!',
      content: 'บันทึก Personal Note Template เสร็จเรียบร้อย',
      okText: ' OK ',
    });
  };

  const handleAlertErrorCreate = () => {
    setIsOpen(false);
    Modal.error({
      title: 'เกิดข้อผิดพลาด!',
      content: 'มีข้อผิดพลาดเกิดขึ้น กรุณาลองใหม่อีกครั้งภายหลัง',
      okText: ' Close ',
    });
  };

  const onSaveNote = async (note: any) => {
    editPatientNoteTemplateByID(
      {
        id: patientID,
        body: note,
      },
      {
        onSuccess: (_data) => {
          handleAlertSuccessCreate();
          if (_data.success && _data.patient) {
            setPatient(_data.patient);
          }
        },
        onError: () => {
          handleAlertErrorCreate();
        },
      },
    );
  };

  useEffect(() => {
    if (data?.success && data.patient) {
      setPatient(data.patient);
    }
  }, [data]);

  if (isLoading) {
    return (
      <div className="flex flex-col justify-center items-center pt-4 pb-6">
        <p className="text-base">กำลังโหลดข้อมูล</p>
      </div>
    );
  }
  if (patient === null) {
    return (
      <div className="flex flex-col justify-center items-center pt-4 pb-6">
        <p className="text-base">ไม่พบข้อมูลของ Patient</p>
      </div>
    );
  }

  const { personal_note_template: personalNoteTemplate } = patient;

  return (
    <>
      <PersonalNoteModal
        value={personalNoteTemplate ?? personalNoteNewState}
        isShuttingDownModal={isOpen}
        onCancel={() => {
          setIsOpen(false);
        }}
        onOk={onSaveNote}
      />
      <div className={cx({ hidden: personalNoteTemplate })}>
        <div className="flex flex-col justify-center items-center pt-4 pb-6">
          <p className="text-base">ยังไม่มีข้อมูล Personal Note</p>
        </div>
        {isCanAddOrEdit && (
          <div className="border-t pt-2 flex justify-end">
            <AddPersonalNoteButton onClick={onOpenModalForAddOrEdit}>
              Add Personal Note
            </AddPersonalNoteButton>
          </div>
        )}
      </div>
      <div className={cx({ hidden: !personalNoteTemplate })}>
        <div
          className={cx('flex flex-col pb-4 border-b', {
            hidden: personalNoteTemplate?.note === '',
          })}
        >
          <p className="text-primary font-bold text-lg pb-2">Note</p>
          <TinyMECShowDetail details={personalNoteTemplate?.note ?? ''} />
        </div>
        {personalNoteTemplate?.ud && (
          <PersonalNoteUDResult value={personalNoteTemplate.ud} />
        )}
        {personalNoteTemplate?.cirrhosis && (
          <PersonalNoteCirrhosisResult value={personalNoteTemplate.cirrhosis} />
        )}
        {personalNoteTemplate?.familyHistory && (
          <PersonalNoteFamilyHistoryResult
            value={personalNoteTemplate.familyHistory}
          />
        )}
        {personalNoteTemplate?.fibroScan && (
          <PersonalNoteFibroScanResult value={personalNoteTemplate.fibroScan} />
        )}
        {personalNoteTemplate?.egd && (
          <PersonalNoteEgdResult value={personalNoteTemplate.egd} />
        )}
        {personalNoteTemplate?.colonoScope && (
          <PersonalNoteColonoScopeResult
            value={personalNoteTemplate.colonoScope}
          />
        )}
        {personalNoteTemplate?.us && (
          <PersonalNoteUSResult value={personalNoteTemplate.us} />
        )}
        {personalNoteTemplate?.mri && (
          <PersonalNoteMriResult value={personalNoteTemplate.mri} />
        )}
        {isCanAddOrEdit && (
          <div className="pt-2 flex justify-end">
            <EditPersonalNoteButton onClick={onOpenModalForAddOrEdit}>
              Edit
            </EditPersonalNoteButton>
          </div>
        )}
      </div>
    </>
  );
};

export default PersonalNoteTemplate;
