import * as Yup from 'yup';
import { NotificationType } from '../../connecting-api/notification/api-action';
import { TDoctor } from '../doctors/DoctorSchema';
import { TPatient } from '../patients/PatientSchema';

export enum RequestDateResult {
  ongoing = 'wait_for_approval',
  approve = 'approved',
  reject = 'rejected',
}

export interface MedicalNoteAppointment {
  status: boolean;
  e_learning: [];
  is_ultrasound: boolean;
  nurse_note: string;
  _id: string;
  appointment_date: string;
  patient_id: {
    _id: string;
    avatar_img_url?: string;
    first_name: string;
    last_name: string;
    name_title?: string;
    gender: string;
    hn: string;
  };
  doctor_id: {
    _id: string;
    avatar_img_url?: string;
    first_name: string;
    last_name: string;
    name_title?: string;
  };
  hospital_id: string;
  appointment_type: string;
  doctor_note: string;
  blood_test: string;
  created_by: string;
  avoid_food: true;
  requested_date: [];
}

export interface TMedicalNote {
  _id: string;
  appointment_id: MedicalNoteAppointment;
  extra_visit: boolean;
  marked_note_as_important: boolean;
  note: string;
  patient_id: string;
  created_at: string;
  created_by: string;
  date: string;
}

export type TMedicalNoteInitialValue = Omit<TMedicalNote, '_id' | 'appointment_id' | 'created_at' | 'marked_note_as_important' | 'date'>;

export interface TAppointment {
  _id: string;
  status: boolean;
  e_learning: string[];
  patient_id: TPatient;
  appointment_type: 'online' | 'onsite';
  come_by_schedule?: boolean | undefined;
  created_by: string;
  requested_date: { date: string[]; process: RequestDateResult; reasons: [{ name: string; text: string }] }[];
  is_ultrasound: boolean;
  nurse_note?: string;
  doctor_note: TMedicalNote;
  appointment_date: string;
  doctor_id: TDoctor;
  blood_test: string;
  avoid_food: boolean;
  mark_doctor_note_as_important?: boolean;
  is_notified: boolean;
  live_updated_at?: string;
  is_canceled?: boolean;
}

export interface TModifiedAppointment {
  e_learning?: string[];
  is_ultrasound?: boolean;
  mark_doctor_note_as_important?: boolean;
  nurse_note?: string;
  doctor_note?: string;
  appointment_date?: string;
  patient_id?: string;
  doctor_id?: string;
  appointment_type?: string;
  blood_test?: string;
  created_by?: string;
  come_by_schedule?: boolean | undefined;
  avoid_food?: boolean;
  is_notified?: boolean;
}

export interface TNotificationAppointment {
  _id: string;
  is_read: boolean;
  patient_id: string;
  message: string;
  type: NotificationType;
  appointment_id: string;
}

// export interface TImportantDoctorNotes {
// patient_id: {
//   _id: string;
//   avatar_img_url?: string;
//   first_name: string;
//   last_name: string;
//   name_title?: string;
//   gender: string;
//   hn: string;
// };
// doctor_id: {
//   _id: string;
//   avatar_img_url?: string;
//   first_name: string;
//   last_name: string;
//   name_title?: string;
// };
//   appointment_date: string;
//   appointment_type: string;
//   doctor_note: string;
//   is_ultrasound: boolean;
//   mark_doctor_note_as_important: boolean;
//   nurse_note: string;
//   patient_note: string[];
//   _id: string;
//   blood_test: string;
//   e_learning: string[];
// }

export const validationSchema = Yup.object().shape({
  patient_id: Yup.string()
    .required('กรุณาเลือกผู้รับบริการ'),
  doctor_id: Yup.string()
    .required('กรุณาเลือกแพทย์'),
  appointment_type: Yup.string()
    .required('กรุณาเลือกประเภทการนัดหมาย'),
  appointment_date: Yup.string()
    .required('กรุณาเลือกวันนัดหมาย'),
});
