/* eslint-disable import/prefer-default-export */
export const SetDynamicTitleBar = (pathName: string): string => {
  let additionalTitleBar = '';
  if (pathName.includes('/patients')) {
    additionalTitleBar += ' - Patients';
  } else if (pathName.includes('/patient/add-patient')) {
    additionalTitleBar += ' - Add New Patient';
  } else if (pathName.includes('/patient/edit/')) {
    additionalTitleBar += ' - Edit Patient Detail';
  } else if (pathName.includes('/patient/')) {
    additionalTitleBar += ' - Patient Detail';
  } else if (pathName.includes('/patient-summary')) {
    additionalTitleBar += ' - Patient Summary';
  } else if (pathName.includes('/doctors')) {
    additionalTitleBar += ' - Doctors';
  } else if (pathName.includes('/doctor/add-doctor')) {
    additionalTitleBar += ' - Add New Doctor';
  } else if (pathName.includes('/doctor/edit/')) {
    additionalTitleBar += ' - Edit Doctor Detail';
  } else if (pathName.includes('/doctor/')) {
    additionalTitleBar += ' - Doctor Detail';
  } else if (pathName.includes('/nurses')) {
    additionalTitleBar += ' - Nurses';
  } else if (pathName.includes('/nurse/add-nurse')) {
    additionalTitleBar += ' - Add New Nurse';
  } else if (pathName.includes('/nurse/edit/')) {
    additionalTitleBar += ' - Edit Nurse Detail';
  } else if (pathName.includes('/nurse/')) {
    additionalTitleBar += ' - Nurse Detail';
  } else if (pathName.includes('/e-learning')) {
    additionalTitleBar += ' - e-Leaning';
  } else {
    additionalTitleBar = '';
  }
  return additionalTitleBar;
};
