/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import { AttachmentResponse } from '../../connecting-api/attachment/api-action';
import useDeleteAttachmentByID from '../../connecting-api/attachment/hooks/use-delete-attachment-by-id';
import useFetchAttachmentByPatientID from '../../connecting-api/attachment/hooks/use-fetch-attachment-by-patient-id';
import useSaveFile from '../../connecting-api/attachment/hooks/use-save-file';
import useUploadPdfFile from '../../connecting-api/attachment/hooks/use-upload-file';
import { TAttachment } from '../../form-schema/attachments/AttachmentSchema';
import AddAttachFile from './attach-file/add-attach';
import PdfList from './list/ListPdf';

type Props = {
  patientID: string;
  titleDisabled?: boolean;
  btnDeleteDisabled?: boolean;
  attachFileDisabled?: boolean;
};

const AttachmentPage: React.FC<Props> = ({
  patientID,
  titleDisabled = false,
  btnDeleteDisabled = false,
  attachFileDisabled = false,
}) => {
  const { mutate: uploadPdfFile } = useUploadPdfFile();
  const { mutate: saveFile } = useSaveFile();
  const { mutate: deleteAttachmentByID } = useDeleteAttachmentByID();
  const { data, isLoading } = useFetchAttachmentByPatientID(patientID);
  const [list, setList] = useState<TAttachment[]>([]);

  const handleUploadFileSuccess = async (
    name: string,
    size: number,
    url: string,
    type: string,
  ) => {
    saveFile(
      {
        name,
        size,
        url,
        type,
        patient_id: patientID,
      },
      {
        onSuccess: (res: AttachmentResponse) => {
          if (res.success) {
            setList((prev) => [...prev, res.attachment]);
          }
        },
      },
    );
  };

  const onUploadPdfFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.item(0);
    if (!file) return;
    const formData = new FormData();
    formData.append('files', file, file.name);
    uploadPdfFile(formData, {
      onSuccess: (res) => {
        handleUploadFileSuccess(file.name, file.size, res.files[0], file.type);
        e.target.value = '';
      },
      onError: (error) => {
        e.target.value = '';
      },
    });
  };

  const onDeleteAttachment = (attachment_id: string) => {
    deleteAttachmentByID(attachment_id, {
      onSuccess: (res) => {
        if (res.success) {
          setList((prev) => prev.filter(
            (attachment: TAttachment) => attachment._id !== attachment_id,
          ));
        }
      },
    });
  };

  useEffect(() => {
    if (data && data.success) {
      setList(data.attachments);
    }
  }, [data, data?.success]);

  if (isLoading) {
    return <div>loading....</div>;
  }

  return (
    <>
      {!titleDisabled ? (
        <div className="mb-2">
          <p className="text-base font-bold">แนบไฟล์</p>
        </div>
      ) : null}
      <PdfList list={list} handleDelete={onDeleteAttachment} btnDeleteDisabled={btnDeleteDisabled} />
      {!attachFileDisabled ? (
        <AddAttachFile onUploadPdfFile={onUploadPdfFile} />
      ) : null}
    </>
  );
};

export default AttachmentPage;
