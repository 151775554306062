/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { Checkbox, Dropdown } from 'antd';
import cx from 'classnames';

import { TPersonalNoteTemplate } from '../../PersonalNoteModal';
import MultiInputForm from '../../../../form/MultiInputForm';

export type PersonalNoteDropdownWithScoreType = {
  isChecked: boolean;
  selected: TPersonalNoteTemplate | null;
  scores: number[];
  totalScore: number;
};

export const personalNoteDropdownWithScoreNewState: PersonalNoteDropdownWithScoreType = {
  isChecked: false,
  selected: null,
  scores: [],
  totalScore: 0,
};

const PersonalNoteDropdownWithScore: React.FC<{
  personalNote: TPersonalNoteTemplate;
  value: PersonalNoteDropdownWithScoreType | null;
  onHandleChange: (newState: PersonalNoteDropdownWithScoreType) => void;
}> = ({ personalNote, value, onHandleChange }) => {
  const { name, options } = personalNote;

  const [state, setState] = useState<PersonalNoteDropdownWithScoreType>(
    value ?? personalNoteDropdownWithScoreNewState,
  );

  useEffect(() => {
    onHandleChange(state);
  }, [state, setState]);

  useEffect(() => {
    setState(value ?? personalNoteDropdownWithScoreNewState);
  }, [value]);

  const onChangeCheckbox = (checked: boolean) => {
    setState({
      ...state,
      isChecked: checked,
      selected: checked ? state.selected : null,
      scores: checked ? state.scores : [],
      totalScore: checked ? state.totalScore : 0,
    });
  };

  const onChangeSelected = (option: TPersonalNoteTemplate) => {
    setState({
      ...state,
      selected: option,
      isChecked: true,
      scores: [],
      totalScore: 0,
    });
  };

  const onSetScore = (scores: number[]) => {
    setState({
      ...state,
      scores,
      totalScore: scores.reduce((prev, result) => result + prev, 0),
    });
  };

  return (
    <div className="p-4 border-b" style={{ background: '#F8F8F8' }}>
      <div className="flex">
        <div className="flex flex-grow">
          <Checkbox
            name={name}
            className="pr-4"
            checked={state.isChecked}
            onChange={(e) => onChangeCheckbox(e.target.checked)}
          />
          <p>{name}</p>
        </div>
      </div>
      <div className="flex items-end">
        <Dropdown
          className="border rounded-full py-2 px-4 mt-4 w-80 h-10 bg-white"
          trigger={['click']}
          menu={{
            className: 'rounded-xl',
            items:
              options?.map((option) => {
                return {
                  key: option.key,
                  label: option.name,
                  onClick: () => {
                    onChangeSelected(option);
                  },
                };
              }) ?? [],
          }}
        >
          <div className="flex">
            <div className="flex flex-grow">{state.selected?.name}</div>
            <div className="flex flex-grow-0 w-4">
              <img src="/icons/arrow_down.svg" alt="arrow down" />
            </div>
          </div>
        </Dropdown>
        {state.selected && (
          <div className="flex">
            <div className="px-4">
              <MultiInputForm
                // key="number-of-inputs"
                defaultValues={state.scores}
                numberOfInput={state.selected.limit ?? 0}
                onChange={onSetScore}
              />
            </div>
            <div
              className={cx(
                'w-24 rounded-full bg-gray-300 flex justify-center items-center',
                {
                  'text-red-600':
                    (state.selected?.condition_value ?? 0) <= state.totalScore,
                },
              )}
            >
              {state.totalScore}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PersonalNoteDropdownWithScore;
