/* eslint-disable @typescript-eslint/no-explicit-any */
export enum GenderLabel {
  // male = 'ชาย',
  // female = 'หญิง',
  male = 'Male',
  female = 'Female',
}

export enum UserType {
  Nurse = 'Nurse',
  Doctor = 'Doctor',
  Patient = 'Patient',
  Bot = 'Bot',
}

export enum HCWRole {
  Nurse = 'Nurse',
  Doctor = 'Doctor',
}

export enum AppointmentType {
  online = 'online',
  onsite = 'onsite',
}

export enum NameTitle {
  mr = 'นาย',
  mrs = 'นาง',
  ms = 'นางสาว',
  other = 'อื่นๆ',
}

export enum MedicalBenefitScheme {
  cash = 'เงินสด',
  goverment_officer = 'สิทธิข้าราชการ',
  sso = 'สิทธิประกันสังคม',
  gold_card = 'สิทธิบัตรทอง',
  monk = 'พระสงฆ์',
  other = 'other',
}

export enum CurrentShowUser {
  card = 'card',
  list = 'list',
}

export enum SortType {
  asc = 'asc',
  desc = 'desc',
}

export enum UserSortType {
  name_th_asc = 'name-th-asc',
  name_th_desc = 'name-th-desc',
  created_at_asc = 'created-at-asc',
  created_at_desc = 'created-at-desc',
}

export enum GenderValue {
  male = 'male',
  female = 'female',
}

export enum CanceledAppointmentDescription {
  doctor_in_meeting = 'แพทย์ติดประชุมด่วน',
  doctor_outside_hospital = 'แพทย์ติดอบรมนอกโรงพยาบาล',
  doctor_another_service = 'แพทย์เข้าประจำหอผู้รับบริการ',
}

export type APIResponse<Custom = { [key: string]: any }> = ({
  success: true;
} & Custom) | {
  success: false;
  error: string;
  error_code: number;
};
