/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/prefer-default-export */
import { APIResponse } from '../../constant-enum-type';
import { TAttachment } from '../../form-schema/attachments/AttachmentSchema';
import api from '../../utils/api';

export type AttachmentResponse = APIResponse<{ success: boolean; attachment: TAttachment }>;
export type AttachmentsResponse = APIResponse<{ success: boolean; attachments: TAttachment[] }>;
export type DeleteAttachmentResponse = APIResponse<{ success: boolean }>;

export const uploadPdfFile = async (body: FormData) => {
  return api
    .uploadImage('/cos/attachment/upload', body)
    .then((res) => res.data);
};

export const saveFile = (body: any): Promise<AttachmentResponse> => {
  return api
    .post('/attachments', body)
    .then((res) => res.data);
};

export const fetchAttachmentByPatientID = (patientId: string): Promise<AttachmentsResponse> => {
  return api
    .get(`/attachments/${patientId}`)
    .then((res) => res.data);
};

export const deleteAttachmentByID = (attchment_id: string): Promise<DeleteAttachmentResponse> => {
  return api
    .delete(`/attachments/${attchment_id}`)
    .then((res) => res.data);
};
