/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { Checkbox } from 'antd';
import {
  EPersonalNoteType,
  TPersonalNoteTemplate,
} from '../../PersonalNoteModal';
import PersonalNoteDropdown, {
  PersonalNoteDropdownType,
  personalNoteDropdownNewState,
} from '../common/PersonalNoteDropdown';
import PersonalNoteDropdownWithScore, {
  PersonalNoteDropdownWithScoreType,
  personalNoteDropdownWithScoreNewState,
} from '../common/PersonalNoteDropdownWithScore';

export type PersonalNoteChronicHBVType = {
  isChecked: boolean;
  isExpand: boolean;
  hbsAg: PersonalNoteDropdownType;
  hbeAg: PersonalNoteDropdownType;
  onMedication: PersonalNoteDropdownType;
  hccPredictation: PersonalNoteDropdownWithScoreType;
};

export const personalNoteChronicHBVNewState = {
  isChecked: false,
  isExpand: false,
  hbsAg: personalNoteDropdownNewState,
  hbeAg: personalNoteDropdownNewState,
  onMedication: personalNoteDropdownNewState,
  hccPredictation: personalNoteDropdownWithScoreNewState,
};

const PersonalNoteChronicHBV: React.FC<{
  personalNote: TPersonalNoteTemplate;
  value: PersonalNoteChronicHBVType | null;
  onHandleChange: (newState: PersonalNoteChronicHBVType) => void;
}> = ({ personalNote, value, onHandleChange }) => {
  const { name, items } = personalNote;

  const [state, setState] = useState<PersonalNoteChronicHBVType>(
    value ?? personalNoteChronicHBVNewState,
  );

  useEffect(() => {
    onHandleChange(state);
  }, [state, setState]);

  const onChangeCheckbox = (checked: boolean) => {
    setState({
      ...state,
      isChecked: checked,
      isExpand: checked || state.isExpand,
      hbeAg: {
        ...state.hbeAg,
        isChecked: checked ? state.hbeAg.isChecked : checked,
        selected: checked ? state.hbeAg.selected : null,
      },
      hbsAg: {
        ...state.hbsAg,
        isChecked: checked ? state.hbsAg.isChecked : checked,
        selected: checked ? state.hbsAg.selected : null,
      },
      onMedication: {
        ...state.onMedication,
        isChecked: checked ? state.onMedication.isChecked : checked,
        selected: checked ? state.onMedication.selected : null,
      },
      hccPredictation: {
        ...state.hccPredictation,
        isChecked: checked ? state.hccPredictation.isChecked : checked,
        selected: checked ? state.hccPredictation.selected : null,
      },
    });
  };

  const toggleExpand = () => {
    setState({
      ...state,
      isExpand: !state.isExpand,
    });
  };

  const onMapData = (keyName: string) => {
    if (keyName === 'hbeag') {
      return state.hbeAg;
    }
    if (keyName === 'hbsag') {
      return state.hbsAg;
    }
    if (keyName === 'on_medication') {
      return state.onMedication;
    }
    return personalNoteDropdownNewState;
  };

  const onSetMapData = (
    keyName: string,
    _value: PersonalNoteDropdownType,
  ) => {
    setState({
      ...state,
      hbeAg: keyName === 'hbeag' ? _value : state.hbeAg,
      hbsAg: keyName === 'hbsag' ? _value : state.hbsAg,
      onMedication: keyName === 'on_medication' ? _value : state.onMedication,
      isChecked: _value.isChecked ? _value.isChecked : state.isChecked,
    });
  };

  return (
    <div className="p-4 border">
      <div className="flex">
        <div className="flex flex-grow">
          <Checkbox
            name={name}
            className="pr-4"
            checked={state.isChecked}
            onChange={(e) => onChangeCheckbox(e.target.checked)}
          />
          <p className="pb-2">{name}</p>
        </div>
        <div className="flex flex-grow-0 items-center">
          <div onClick={toggleExpand} className=" cursor-pointer">
            <img
              className={`transform transition-transform ${
                state.isExpand ? 'rotate-0' : 'rotate-180'
              }`}
              src="/icons/arrow_down.svg"
              alt="arrow down"
            />
          </div>
        </div>
      </div>
      {state.isExpand && (
        <div className="">
          {items?.map((item) => {
            if (item.type === EPersonalNoteType.DROPDOWN) {
              return (
                <PersonalNoteDropdown
                  key={item.key}
                  personalNote={item}
                  value={onMapData(item.key)}
                  onHandleChange={(_value) => {
                    onSetMapData(item.key, _value);
                  }}
                />
              );
            }
            if (item.type === EPersonalNoteType.DROPDOWN_WITH_SCORE) {
              return (
                <PersonalNoteDropdownWithScore
                  key={item.key}
                  personalNote={item}
                  value={state.hccPredictation}
                  onHandleChange={(_value) => {
                    setState({
                      ...state,
                      hccPredictation: _value,
                      isChecked: _value.isChecked ? _value.isChecked : state.isChecked,
                    });
                  }}
                />
              );
            }
            return <div>{item.name}</div>;
          })}
        </div>
      )}
    </div>
  );
};

export default PersonalNoteChronicHBV;
