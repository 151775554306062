import moment from 'moment-timezone';
import { TDoctor } from './form-schema/doctors/DoctorSchema';
import { TNurse } from './form-schema/nurses/NurseSchema';
import { TPatient } from './form-schema/patients/PatientSchema';

export interface DateInterface {
  date: string | undefined;
  month: string | undefined;
  year: string | undefined;
}

const HandleInitDateOfBirth = (personData: TPatient | TNurse | TDoctor | undefined, date?: Date): DateInterface => {
  if (personData === undefined) {
    if (date) {
      const momentDate = moment(date);
      return {
        date: momentDate.date().toString(),
        month: (momentDate.month() + 1).toString(),
        year: (momentDate.year() + 543).toString(),
      };
    }
    return { date: undefined, month: undefined, year: undefined };
  }
  const dateData = date || personData.date_of_birth;
  const momentDate = moment(dateData);
  return {
    date: momentDate.date().toString(),
    month: (momentDate.month() + 1).toString(),
    year: (momentDate.year() + 543).toString(),
  };
};

export default HandleInitDateOfBirth;
