/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useQuery } from 'react-query';
import { fetchOldAppointmentsByUserID } from '../api-action';

const useFetchOldAppointmentsByUserID = (id: string, userType: string) => {
  return useQuery(
    ['old_appointments', id],
    () => fetchOldAppointmentsByUserID(id, userType),
    { refetchOnWindowFocus: false },
  );
};

export default useFetchOldAppointmentsByUserID;
