import React from 'react';
import { Radio } from 'antd';
import moment from 'moment';

import { PersonalNoteChronicHCVType } from '../../../common/components/modals/PersonalNoteModal/type/PersonalNoteUD/PersonalNoteChronicHCV';

const PersonalNoteChronicHCVResult: React.FC<{
  value: PersonalNoteChronicHCVType;
}> = ({ value }) => {
  if (!value.isChecked) {
    return <div />;
  }

  return (
    <div className="pb-4">
      <p className="text-base font-bold">Chronic HCV</p>
      <div className=" py-2">
        <Radio.Group value={value.radioSelected}>
          <Radio key="treatment" value="treated">
            Treated
          </Radio>
          <Radio key="none_treat" value="none_treat">
            None Treat
          </Radio>
        </Radio.Group>
      </div>
      {value.radioSelected === 'treated' && (
        <div className="px-4 py-2 bg-gray-100 rounded-lg flex flex-col">
          <div className="pb-2">
            <p className="text-base font-bold">Treatment</p>
            <p className="text-base text-gray-500">
              {value.dropdownSelected !== '' ? value.dropdownSelected : '-'}
            </p>
          </div>
          <div className="pb-2">
            <p className="text-base font-bold">Date</p>
            <p className="text-base text-gray-500">
              {value.dateSelected
                ? moment(value.dateSelected).format('DD/MM/yyyy')
                : '-'}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default PersonalNoteChronicHCVResult;
