/* eslint-disable import/prefer-default-export */
import moment from 'moment-timezone';
import * as Yup from 'yup';
import { UserType } from '../../constant-enum-type';
import { PersonalNote } from '../../components/modals/PersonalNoteModal/PersonalNoteModal';

export enum XrayType {
  MRI = 'MRI',
  CT_SCAN = 'CT Scan',
  ULTRASOUND = 'Ultrasound',
}

export interface TPatient {
  _id: string;
  tags: string[];
  user_type: UserType;
  code_id: string;
  medical_benefit_scheme: string;
  hn: string;
  diagnosis_code: string;
  hospital_id: string;
  name_title: string;
  first_name: string;
  last_name: string;
  citizen_id: string;
  date_of_birth: string;
  gender: string;
  raw_address: string;
  telephone: string;
  mobile_number: string;
  backup_mobile_number: string;
  weight: number;
  height: number;
  sbp: number;
  dbp: number;
  avatar_img_url: string;
  personal_note: string;
  personal_note_template: PersonalNote | null;
  last_xray_date: Date | null;
  xray_type: XrayType | null;
  is_egd_checked: boolean;
  last_egd_date: Date | null;
  medication_note: string;
  created_at: string;
  updated_at: string;
}

export interface TShortAppointmentInfo {
  // patient_note: string [] | null;
  // clinical_note: string [] | null;
  _id: string;
  is_ultrasound: boolean;
  nurse_note: string;
  appointment_date: string;
}

export interface TProgressNote {
  _id: string;
  is_active: boolean;
  marked_note_as_important: boolean;
  extra_visit: boolean;
  note: string;
  patient_id: string;
  created_by: string;
  created_at: string;
  updated_at: string;
}

export interface TNurseNote {
  // patient_note: string[] | null;
  // clinical_note: string[] | null;
  _id: string;
  e_learning: string[];
  patient_id: TPatient;
  appointment_type: 'online' | 'onsite';
  come_by_schedule?: boolean | undefined;
  created_by: string;
  is_ultrasound: boolean;
  nurse_note: string;
  appointment_date: string;
  blood_test: string;
  avoid_food: boolean;
  mark_doctor_note_as_important?: boolean;
  is_notified: boolean;
  status: boolean;
}

export interface TPatientSummary {
  patient: TPatient;
  next_appointment: TShortAppointmentInfo | null;
  latest_appointment: TShortAppointmentInfo | null;
  progress_note: TProgressNote[] | null;
  nurse_note: TNurseNote[] | null;
  created_at: string;
}

export interface TPatientEdit {
  avatar_img_url?: string;
  backup_mobile_number?: string;
  date_of_birth?: string;
  diagnosis_code?: string;
  first_name?: string;
  gender?: string;
  height?: number;
  hn?: string;
  hospital_id?: string;
  last_name?: string;
  medical_benefit_scheme?: string;
  mobile_number?: string;
  name_title?: string;
  raw_address?: string;
  tags?: string[];
  telephone?: string;
  user_type?: string;
  username?: string;
  weight?: number;
  _id?: string;
  citizen_id?: string;
  personal_note?: string;
  medication_note?: string;
  xray_type?: XrayType | null;
  last_xray_date?: Date | null;
  is_egd_checked?: boolean;
  last_egd_date?: Date | null;
  code_id?: string;
}

export interface TPatientTags {
  patient_tags: [];
  _id: string;
  key: string;
  value: string[];
  hospital_id: string;
}

export type TPatientInitialValue = Omit<TPatient, '_id' | 'created_at' | 'updated_at' | 'user_type' | 'tags'>;

const initialValues: TPatientInitialValue = {
  avatar_img_url: '',
  code_id: '',
  diagnosis_code: '',
  hn: '',
  first_name: '',
  last_name: '',
  height: 0,
  weight: 0,
  sbp: 0,
  dbp: 0,
  citizen_id: '',
  date_of_birth: moment(new Date()).toISOString(),
  gender: '',
  medical_benefit_scheme: '',
  mobile_number: '',
  backup_mobile_number: '',
  telephone: '',
  raw_address: '',
  name_title: '',
  hospital_id: '',
  personal_note: '',
  medication_note: '',
  personal_note_template: null,
  xray_type: null,
  last_xray_date: null,
  is_egd_checked: false,
  last_egd_date: null,
};

export const validationSchema = Yup.object().shape({
  code_id: Yup.string()
    .min(5, 'Code ID Patient ใช้ 5 ตัวอักษร')
    .max(5, 'Code ID Patient เกิน 5 ตัวอักษร')
    .required('กรุณากรอก Code ID Patient'),
  diagnosis_code: Yup.string()
    .min(5, 'Code Diagnosis ใช้ 5 ตัวอักษร')
    .max(5, 'Code Diagnosis เกิน 5 ตัวอักษร')
    .required('กรุณากรอก Code Diagnosis'),
  hn: Yup.string()
    .required('กรุณากรอกเลข HN'),
  first_name: Yup.string()
    .required('กรุณากรอกชื่อผู้รับบริการ'),
  last_name: Yup.string()
    .required('กรุณากรอกนามสกุลผู้รับบริการ'),
  citizen_id: Yup.string()
    .min(13, 'เลขบัตรประชาชนยังไม่ครบ 13 ตัวอักษร')
    .max(13, 'เลขบัตรประชาชนเกิน 13 ตัวอักษร')
    .required('กรุณากรอกเลขบัตรประชาชนประชาชน'),
  date_of_birth: Yup.date()
    .required('กรุณาเลือกวันเดือนปีเกิด'),
  gender: Yup.string()
    .required('กรุณาเลือกเพศ'),
  medical_benefit_scheme: Yup.string()
    .required('กรุณากรอกสิทธิการรักษา'),
  mobile_number: Yup.string()
    .min(10, 'เบอร์โทรศัพท์ยังไม่ครบ 10 ตัวอักษร')
    .max(10, 'เบอร์โทรศัพท์เกิน 10 ตัวอักษร')
    .required('กรุณากรอกเบอร์โทรศัพท์'),
  backup_mobile_number: Yup.string()
    .min(10, 'เบอร์โทรศัพท์ยังไม่ครบ 10 ตัวอักษร')
    .max(10, 'เบอร์โทรศัพท์เกิน 10 ตัวอักษร'),
  telephone: Yup.string()
    .min(9, 'เบอร์โทรศัพท์บ้านใช้ 9-10 ตัวอักษร')
    .max(10, 'เบอร์โทรศัพท์บ้านใช้ 9-10 ตัวอักษร'),
  height: Yup.number()
    .min(1, 'กรุณาส่วนสูง (ซม.)')
    .max(999, 'กรุณาส่วนสูง (ซม.)')
    .required('กรุณาส่วนสูง (ซม.)')
    .typeError('กรุณากรอกเฉพาะตัวเลขเท่านั้น'),
  weight: Yup.number()
    .min(1, 'กรุณาน้ำหนัก (กก.)')
    .max(999, 'กรุณาน้ำหนัก (กก.)')
    .required('กรุณาน้ำหนัก (กก.)')
    .typeError('กรุณากรอกเฉพาะตัวเลขเท่านั้น'),
  xray_type: Yup.mixed().oneOf([...Object.values(XrayType), null]).nullable(),
  last_xray_date: Yup.date().when('xray_type', {
    is: (value: XrayType | null) => value !== null,
    then: Yup.date().typeError('กรุณาเลือกวันที่ได้รับการตรวจครั้งล่าสุด').required('กรุณาเลือกวันที่ได้รับการตรวจครั้งล่าสุด'),
    otherwise: Yup.date().nullable(),
  }),
  is_egd_checked: Yup.bool().required('กรุณราระบุว่าคนไข้เคยได้รับ EGD หรือไม่'),
  last_egd_date: Yup.date().when('is_egd_checked', {
    is: true,
    then: Yup.date().typeError('กรุณาเลือกวันที่ได้รับการตรวจ EGD ครั้งล่าสุด').required('กรุณาเลือกวันที่ได้รับการตรวจ EGD ครั้งล่าสุด'),
    otherwise: Yup.date().nullable(),
  }),
});

export default initialValues;
