/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { AppointmentType } from '../../common/constant-enum-type';

/* eslint-disable import/prefer-default-export */
interface Props {
  appointmentType: string;
  isMedicalNote?: boolean;
}

interface MedicalNoteEditButtonProps {
  handleEdit: () => void;
}

interface MedicalNoteDeleteButtonProps {
  handleDelete: () => void;
}

const appointmentOnSiteStyleBadge = {
  color: '#1E73BE',
  backgroundColor: '#E6F7FF',
  borderColor: '#1E73BE',
  borderWidth: 1,
};

const appointmentOnlineStyleBadge = {
  color: '#FA541C',
  backgroundColor: '#FFF2E8',
  borderColor: '#FA541C',
  borderWidth: 1,
};

const appointmentOnsiteStyleLabel = {
  color: '#1E73BE',
};

const appointmentOnlineStyleLabel = {
  color: '#ED6024',
};

const redButtonStyle = {
  color: '#CF1353',
  backgroundColor: '#FFe6E6',
  borderColor: '#CF1353',
  borderWidth: 1,
};

export const AppointmentTypeLabel: React.FC<Props> = (props) => {
  if (props.isMedicalNote) {
    const appointmentTypeWord = props.appointmentType === AppointmentType.onsite ? '(On-site)' : '(Online)';
    return (
      <span className="text-lg font-medium" style={appointmentOnsiteStyleLabel}>:&nbsp;&nbsp;{appointmentTypeWord}</span>
    );
  }

  if (props.appointmentType === AppointmentType.onsite) {
    return (
      <p className="text-lg font-medium" style={appointmentOnsiteStyleLabel}>:&nbsp;&nbsp;On-site</p>
    );
  }

  return (
    <p className="text-lg font-medium" style={appointmentOnlineStyleLabel}>:&nbsp;&nbsp;Online</p>
  );
};

export const MedicalNoteEditButton: React.FC<MedicalNoteEditButtonProps> = (props) => {
  return (
    <div
      className="px-2 text-base font-medium text-center cursor-pointer rounded-3xl"
      style={appointmentOnSiteStyleBadge}
      onClick={props.handleEdit}
    >
      Edit
    </div>
  );
};

export const MedicalNoteDeleteButton: React.FC<MedicalNoteDeleteButtonProps> = (props) => {
  return (
    <div
      className="px-2 text-base font-medium text-center cursor-pointer rounded-3xl"
      style={redButtonStyle}
      onClick={props.handleDelete}
    >
      Delete
    </div>
  );
};

export const AppointmentTypeBadge: React.FC<Props> = (props) => {
  if (props.appointmentType === AppointmentType.onsite) {
    return <div className="px-2 text-base font-medium text-center cursor-pointer rounded-3xl" style={appointmentOnSiteStyleBadge}>On-site</div>;
  }
  return <div className="px-2 text-base font-medium text-center cursor-pointer rounded-3xl" style={appointmentOnlineStyleBadge}>Online</div>;
};
