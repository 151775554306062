/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */

import { APIResponse } from '../../constant-enum-type';
import api from '../../utils/api';
import { TDoctor, TDoctorEdit, TDoctorInitialValue } from '../../form-schema/doctors/DoctorSchema';
import { DoctorResponses } from '../../components/form/common/responses';

export type FetchDoctorsResponse = APIResponse<{ success: boolean; doctor: TDoctor[] }>;
export type FetchDoctorByIDResponse = APIResponse<{ success: boolean; doctor: TDoctor }>;
export type EditDoctorByIDResponse = APIResponse<{ success: boolean; doctor: TDoctorEdit }>;

export const addDoctor = (body: TDoctorInitialValue): Promise<DoctorResponses> => {
  return api
    .post('/doctors', body)
    .then((res) => res.data);
};

export const fetchDoctors = (): Promise<FetchDoctorsResponse> => {
  return api
    .get('/doctors')
    .then((res) => res.data);
};

export const fetchDoctorByID = (id: string): Promise<FetchDoctorByIDResponse> => {
  return api
    .get(`/doctors/${id}`)
    .then((res) => res.data);
};

export const editDoctorByID = (data: { id: string; body: TDoctorEdit }): Promise<DoctorResponses> => {
  return api
    .put(`/doctors/${data.id}`, data.body)
    .then((res) => res.data);
};
