/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useQuery } from 'react-query';
import { fetchDoctorByID } from '../api-action';

const useFetchDoctorByID = (id: string) => {
  return useQuery(
    ['doctor_by_id', id],
    () => fetchDoctorByID(id),
    { refetchOnWindowFocus: false },
  );
};

export default useFetchDoctorByID;
