import React from 'react';
import { useParams } from 'react-router';
import PageTitle from '../../features/common/components/PageTitle';
import AddNewDoctorForm from '../../features/common/components/form/AddNewDoctorForm';

const AddNewDoctor: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  return (
    <div>
      <PageTitle title={id ? 'Edit Physicians Detail' : 'Add New Physicians'} backTo={id ? `/doctor/${id}` : '/doctors'} />
      <div className="w-full p-6 mt-6 bg-white rounded-3xl">
        <AddNewDoctorForm />
      </div>
    </div>
  );
};

export default AddNewDoctor;
