/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { EditorState } from 'draft-js';

import Editor, { decorator, getHTML } from '../common/components/Editor';
import { SendNotificationButton as Button } from '../common/components/Button';
import LinkUtils from '../common/components/Editor/LinkUtils';
import useSendNotification from '../common/connecting-api/notification/hooks/use-send-notification';
import { NotificationType } from '../common/connecting-api/notification/api-action';
import handleSendNotificaitonSuccess from '../common/components/modals/SuccessSendNotificationModal';

const getEmptyState = (): EditorState => EditorState.createEmpty(decorator);

interface SendNotificationProp {
  onChange?: (val: string[], plainText: string) => void;
}

const SendNotification: React.FC<SendNotificationProp> = ({ onChange }) => {
  const { id } = useParams<{ id: string }>();
  const [editorState, setEditorState] = useState(
    getEmptyState(),
  );

  const { mutateAsync: sendNotifcation, isLoading } = useSendNotification();

  const handleSendNotification = async () => {
    const htmlData = getHTML(editorState.getCurrentContent());
    if (id) {
      const data = {
        id,
        type: NotificationType.MESSAGE,
        content: [htmlData],
      };
      await sendNotifcation(data, { onSuccess: handleSendNotificaitonSuccess });
      setEditorState(getEmptyState());
    }
  };

  const handleChange = (updatedState: EditorState): void => {
    setEditorState(updatedState);
    if (onChange) {
      const htmlData = getHTML(updatedState.getCurrentContent());
      onChange([htmlData], updatedState.getCurrentContent().getPlainText());
    }
  };

  return (
    <div className="mb-5">
      <p className="mb-2 text-base font-semibold">e-Learning</p>
      <LinkUtils
        editorStates={[editorState]}
        setEditorStates={(newStates) => setEditorState(newStates[0])}
      />
      <Editor editorStateProp={editorState} onChangeProp={handleChange} />
      {!onChange && (
        <div className="flex justify-end">
          <Button disabled={isLoading} className="mt-2 text-white" onClick={handleSendNotification}>ส่งการแจ้งเตือน</Button>
        </div>
      )}
    </div>
  );
};

export default SendNotification;
