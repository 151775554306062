/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import useFetchIncomingAppointmentsByUserID from '../common/connecting-api/appointment/hooks/use-fetch-incoming-appointments-by-user-id';
import AppointmentInfoCard from '../appointment/components/AppointmentInfoCard';
import { TAppointment } from '../common/form-schema/appointments/AppointmentSchema';
import Loading from '../common/components/Loading';
import { UserSchema } from '../common/form-schema/user/UserSchema';

interface IAppointmentProps {
  userType: string;
}
const Appointments: React.FC<IAppointmentProps> = (props) => {
  const { id } = useParams<{ id: string }>();
  const { userType } = props;
  const { data, isLoading } = useFetchIncomingAppointmentsByUserID(id, userType);

  const queryClient = useQueryClient();
  const userQuery = queryClient.getQueryData<{ success: boolean; user_info: UserSchema }>(['user']);

  if (isLoading) return <div><Loading /></div>;

  if (data?.success && data.appointments.length === 0) {
    return (
      <div className="p-4 text-center"><p className="text-base font-normal">ยังไม่มีข้อมูลการนัดหมายของผู้รับบริการ</p></div>
    );
  }

  if (!data || !data.success) {
    return (
      <div className="p-4 text-center">
        <p className="text-base font-normal">
          เกิดข้อผิดพลาด กรุณาลองอีกครั้งในภายหลัง
        </p>
      </div>
    );
  }

  return (
    <div>
      {data?.success && data.appointments.map((appointment: TAppointment, index: number) => {
        const appointmentLength = data.appointments.length;
        return (
          <AppointmentInfoCard
            key={appointment._id}
            userType={userType}
            appointment={appointment}
            isLastElement={appointmentLength === index + 1}
          />
        );
      })}
    </div>
  );
};

export default Appointments;
