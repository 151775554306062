/* eslint-disable import/prefer-default-export */
import { atom } from 'recoil';

export const DoctorID = atom({
  key: 'doctorID',
  default: '',
});

export const DoctorName = atom({
  key: 'doctorName',
  default: '',
});
