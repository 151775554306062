/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useQuery } from 'react-query';
import { fetchNotificationCount } from '../api-action';

const useFetchXrayNotificationCount = () => {
  return useQuery(
    'xray-notification-count',
    () => fetchNotificationCount(),
    { refetchOnWindowFocus: false, cacheTime: 0 },
  );
};

export default useFetchXrayNotificationCount;
