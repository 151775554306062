/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { FormikProps } from 'formik';
import {
  Checkbox,
  DatePicker,
  Radio,
} from 'antd';
import Select from 'antd/es/select';
import locale from 'antd/es/date-picker/locale/th_TH';
import TextArea from 'antd/lib/input/TextArea';

import SelectPatientsComponent from '../../common/components/persons/SelectPatientsComponent';
import SelectDoctorsComponent from '../../common/components/persons/SelectDoctorsComponent';
import { ErrorMsg, WarningMsg } from '../../common/components/Lable';

import { TModifiedAppointment } from '../../common/form-schema/appointments/AppointmentSchema';
import { TDoctor } from '../../common/form-schema/doctors/DoctorSchema';
import ELearningNotification from '../../patient-appointments/ELearningNotification';
import { AppointmentType } from '../../common/constant-enum-type';
import { future100Years } from '../../common/birth-date-data';
import useWindowDimensions from '../../common/use-window-dimensions';
import TinyMECInput from '../../common/components/TinyMECInput';

const { Option } = Select;

export const checkUnavailabilityDay = (day: moment.Moment | string, unavailableDaysOfWeek: string[]) => {
  if (moment(day).startOf('day').isBefore(moment().startOf('day'))) return true;
  return unavailableDaysOfWeek.some((d) => {
    return moment(day).locale('en').format('dddd') === d;
  });
};

type Props = {
  isAppointmentDateFieldShownFirst?: boolean;
  disabledFields?: { [key in keyof TModifiedAppointment]: boolean };
  formik: FormikProps<TModifiedAppointment>;
  patientPhoneNumber?: string;
  doctorUnavailableDays?: string[];
  oldAppointmentDate?: string;
};

const AppointmentInputs: React.FC<Props> = (props) => {
  const {
    errors,
    setFieldValue,
    values,
    touched,
  } = props.formik;

  const { height } = useWindowDimensions();

  const [focusAppointmentDate, setFocusAppointment] = useState(true);
  const [isAppointmentTypeChanged, setIsAppointmentTypeChanged] = useState(false);
  const [year, setYear] = useState(values.appointment_date === undefined ? (moment().year() + 543).toString() : moment(values.appointment_date).year() + 543);
  const [doctorUnavailableDaysOfWeek, setDoctorUnavailableDaysOfWeek] = useState<string[]>(props.doctorUnavailableDays && props.doctorUnavailableDays.length !== 0 && props.doctorUnavailableDays[0] !== 'Free' ? props.doctorUnavailableDays : []);
  const onDoctorIsChanged = (doctor?: TDoctor) => {
    setDoctorUnavailableDaysOfWeek(doctor?.unavailable_day ?? []);
    setFieldValue('appointment_date', undefined);
  };

  const disabledFields: Props['disabledFields'] = {
    ...props.disabledFields,
    appointment_date: !values.doctor_id || !values.appointment_type || props.disabledFields?.appointment_date,
    mark_doctor_note_as_important: !values.doctor_note || props.disabledFields?.mark_doctor_note_as_important,
  };

  const isDoctorNoteEmpty = !values.doctor_note || values.doctor_note === '';

  const handleSetNewAppointmentDate = (value: string) => {
    // const oldAppointmentDate = values.appointment_date;
    if (value === AppointmentType.online) {
      const nowTime = moment(values.appointment_date).hour(new Date().getHours()).minute(new Date().getMinutes());
      setFieldValue('appointment_date', nowTime.toISOString());
    }
  };

  useEffect(() => {
    if (isDoctorNoteEmpty && values.mark_doctor_note_as_important) {
      setFieldValue('mark_doctor_note_as_important', false);
    }
  }, [isDoctorNoteEmpty, setFieldValue, values.mark_doctor_note_as_important]);

  useEffect(() => {
    if (values.appointment_date) {
      const copiedDate = values.appointment_date;
      const yearMinus543 = Number(year) - 543; // convert the selected year to christian-year
      const onlyYearOfAppointmentdate = `${values.appointment_date[0]}${values.appointment_date[1]}${values.appointment_date[2]}${values.appointment_date[3]}`;
      const changedDate = copiedDate.replace(onlyYearOfAppointmentdate, yearMinus543.toString());
      setFieldValue('appointment_date', changedDate);
    }
  }, [year]);

  return (
    <div className="grid gap-3 mb-3 overflow-y-scroll" style={{ maxHeight: height - 270 }}>
      <div style={{ order: 1 + (props.isAppointmentDateFieldShownFirst ? 1 : 0) }}>
        <p className="font-medium">ผู้รับบริการ</p>
        <SelectPatientsComponent disabled={disabledFields.patient_id} formik={props.formik} />
        {props.patientPhoneNumber && <p className="ml-2 opacity-80">เบอร์โทรศัพท์: {props.patientPhoneNumber}</p>}
        {errors.patient_id && touched.patient_id && <ErrorMsg>{errors.patient_id}</ErrorMsg>}
      </div>
      <div style={{ order: 2 + (props.isAppointmentDateFieldShownFirst ? 1 : 0) }}>
        <p className="font-medium">แพทย์</p>
        <SelectDoctorsComponent disabled={disabledFields.doctor_id} formik={props.formik} onDoctorIsChanged={onDoctorIsChanged} />
        {errors.doctor_id && touched.doctor_id && <ErrorMsg>{errors.doctor_id}</ErrorMsg>}
      </div>
      <div style={{ order: 3 + (props.isAppointmentDateFieldShownFirst ? 1 : 0) }}>
        <p className="font-medium">ประเภทการนัดหมาย</p>
        <Select
          id="appointment_type"
          // disabled={disabledFields.appointment_type}
          // disabled={props.isShiftAppointment}
          value={values.appointment_type !== '' ? values.appointment_type : ''}
          onChange={(value) => {
            setFieldValue('appointment_type', value);
            handleSetNewAppointmentDate(value);
            if (value === AppointmentType.online) {
              setIsAppointmentTypeChanged(true);
            }
            if (value === AppointmentType.onsite) {
              setFieldValue('appointment_date', props.oldAppointmentDate);
            }
          }}
          bordered={false}
          className="items-center w-full text-base border-2 outline-none rounded-3xl"
          // className={`${!!disabledFields.appointment_type && 'bg-gray-100 border-transparent'} items-center w-full text-base border-2 outline-none rounded-3xl`}
          dropdownStyle={{ borderRadius: 20 }}
        >
          <Option value="onsite">On-site</Option>
          <Option value="online">Online</Option>
        </Select>
        {errors.appointment_type && touched.appointment_type && <ErrorMsg>{errors.appointment_type}</ErrorMsg>}
      </div>
      <div className="w-full gap-x-2" style={{ order: props.isAppointmentDateFieldShownFirst ? 1 : 4 }}>
        <div className="flex-col w-full">
          <p className="font-medium">วันนัดหมายใหม่</p>
          <div className="flex flex-row w-full">
            <div className="w-full mr-2">
              <DatePicker
                disabled={!values.doctor_id && !values.appointment_type}
                className="h-10 min-w-full px-3 font-normal bg-center border-2 opacity-90 rounded-3xl"
                name="appointment_date"
                showTime={values.appointment_type === AppointmentType.online && { format: 'HH:mm' }}
                format={values.appointment_type === AppointmentType.online ? 'Do MMMM เวลา HH.mm น.' : 'Do MMMM'}
                direction="rtl"
                inputReadOnly
                locale={locale}
                placeholder="เลือกวันนัดหมาย"
                disabledDate={(current) => checkUnavailabilityDay(current, doctorUnavailableDaysOfWeek)}
                popupStyle={{ borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }}
                value={values.appointment_date ? moment(values.appointment_date) : undefined}
                onChange={(date) => {
                  setFieldValue('appointment_date', date?.toISOString());
                  setIsAppointmentTypeChanged(false);
                }}
                onFocus={() => setFocusAppointment(true)}
                onBlur={() => setFocusAppointment(false)}
              />
            </div>
            <div className="w-3/12">
              <Select
                disabled={(!values.patient_id && !values.doctor_id && !values.appointment_type) || !values.appointment_date}
                className={`${(!values.patient_id && !values.doctor_id && !values.appointment_type) || !values.appointment_date ? 'bg-gray-100' : ''} items-center w-full h-10 text-base border-2 outline-none rounded-3xl`}
                dropdownStyle={{ borderRadius: 20 }}
                value={year}
                bordered={false}
                options={future100Years.map((value) => ({ label: value, value }))}
                onChange={(value) => setYear(value)}
              />
            </div>
          </div>
        </div>
        {(errors.appointment_date && touched.appointment_date)
          || (!values.appointment_date && !focusAppointmentDate)
          ? <ErrorMsg>{errors.appointment_date}</ErrorMsg>
          : null}
        {isAppointmentTypeChanged && <WarningMsg>กรุณาตรวจสอบเวลานัดหมายให้แน่ใจอีกครั้ง</WarningMsg>}
      </div>
      {values.appointment_type === AppointmentType.onsite && (
        <>
          <div className="flex flex-col text-base" style={{ order: 5 }}>
            <p className="font-medium">การตรวจเลือด</p>
            <div className="font-medium">
              <Radio.Group
                id="blood_test"
                onChange={(e) => setFieldValue('blood_test', e.target.value)}
                name="blood_test"
                value={values.blood_test !== 'not_required' ? values.blood_test : 'not_required'}
                defaultValue={values.blood_test}
                disabled={disabledFields.blood_test}
              >
                <Radio value="before_two_weeks">อย่างน้อย 2 สัปดาห์</Radio>
                <Radio value="before_one_week">อย่างน้อย 1 สัปดาห์</Radio>
                <Radio value="morning">ช่วงเช้าก่อนพบแพทย์</Radio>
                <Radio value="not_required">ไม่ต้องตรวจเลือด</Radio>
              </Radio.Group>
            </div>
            {errors.blood_test && touched.blood_test && <ErrorMsg>{errors.blood_test}</ErrorMsg>}
          </div>
          <div className="order-5 text-base" style={{ order: 6 }}>
            <p className="font-medium">การงดอาหาร</p>
            <div className="font-normal">
              <Radio.Group
                id="avoid_food"
                name="avoid_food"
                onChange={(e) => setFieldValue('avoid_food', e.target.value)}
                value={values.avoid_food !== false ? values.avoid_food : false}
                defaultValue={values.avoid_food}
              >
                <Radio value>งดอาหาร 8-10 ชม. ก่อนตรวจเลือด</Radio>
                <Radio value={false}>ไม่ต้องงดอาหาร</Radio>
              </Radio.Group>
            </div>
          </div>
          <div className="flex flex-col text-base" style={{ order: 7 }}>
            <p className="font-medium">Ultrasound</p>
            <div className="font-medium">
              <Radio.Group
                id="is_ultrasound"
                name="is_ultrasound"
                onChange={(e) => setFieldValue('is_ultrasound', e.target.value)}
                value={values.is_ultrasound !== false ? values.is_ultrasound : false}
                defaultValue={false}
                disabled={disabledFields.is_ultrasound}
              >
                <Radio value>ต้อง Ultrasound</Radio>
                <Radio value={false}>ไม่ต้อง Ultrasound</Radio>
              </Radio.Group>
            </div>
          </div>
        </>
      )}
      {/* <div style={{ order: 8 }}>
        <p className="font-medium">Doctor Note</p>
        <TextArea
          autoSize={{ minRows: 5 }}
          className="h-10 min-w-full px-3 text-base font-normal border-2 opacity-90 rounded-3xl"
          name="doctor_note"
          contentEditable
          suppressContentEditableWarning
          onChange={(e) => {
            const splitted = e.target.value.split('\n');
            for (let i = 0; i < splitted.length; i += 1) {
              if (splitted[i].includes('- ') && !/[·]+/g.test(splitted[i])) {
                const dot = ' · ';
                splitted[i] = (`${splitted[i].replace('- ', dot)}`);
              }
            }
            setFieldValue('doctor_note', splitted.join('\n'));
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && values.doctor_note?.includes('·') && !e.shiftKey) {
              const splitted = values.doctor_note.split('\n');
              if (splitted[splitted.length - 1].includes('·')) {
                const dot = ' · ';
                setFieldValue('doctor_note', `${values.doctor_note.concat(`\n${dot}`)}`);
                e.preventDefault();
              }
            }
            if (e.key === 'Enter' && values.doctor_note && values.doctor_note.length > 2 && values.doctor_note[values.doctor_note.length - 2] === '·') {
              setFieldValue('doctor_note', values.doctor_note.slice(0, values.doctor_note.length - 3));
            }
          }}
          value={values.doctor_note}
          disabled={disabledFields.doctor_note}
        />
        <TinyMECInput
          disabled={disabledFields.doctor_note}
          inputValue={values.doctor_note}
          handleChangeValue={(value) => setFieldValue('doctor_note', value)}
        />

        <Checkbox
          className="text-sm"
          name="mark_doctor_note_as_important"
          checked={!!values.mark_doctor_note_as_important}
          onChange={(e) => setFieldValue('mark_doctor_note_as_important', !!e.target.checked)}
          disabled={disabledFields.mark_doctor_note_as_important}
        >
          Medical History View
        </Checkbox>
      </div> */}
      <div style={{ order: 9 }}>
        <p className="font-medium">Nurse Note</p>
        <TinyMECInput
          disabled={disabledFields.nurse_note}
          inputValue={values.nurse_note}
          handleChangeValue={(value) => setFieldValue('nurse_note', value)}
        />
      </div>
      <div style={{ order: 10 }}>
        <ELearningNotification
          handleChange={(v) => setFieldValue('e_learning', v)}
          value={values.e_learning}
          mediumHeader
        />
      </div>
    </div>
  );
};

export default AppointmentInputs;
