/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { Modal } from 'antd';
import { TDoctor, TDoctorEdit, TDoctorInitialValue } from '../../../../form-schema/doctors/DoctorSchema';
import { TNurse, TNurseEdit, TNurseInitialValue } from '../../../../form-schema/nurses/NurseSchema';
import { TPatient, TPatientEdit, TPatientInitialValue } from '../../../../form-schema/patients/PatientSchema';
import { DoctorResponses, NurseResponses, PatientResponse } from '../responses';
import { UserType } from '../../../../constant-enum-type';

type ExsitedPersonData = TPatient | TNurse | TDoctor;
type NewCreatedEditedPersonData = TPatientEdit | TNurseEdit | TDoctorEdit | TPatientInitialValue | TNurseInitialValue | TDoctorInitialValue;

export enum ErrorType {
  BACKEND_ERROR = 'Error occured, please contact developer team',
  DUCPLICATED_CITIZEN_ID = 'The citizen id is duplicated',
  DUCPLICATED_USERNAME = 'The username is duplicated',
}

export interface ModalData {
  text: ErrorType | string;
  personData?: NewCreatedEditedPersonData;
  exsitedPersonData?: ExsitedPersonData;
  error?: PatientResponse | NurseResponses | DoctorResponses;
  personType: UserType;
}

const DuplicatedDataContent = (contentParams:
{ oldPerson: ExsitedPersonData; newPerson: NewCreatedEditedPersonData; contentPersonType: UserType; duplicatedData?: ErrorType.DUCPLICATED_CITIZEN_ID | ErrorType.DUCPLICATED_USERNAME }) => {
  if (contentParams.contentPersonType === UserType.Patient) {
    return (
      <span className="">
        This citizen id <span className="font-semibold">{contentParams.newPerson.citizen_id}</span>&nbsp;
        is duplicated with <span className="font-semibold">{contentParams.oldPerson.citizen_id}</span>&nbsp;
        of <span className="font-semibold">{contentParams.oldPerson.name_title}{contentParams.oldPerson.first_name} {contentParams.oldPerson.last_name}</span>
      </span>
    );
  }

  if (contentParams.contentPersonType === UserType.Nurse) {
    if (contentParams.duplicatedData === ErrorType.DUCPLICATED_USERNAME) {
      return (
        <span>
          This username <span className="font-semibold">{(contentParams.newPerson as TNurseInitialValue).username}</span>&nbsp;
          is duplicated with <span className="font-semibold">{(contentParams.oldPerson as TNurse).username}</span>&nbsp;
          of <span className="font-semibold">{contentParams.oldPerson.name_title}{contentParams.oldPerson.first_name} {contentParams.oldPerson.last_name}</span>
        </span>
      );
    }
    if (contentParams.duplicatedData === ErrorType.DUCPLICATED_CITIZEN_ID) {
      return (
        <span>
          This citizen id <span className="font-semibold">{(contentParams.newPerson as TNurseInitialValue).citizen_id}</span>&nbsp;
          is duplicated with <span className="font-semibold">{(contentParams.oldPerson as TNurse).citizen_id}</span>&nbsp;
          of <span className="font-semibold">{contentParams.oldPerson.name_title}{contentParams.oldPerson.first_name} {contentParams.oldPerson.last_name}</span>
        </span>
      );
    }
    return (
      <span>Some data of this nurse is duplicated, please re-check or contact developer team</span>
    );
  }

  if (contentParams.contentPersonType === UserType.Doctor) {
    if (contentParams.duplicatedData === ErrorType.DUCPLICATED_USERNAME) {
      return (
        <span>
          This username <span className="font-semibold">{(contentParams.newPerson as TDoctorInitialValue).username}</span>&nbsp;
          is duplicated with <span className="font-semibold">{(contentParams.oldPerson as TDoctor).username}</span>&nbsp;
          of <span className="font-semibold">{contentParams.oldPerson.name_title}{contentParams.oldPerson.first_name} {contentParams.oldPerson.last_name}</span>
        </span>
      );
    }
    if (contentParams.duplicatedData === ErrorType.DUCPLICATED_CITIZEN_ID) {
      return (
        <span>
          This citizen id <span className="font-semibold">{(contentParams.newPerson as TDoctorInitialValue).citizen_id}</span>&nbsp;
          is duplicated with <span className="font-semibold">{(contentParams.oldPerson as TDoctor).citizen_id}</span>&nbsp;
          of <span className="font-semibold">{contentParams.oldPerson.name_title}{contentParams.oldPerson.first_name} {contentParams.oldPerson.last_name}</span>
        </span>
      );
    }
    return (
      <span>Some data of this physician is duplicated, please re-check or contact developer team</span>
    );
  }

  return (
    <span>Some data of this urer is duplicated, please re-check or contact developer team</span>
  );
};

export const SuccessModal = (modalParams: ModalData) => {
  return (
    Modal.success({
      centered: true,
      title: 'Success!',
      content: modalParams.text,
      okText: 'OK',
    })
  );
};

export const FailedModal = (errorType: ErrorType, modalParams: ModalData) => {
  if ((errorType === ErrorType.DUCPLICATED_CITIZEN_ID || errorType === ErrorType.DUCPLICATED_USERNAME)
     && modalParams.exsitedPersonData && modalParams.personData) {
    return (
      Modal.error({
        centered: true,
        title: 'Data Error!',
        content: DuplicatedDataContent({
          oldPerson: modalParams.exsitedPersonData,
          newPerson: modalParams.personData,
          contentPersonType: modalParams.personType,
          duplicatedData: errorType,
        }),
        okText: 'OK',
      })
    );
  }

  return (
    Modal.error({
      centered: true,
      title: 'Something went wrong!',
      content: <p>{modalParams.text}</p>,
      okText: 'OK',
    })
  );
};
