/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import {
  useHistory,
  NavLink,
} from 'react-router-dom';
import styled from 'styled-components';
import Avatar from 'antd/lib/avatar/avatar';
import {
  Row,
  Col,
  Dropdown,
  Menu,
  Typography,
  Layout,
} from 'antd';
import MenuUnfoldOutlined from '@ant-design/icons/MenuUnfoldOutlined';
import MenuFoldOutlined from '@ant-design/icons/MenuFoldOutlined';
import DownOutlined from '@ant-design/icons/DownOutlined';
import PoweroffOutlined from '@ant-design/icons/PoweroffOutlined';
import InfoCircleTwoTone from '@ant-design/icons/InfoCircleTwoTone';

import { useQueryClient } from 'react-query';
import Modal from 'antd/lib/modal/Modal';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { CancelButton, PrimaryButton } from './Button';
import useWindowDimensions from '../use-window-dimensions';
import AvatarImage from './AvatarImage';
import { UserSchema } from '../form-schema/user/UserSchema';
import { authTokenLocalStorage } from '../utils/local-storage';
import useFetchXrayNotificationCount from '../connecting-api/xray/hooks/use-fetch-notification-count';
import { UserType } from '../constant-enum-type';
import UnreadChatState from '../../../common-state/unread-chat-state';
import CalendarView from '../../../common-state/calendar-view';
import { DoctorID, DoctorName } from '../../../common-state/doctor';
import UserState from '../../../common-state/user-state';

const { Text } = Typography;
const { Header } = Layout;

interface IProps {
  collapsed: boolean;
  setCollapse: any;
  user: UserSchema;
}

const NotiDot = styled.div`
  position: absolute;
  top: 0px;
  right: -4px;
  width: 15px;
  height: 15px;
  background: #FB5E2A;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

const Navbar: React.FC<IProps> = (props) => {
  const { collapsed, setCollapse, user } = props;
  const [isShowConfirmLogout, setIsShowConfirmLogout] = useState(false);
  const history = useHistory();
  const { width, height } = useWindowDimensions();
  const { data } = useFetchXrayNotificationCount();
  const unreadChatState = useRecoilValue(UnreadChatState);
  const queryClient = useQueryClient();
  const setView = useSetRecoilState(CalendarView);
  const setDoctorID = useSetRecoilState(DoctorID);
  const setDoctorName = useSetRecoilState(DoctorName);
  const resetUser = useResetRecoilState(UserState);

  const toggle = () => {
    setCollapse((s: boolean) => !s);
  };

  const handleClickLogout = async () => {
    await queryClient.resetQueries(['user']);
    authTokenLocalStorage.remove();
    localStorage.clear();
    setView('month');
    setDoctorID('');
    setDoctorName('');
    resetUser();
    history.push('/login');
  };

  const menu = (
    <Menu className="pt-2 pb-3 -mt-3 rounded-t-sm shadow-md rounded-b-3xl">
      <Menu.Item key={0}>
        <Row onClick={() => setIsShowConfirmLogout(true)}>
          <Col key={0} className="mx-2">
            <Avatar icon={<PoweroffOutlined />} />
          </Col>
          <Col key={1} className="mx-1">
            <Text type="danger">Log out</Text>
          </Col>
        </Row>
      </Menu.Item>
    </Menu>
  );

  const renderFooter = () => {
    return (
      <div className="items-center text-right align-middle outline-none">
        <PrimaryButton key={0} onClick={() => handleClickLogout()}>Log out</PrimaryButton>
        <CancelButton key={1} onClick={() => setIsShowConfirmLogout(false)}>Back</CancelButton>
      </div>
    );
  };

  useEffect(() => {
    if (width <= 1200) {
      setCollapse(true);
    } else {
      setCollapse(false);
    }
  }, [width, height]);

  const notiCount = (data && data.success) ? data.count : 0;

  return (
    <>
      <Header className="h-20 p-0 bg-white">
        <div className="flex items-center justify-between ml-4 text-primary">
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: toggle,
          })}
          <div className="flex items-center">
            {user.user_type === UserType.Nurse && (
              <>
                <div className="relative mr-4">
                  <NavLink to="/chat">
                    <img style={{ height: '30px' }} src="/static/icon/chat.svg" alt="chats" loading="lazy" />
                    {unreadChatState > 0 && (
                    <NotiDot>
                      <p className="text-white" style={{ fontSize: '10px', marginTop: '-2px' }}>{unreadChatState > 0 && unreadChatState}</p>
                    </NotiDot>
                    )}
                  </NavLink>
                </div>
                <div className="relative mr-4">
                  <NavLink to="/xray">
                    <img style={{ height: '30px' }} src="/static/icon/noti.svg" alt="notification" loading="lazy" />
                    {notiCount > 0 && (
                    <NotiDot>
                      <p className="text-white" style={{ fontSize: '10px', marginTop: '-2px' }}>{notiCount}</p>
                    </NotiDot>
                    )}
                  </NavLink>
                </div>
              </>
            )}
            <Dropdown overlay={menu} trigger={['click']} className="cursor-pointer">
              <Row key={0}>
                <Col key={0} className="mx-2">
                  <AvatarImage imgURL={user.avatar_img_url} />
                </Col>
                <Col key={1} className="mx-1">
                  <Text className="text-base">{user.user_type === UserType.Doctor && user.name_title}{user.first_name}</Text>
                </Col>
                <Col key={2} className="mx-1" style={{ color: 'black' }}>
                  <DownOutlined />
                </Col>
              </Row>
            </Dropdown>
          </div>
        </div>
      </Header>
      <Modal
        width={400}
        open={isShowConfirmLogout}
        centered
        title={false}
        onOk={() => handleClickLogout()}
        onCancel={() => setIsShowConfirmLogout(false)}
        footer={[
          renderFooter(),
        ]}
      >
        <div key={0} className="items-center text-lg text-center">
          <InfoCircleTwoTone twoToneColor="#f8db00" className="my-2 text-3xl" />
          <p>Are you are to log out?</p>
        </div>
      </Modal>
    </>
  );
};

export default Navbar;
