/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useRecoilState } from 'recoil';
import Tabs from 'antd/lib/tabs';
import { useQueryClient } from 'react-query';
import AppointmentHistories from '../../../patient-appointments/AppointmentHistories';
import MedicalHistories from '../../../patient-appointments/MedicalHistories';
import Notifications from '../../../patient-appointments/Notifications';
import Appointments from '../../../patient-appointments/Appointments';
import { doctorMenu, patientMenu } from '../../../../common-state/horizontal-menu';
import { UserSchema } from '../../form-schema/user/UserSchema';
import { UserType } from '../../constant-enum-type';
import CreateAppointmentInPatientPage from '../../../appointment/components/CreateAppointmentInPatientPage';
import CreateAppointmentInDoctorPage from '../../../appointment/components/CreateAppointmentInDoctorPage';
import XrayInfo from '../../../patient-appointments/XrayInfo';
import AttachmentPage from '../pdf-viewer/AttachmentPage';
import BMIHistories from '../../../patient-appointments/BMIHistories';
import PersonalNoteTemplate from '../../../patient-appointments/PersonalNoteTemplate/PersonalNoteTemplate';
import BpHistories from '../../../patient-appointments/BpHistories';

const { TabPane } = Tabs;

interface IMainInfoProps {
  userType: string;
  patientID?: string;
  role?: string;
}

const MainInfo: React.FC<IMainInfoProps> = (props) => {
  const queryClient = useQueryClient();
  const queryUserData = queryClient.getQueryData<{ success: boolean; user_info: UserSchema }>(['user']);
  const user = queryUserData?.user_info;

  const { userType, patientID, role } = props;
  const [patientCurrent, setPatientCurrentMenu] = useRecoilState(patientMenu);
  const [doctorCurrent, setDoctorCurrentMenu] = useRecoilState(doctorMenu);

  const handleClickPatientMenu = (key: string) => {
    setPatientCurrentMenu({ currentMenu: key });
  };

  const handleClickDoctorMenu = (key: string) => {
    setDoctorCurrentMenu({ currentMenu: key });
  };

  // SEE PATIENT DETAIL
  if (userType === UserType.Patient) {
    return (
      <div className="w-full bg-white rounded-3xl">
        <Tabs
          className="px-8 py-2"
          activeKey={patientCurrent.currentMenu}
          defaultActiveKey={patientCurrent.currentMenu}
          onChange={handleClickPatientMenu}
        >
          {user && (
            user.user_type === UserType.Nurse
              ? (
                <>
                  <TabPane tab="Personal Note" key="personal-note-template">
                    <PersonalNoteTemplate patientID={patientID ?? ''} isCanAddOrEdit />
                  </TabPane>
                  <TabPane tab="Progress Notes" key="medical">
                    <MedicalHistories user={user} />
                  </TabPane>
                  <TabPane tab="X-ray" key="x-ray">
                    <XrayInfo />
                  </TabPane>
                  <TabPane tab="Appointments" key="appointments">
                    <Appointments userType={userType} />
                  </TabPane>
                  <TabPane tab="Create Appointment" key="create_appointment">
                    <CreateAppointmentInPatientPage handleClickPatientMenu={handleClickPatientMenu} />
                  </TabPane>
                  <TabPane tab="Appointment Histories" key="histories">
                    <AppointmentHistories userType={userType} />
                  </TabPane>
                  <TabPane tab="Send Message" key="notifications">
                    <Notifications />
                  </TabPane>
                  <TabPane tab="Attachment" key="attachment">
                    <AttachmentPage patientID={patientID ?? ''} />
                  </TabPane>
                  <TabPane tab="BMI Histories" key="bmi-histories">
                    <BMIHistories patientID={patientID ?? ''} />
                  </TabPane>
                  <TabPane tab="BP Histories" key="bp-histories">
                    <BpHistories patientID={patientID ?? ''} />
                  </TabPane>
                </>
              )
              : (
                <>
                  <TabPane tab="Personal Note" key="personal-note-template">
                    <PersonalNoteTemplate patientID={patientID ?? ''} isCanAddOrEdit />
                  </TabPane>
                  <TabPane tab="Progress Notes" key="medical">
                    <MedicalHistories user={user} />
                  </TabPane>
                  <TabPane tab="Attachment" key="attachment">
                    <AttachmentPage patientID={patientID ?? ''} />
                  </TabPane>
                  <TabPane tab="BMI Histories" key="bmi-histories">
                    <BMIHistories patientID={patientID ?? ''} />
                  </TabPane>
                </>
              )
          )}
        </Tabs>
      </div>
    );
  }

  // SEE DOCTOR DETAIL
  return (
    <div className="h-auto bg-white lg:w-full rounded-3xl">
      <Tabs
        className="px-8 py-2"
        activeKey={doctorCurrent.currentMenu}
        defaultActiveKey={doctorCurrent.currentMenu}
        onChange={handleClickDoctorMenu}
      >
        <TabPane tab="Appointments" key="appointments">
          <Appointments userType={userType} />
        </TabPane>
        <TabPane tab="Create Appointment" key="create_appointment">
          <CreateAppointmentInDoctorPage handleClickDoctorMenu={handleClickDoctorMenu} />
        </TabPane>
        <TabPane tab="Appointment Histories" key="histories">
          <AppointmentHistories userType={userType} />
        </TabPane>
        <TabPane tab="BMI Histories" key="bmi-histories">
          <BMIHistories patientID={patientID ?? ''} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default MainInfo;
