/* eslint-disable import/prefer-default-export */
import { createContext } from 'react';

export const SocketContext = createContext<{
  isConnected:boolean;
  open:(api: string, token: string) => void;
  close:() => void;
  subAdminMessage: (cb: (data: any) => void) => void;
  subAdminRead: (cb: (data: any) => void) => void;
  subPatientReadMyMessage: (name: string, cb: (data: any) => void) => void;
  subTelemedQueue: (cb: (data: any) => void) => void;
  subAdminRemoveMessage: (cb: (data: any) => void) => void;
  unsubAdminMessage: () => void;
  unsubAdminRead: () => void;
  unsubTelemedQueue: () => void;
  unsubPatientReadMyMessage: (name: string) => void;
  unsubAdminRemoveMessage: () => void;
  emitServerRead: (data: any, ack?: (data: any) => void) => boolean;
  emitServerMessage: (data: any, ack?: (data: any) => void) => boolean;
  emitUnsendServerMessage: (data: any, ack?: (data: any) => void) => boolean;
} | null>(null);
