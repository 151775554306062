/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useQuery } from 'react-query';
import { fetchAttachmentByPatientID } from '../api-action';

const useFetchAttachmentByPatientID = (id: string) => {
  return useQuery(
    ['fetch_attachment_by_patient_id', id],
    () => fetchAttachmentByPatientID(id),
    { refetchOnWindowFocus: false },
  );
};

export default useFetchAttachmentByPatientID;
