/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Modal } from 'antd';
import moment from 'moment-timezone';

const AppointmentModal = (date: string, isEditAppointment: boolean, content = '') => {
  if (date && isEditAppointment) {
    return (
      Modal.success({
        title: 'สำเร็จ!',
        content,
        okText: 'OK',
      })
    );
  }

  if (date && !isEditAppointment) {
    const appointmentDate = moment(date).add(543, 'year').format('LL');
    return (
      Modal.success({
        title: 'สำเร็จ!',
        content: `สร้างนัดหมายในวันที่ ${appointmentDate} เสร็จเรียบร้อย`,
        okText: 'OK',
      })
    );
  }

  return (
    Modal.success({
      title: 'สำเร็จ!',
      content: 'ลบนัดหมายเสร็จเรียบร้อย',
      okText: 'OK',
    })
  );
};

export default AppointmentModal;
