/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useQuery } from 'react-query';
import { fetchIncomingAppointmentsByUserID } from '../api-action';

const useFetchIncomingAppointmentsByUserID = (id: string, userType: string) => {
  return useQuery(
    ['incoming_appointments_by_patient_id', id],
    () => fetchIncomingAppointmentsByUserID(id, userType),
    { refetchOnWindowFocus: false },
  );
};

export default useFetchIncomingAppointmentsByUserID;
