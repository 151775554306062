/* eslint-disable react/no-danger */
/* eslint-disable react-hooks/exhaustive-deps */
import UserOutlined from '@ant-design/icons/UserOutlined';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import { Avatar, Col, Row } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';
import config from '../../../config';
import TinyMECShowDetail from '../../common/components/TinyMECShowDetail';
import { AppointmentType } from '../../common/constant-enum-type';
import { TMedicalNote } from '../../common/form-schema/appointments/AppointmentSchema';
import { UserSchema } from '../../common/form-schema/user/UserSchema';
import { AppointmentTypeBadge } from './AppointmentTypes';

const RowDisplay = styled.div`
  display: flex;
  flex-direction: column;
`;

const ELearningLink = styled.span`
  a {
  color: #1890FF;
  }
`;

interface IAppointmentDiagnosisModalFromMedicalHistoryInfoProps {
  isModalVisible: boolean;
  handleClickHideModal: () => void;
  medicalNote: TMedicalNote;
}

const AppointmentDiagnosisModalFromMedicalHistoryInfo: React.FC<IAppointmentDiagnosisModalFromMedicalHistoryInfoProps> = (props) => {
  const {
    isModalVisible,
    handleClickHideModal,
    medicalNote,
  } = props;
  const [bloodTest, setBloodTest] = useState('');

  const baseURL = `${config.apiPath}`;

  const queryClient = useQueryClient();
  const userQuery = queryClient.getQueryData<{ success: boolean; user_info: UserSchema }>(['user']);

  useEffect(() => {
    if (medicalNote.appointment_id.blood_test === 'morning') {
      setBloodTest('ช่วงเช้าก่อนพบแพทย์');
    } else if (medicalNote.appointment_id.blood_test === 'before_two_weeks') {
      setBloodTest('อย่างน้อย 2 สัปดาห์');
    } else if (medicalNote.appointment_id.blood_test === 'before_one_week') {
      setBloodTest('อย่างน้อย 1 สัปดาห์');
    } else {
      setBloodTest('ไม่ต้องตรวจเลือด');
    }
  }, [medicalNote.appointment_id.blood_test, isModalVisible]);

  return (
    <div>
      <Modal
        title={(
          <Row align="middle" className="flex flex-row align-middle">
            <p className="mr-3 text-xl font-medium" style={{ color: medicalNote.appointment_id.appointment_type === AppointmentType.onsite ? '#1E73BE' : '#ED6024' }}>
              {moment(medicalNote.appointment_id.appointment_date).add(543, 'year').format('LL')}
            </p>
          </Row>
        )}
        open={isModalVisible}
        centered
        width={698}
        onCancel={handleClickHideModal}
        closeIcon={<CloseOutlined className="outline-none" />}
        footer={false}
      >
        <div className="flex flex-col">
          <Row className="mb-3">
            <Col span={12}>
              <p className="mb-1 text-base font-medium">ผู้รับบริการ</p>
              <div className="flex items-center">
                <Avatar
                  size={50}
                  src={medicalNote.appointment_id.patient_id.avatar_img_url && `${baseURL}/cos/image/${medicalNote.appointment_id.patient_id.avatar_img_url}`}
                  icon={!medicalNote.appointment_id.patient_id.avatar_img_url && <UserOutlined />}
                />
                <p className="ml-3 text-xl">{medicalNote.appointment_id.patient_id.name_title}{medicalNote.appointment_id.patient_id.first_name}  {medicalNote.appointment_id.patient_id.last_name}</p>
              </div>
            </Col>
            {userQuery?.user_info.user_type !== 'Doctor'
              && (
                <Col span={12}>
                  <p className="mb-1 text-base font-medium">แพทย์</p>
                  <div className="flex items-center">
                    <Avatar
                      size={50}
                      src={medicalNote.appointment_id.doctor_id.avatar_img_url && `${baseURL}/cos/image/${medicalNote.appointment_id.doctor_id.avatar_img_url}`}
                      icon={!medicalNote.appointment_id.doctor_id.avatar_img_url && <UserOutlined />}
                    />
                    <p className="ml-3 text-xl">{medicalNote.appointment_id.doctor_id.name_title}{medicalNote.appointment_id.doctor_id.first_name}  {medicalNote.appointment_id.doctor_id.last_name}</p>
                  </div>
                </Col>
              )}
          </Row>
          <RowDisplay>
            <p className="text-base font-medium">ประเภทการนัดหมาย</p>
            <Row className="mb-3">
              <AppointmentTypeBadge appointmentType={medicalNote.appointment_id.appointment_type} />
            </Row>
          </RowDisplay>
          {medicalNote.note
            && (
              <div className="mb-3 text-base">
                <p className="text-base font-medium">Doctor Note</p>
                <span className="text-base opacity-90">
                  <TinyMECShowDetail details={medicalNote.note} />
                </span>
              </div>
            )}
          {medicalNote.appointment_id.nurse_note
            && (
              <div className="mb-3 text-base">
                <p className="text-base font-medium">Nurse Note</p>
                <span className="text-base opacity-90">
                  <TinyMECShowDetail details={medicalNote.appointment_id.nurse_note} />
                </span>
              </div>
            )}
          {medicalNote.appointment_id.appointment_type === AppointmentType.onsite && (
            <RowDisplay>
              <p className="text-base font-medium">การตรวจเลือด</p>
              <p className="mb-3 text-base opacity-90">{bloodTest}</p>
            </RowDisplay>
          )}
          <RowDisplay>
            {medicalNote.appointment_id.e_learning.length > 0 && <p className="text-base font-medium">E-Learning</p>}
            {medicalNote.appointment_id.e_learning.length > 0
              && (
                medicalNote.appointment_id.e_learning.map((value: string) => {
                  return (
                    <div className="flex flex-row text-base font-normal opacity-90">
                      <span>-&nbsp;</span>
                      <ELearningLink dangerouslySetInnerHTML={{ __html: value }} />
                    </div>
                  );
                })
              )}
          </RowDisplay>
        </div>
      </Modal>
    </div>
  );
};

export default AppointmentDiagnosisModalFromMedicalHistoryInfo;
