import styled from 'styled-components';
import { Button } from 'antd';

export const PrimaryButton = styled(Button)`
  color: #ffffff;
  background-color: #40a9ff;
  border-color: #40a9ff;
`;

export const PrimaryBlueButton = styled(Button)`
  color: #ffffff;
  background-color: #1e73be;
  border-color: #1e73be;
  border-radius: 10px;
`;

export const SaveAndSendNotiButton = styled(Button)`
  color: #ffffff;
  background-color: #dd6943;
  border-color: #dd6943;
`;

export const SaveButton = styled(Button)`
  color: #ffffff;
  background: #649ff8;
  @media (max-width: 1280px) {
    width: 100%;
  }
`;

export const LoginButton = styled(Button)`
  color: #ffffff;
  background: #1e73be;
  width: 100%;
  vertical-align: middle;
  padding-left: 1rem;
  padding-bottom: 1.5rem;
  font-size: 14px;
  font-weight: 400;
  height: 36px;
`;

export const CancelButton = styled(Button)`
  background: #cf1322;
  color: #ffffff;
`;

export const AddLinkButton = styled(Button)`
  background: #8eccff;
  color: white;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: transparent;
  padding-top: 1.5px;
  padding-bottom: 1.5px;
`;

export const RemoveLinkButton = styled(Button)`
  background: #db3832;
  color: white;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: transparent;
  padding-top: 1.5px;
  padding-bottom: 1.5px;
`;

export const DottedButton = styled(Button)`
  margin-bottom: -1.25rem;
  font-size: 16px;
  line-height: 1rem;
  text-align: center;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(55, 65, 81, var(--tw-border-opacity));
  border-style: dotted;
  border-radius: 1.5rem;
  width: 100%;
`;

export const StartVideoCallButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-radius: 1.5rem;
`;

export const SendNotificationButton = styled(Button)`
  background: #8eccff !important;
  color: #ffffff !important;
  border: none;
  padding: 0.5px 12px;
  &:disable {
    cursor: not-allowed;
    background: #d1d5db !important;
  }
`;

export const EditAppointmentButton = styled(Button)`
  background: #fdffff;
  color: #009cb8;
  border-color: #009cb8;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-radius: 1.5rem;
`;

export const BackToAppointmentModalButton = styled(Button)`
  background: #fdffff;
  color: #a78bfa;
  border-color: #a78bfa;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-radius: 1.5rem;
`;

export const EditUserInfoButton = styled(Button)`
  @media screen and (max-width: 1024px) {
    padding: 0rem 1rem 0rem 1rem;
    margin-bottom: 0.5rem;
  }
  @media screen and (min-width: 1025px) {
    padding: 1.15rem 1rem 1.15rem 1rem;
  }
  background: #9b60fa;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  border-radius: 1rem;
`;

export const BackButton = styled(Button)`
  background: #e6f7ff;
  color: #1e73be;
  border: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  border-radius: 2.5rem;
  padding: 1.15rem 1rem 1.15rem 1rem;
`;

export const ReturnButton = styled(Button)`
  background: white;
  color: #1e73be;
  border-color: #60aaf5;
`;

export const DeleteBMIButton = styled(Button)`
  background: #cf1322;
  color: #ffffff;
  border-color: #cf1322;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-radius: 1.5rem;
  &:hover {
    background: #ffffff;
    color: #cf1322;
    border-color: #cf1322;
  }
  &:focus {
    background: #ffffff;
    color: #cf1322;
    border-color: #cf1322;
  }
`;

export const DeleteBpButton = styled(Button)`
  background: #cf1322;
  color: #ffffff;
  border-color: #cf1322;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-radius: 1.5rem;
  &:hover {
    background: #ffffff;
    color: #cf1322;
    border-color: #cf1322;
  }
  &:focus {
    background: #ffffff;
    color: #cf1322;
    border-color: #cf1322;
  }
`;

export const SaveNoteButton = styled(Button)`
  background: #719df1;
  color: white;
  border-color: #719df1;
  padding-left: 1rem;
  padding-right: 1rem;
  &:hover {
    background: white;
    color: #719df1;
    border-color: #719df1;
  }
`;

export const AddPersonalNoteButton = styled(Button)`
  background: #719df1;
  color: white;
  border-color: #719df1;
  padding-left: 1rem;
  padding-right: 1rem;
  &:hover {
    background: white;
    color: #719df1;
    border-color: #719df1;
  }
`;

export const EditPersonalNoteButton = styled(Button)`
  background: #719df1;
  color: white;
  border-color: #719df1;
  padding-left: 1rem;
  padding-right: 1rem;
  &:hover {
    background: white;
    color: #719df1;
    border-color: #719df1;
  }
`;
