import * as Yup from 'yup';

export interface TLogin {
  username: string;
  password: string;
}

const initialValues: TLogin = {
  username: '',
  password: '',
};

export const validationSchema = Yup.object().shape({
  username: Yup.string()
    .required('กรุณากรอกชื่อผู้ใช้'),
  password: Yup.string()
    .required('กรุณากรอกรหัสผ่าน'),
});

export default initialValues;
