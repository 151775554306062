/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import { Checkbox, Modal } from 'antd';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import MedicalhistoryInfo from '../appointment/components/MedicalHistoryInfo';
import { SaveButton } from '../common/components/Button';
import { InputBox, TextAreaInput } from '../common/components/Input';
import Loading from '../common/components/Loading';
import TinyMECInput from '../common/components/TinyMECInput';
import { useFetchAllMedicalNote, useCreatePlainMedicalNote } from '../common/connecting-api/appointment/hooks/use-manage-all-medical-note';
import { SortType } from '../common/constant-enum-type';
import { TMedicalNote, TMedicalNoteInitialValue } from '../common/form-schema/appointments/AppointmentSchema';
import { UserSchema } from '../common/form-schema/user/UserSchema';

const handleFilterMedicalNote = (medicalNoteLists: TMedicalNote[], searchKey: string) => {
  return medicalNoteLists.filter((note) => {
    return (
      note.note.toLocaleLowerCase().includes(searchKey.toLowerCase())
    );
  });
};

interface Props {
  user: UserSchema;
}

const MedicalHistories: React.FC<Props> = (props) => {
  const { user } = props;
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const { mutate: createPlainMedicalNote } = useCreatePlainMedicalNote();

  const [showMedicalNote, setShowMedicalNote] = useState('');
  const [plainMedicalNote, setPlainMedicalNote] = useState('');
  const [isExtraVisit, setIsExtraVisit] = useState(false);
  const [sortType, setSortType] = useState(SortType.desc);
  const { refetch: refetchMedicalNote, data, isLoading } = useFetchAllMedicalNote(id, sortType);

  useEffect(() => {
    refetchMedicalNote();
  }, [sortType]);

  useEffect(() => {
    if (plainMedicalNote.trim().length === 0) { setIsExtraVisit(false); }
  }, [plainMedicalNote]);

  if (isLoading) return <div><Loading /></div>;

  if (!data || !data.success) {
    return (
      <div className="p-4 text-center">
        <p className="text-base font-normal">
          เกิดข้อผิดพลาด กรุณาลองอีกครั้งในภายหลัง
        </p>
      </div>
    );
  }

  const handleAlertSuccessCreate = () => {
    setPlainMedicalNote('');
    setIsExtraVisit(false);
    queryClient.invalidateQueries(['all_medical_note', id]);
    Modal.success({
      title: 'สำเร็จ!',
      content: 'สร้าง Medical Note เสร็จเรียบร้อย',
      okText: ' OK ',
    });
  };

  const handleAlertErrorCreate = () => {
    Modal.error({
      title: 'เกิดข้อผิดพลาด!',
      content: 'มีข้อผิดพลาดเกิดขึ้น กรุณาลองใหม่อีกครั้งภายหลัง',
      okText: ' Close ',
    });
  };

  const handleSavePlainMedicalNote = () => {
    const values: TMedicalNoteInitialValue = {
      extra_visit: isExtraVisit,
      note: plainMedicalNote.trim(),
      patient_id: id,
      created_by: user._id,
    };
    createPlainMedicalNote(values, { onSuccess: handleAlertSuccessCreate, onError: handleAlertErrorCreate });
  };

  const filteredMedicalNote = handleFilterMedicalNote(data.medical_note, showMedicalNote);

  const handleSortMedicalNote = () => {
    if (sortType === SortType.desc) { setSortType(SortType.asc); }
    if (sortType === SortType.asc) { setSortType(SortType.desc); }
  };

  return (
    <div className="mb-8">
      <div>
        <p className="text-lg font-medium" style={{ color: '#CF1322' }}>{moment(new Date()).add(543, 'year').format('LL')}</p>
        <TinyMECInput inputValue={plainMedicalNote} handleChangeValue={(value) => setPlainMedicalNote(value)} />

        <div className="relative flex justify-between mt-4">
          <Checkbox
            checked={isExtraVisit}
            className="text-sm"
            onChange={(e) => setIsExtraVisit(e.target.checked)}
            disabled={plainMedicalNote.length === 0}
          >
            Extra Visit
          </Checkbox>
          <div className="absolute top-0 left-0 right-0 flex mx-auto max-w-max">
            <SaveButton
              className="self-center"
              onClick={handleSavePlainMedicalNote}
              disabled={plainMedicalNote.trim().length === 0}
            >
              Save
            </SaveButton>
          </div>
        </div>
        <div>
          <div className="flex flex-row justify-between w-full mt-8">
            <InputBox
              style={{ width: '300px' }}
              suffix={<SearchOutlined className="opacity-40" />}
              value={showMedicalNote}
              onChange={(e) => setShowMedicalNote(e.target.value)}
              placeholder="ค้นหาด้วย Medical Note"
            />
            <div className="flex flex-row items-center cursor-pointer" style={{ columnGap: '6px' }} onClick={handleSortMedicalNote}>
              <p className="text-lg font-medium">Sort</p>
              <img alt="Sort Icon" src="/static/icon/icon-sort.svg" loading="lazy" />
            </div>
          </div>
          <hr className="mt-6 mb-4" />
        </div>
      </div>
      {filteredMedicalNote.length === 0 ? (
        <p className="text-base text-center">ยังไม่มี Progress Note</p>
      ) : (
        filteredMedicalNote.map((note: TMedicalNote, index: number) => {
          const appointmentLength = filteredMedicalNote.length;
          return (
            <MedicalhistoryInfo
              key={note._id}
              medicalNote={note}
              isLastElement={appointmentLength === index + 1}
            />
          );
        })
      )}
    </div>
  );
};

export default MedicalHistories;
