import { TDoctor } from '../../../form-schema/doctors/DoctorSchema';
import { TNurse } from '../../../form-schema/nurses/NurseSchema';
import { TPatient } from '../../../form-schema/patients/PatientSchema';
import { TShortcutMessage } from '../../../form-schema/shortcut-message/ShortcutMessageSchema';

export enum ErrorText {
  DUCPLICATED_CITIZEN_ID = 'duplicated citizen id',
  DUCPLICATED_USERNAME = 'duplicated username',
}

// add or edit patient, nurse and doctor responses
export interface PatientResponse {
  success: boolean;
  error?: ErrorText;
  duplicated_patient?: TPatient;
}

export interface NurseResponses {
  success: boolean;
  error?: ErrorText;
  duplicated_nurse?: TNurse;
}

export interface DoctorResponses {
  success: boolean;
  error?: ErrorText;
  duplicated_doctor?: TDoctor;
}
export interface ShortcutMessageResponses {
  success: boolean;
  error?: ErrorText;
  duplicated_shortcut_message?: TShortcutMessage;
}
