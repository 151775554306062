/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useQuery } from 'react-query';
import { fetchBpsByPatientId } from '../api-action';

const useFetchBpsByPatientId = (id: string) => {
  return useQuery(
    ['bps-by-patient-id', id],
    () => fetchBpsByPatientId(id),
    { refetchOnWindowFocus: false },
  );
};

export default useFetchBpsByPatientId;
