/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-lonely-if */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import EyeOutlined from '@ant-design/icons/EyeOutlined';
import QuestionCircleTwoTone from '@ant-design/icons/QuestionCircleTwoTone';
import UploadOutlined from '@ant-design/icons/UploadOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';

import Avatar from 'antd/lib/avatar/avatar';
import {
  useFormik,
} from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { Checkbox, Popover } from 'antd';
import Select from 'antd/es/select';
import 'moment/locale/th';
import { useQueryClient } from 'react-query';
import initialValues,
{
  TDoctorEdit,
  TDoctorInitialValue,
  validationSchema,
} from '../../form-schema/doctors/DoctorSchema';
import { SaveButton } from '../Button';
import { InputBox, TextAreaInput } from '../Input';
import { ErrorMsg, HeadText } from '../Lable';
import useAddDoctor from '../../connecting-api/doctor/hooks/use-add-doctor';
import ValidateCitizenID from '../../validate-citizen-id';
import useUploadPersonAvatar from '../../connecting-api/person-info/hooks/use-upload-person-avatar';
import config from '../../../../config';
import useFetchDoctorByID from '../../connecting-api/doctor/hooks/use-fetch-doctor-by-id';
import Loading from '../Loading';
import useEditDoctorByID from '../../connecting-api/doctor/hooks/use-edit-docto-by-id';
import { UserSchema } from '../../form-schema/user/UserSchema';
import {
  dates,
  months,
  years,
} from '../../birth-date-data';
import NameTitlesDoctor from '../../../appointment/components/NameTitlesDoctor';
import { NameTitle as EnumNameTitle, UserType } from '../../constant-enum-type';
import { handleCalculateAge } from '../../all-about-string-method';
import HandleInitDateOfBirth, { DateInterface } from '../../handle-init-date-of-birth';
import { DoctorResponses, ErrorText } from './common/responses';
import { ErrorType, FailedModal, ModalData, SuccessModal } from './common/modals/ReponsesModal';

const { Option } = Select;

const AddNewDoctorForm: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const { mutate: addDoctor } = useAddDoctor();
  const { mutate: editDoctor } = useEditDoctorByID();
  const { mutate: uploadAvatar } = useUploadPersonAvatar();

  const { data, isLoading, status } = useFetchDoctorByID(id);

  const queryClient = useQueryClient();
  const queryData = queryClient.getQueryData<{ success: boolean; user_info: UserSchema }>(['user']);
  const user = queryData;

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isCitizenIDCorrect, setIsCititzenIDCorrect] = useState(true);
  const [isShowErrorUpload, setIsShowErrorUpload] = useState(false);

  const [dateOfBirth, setDateOfBirth] = useState<DateInterface>(HandleInitDateOfBirth(data?.success && data.doctor ? data.doctor : undefined));

  const [birthDateForCalAge, setBirthDateForCalAge] = useState('');
  const [birthMonthForCalAge, setBirthMonthForCalAge] = useState('');

  const [inputOtherNameTitle, setInputOtherNameTitle] = useState('');
  const [showInputOtherNameTitle, setShowInputOtherNameTitle] = useState(false);
  const [mustInputOtherNameTitle, setMustInputOtherNameTitle] = useState(false);

  const [errorEmptyConfirmPassword, setErrorEmptyConfirmPassword] = useState(false);
  const [errorPasswordNotMatch, setErrorPasswordNotMatch] = useState(false);

  const handleAlertSuccess = (res: DoctorResponses, createdDoctor: TDoctorEdit | TDoctorInitialValue) => {
    if (res.success === false) {
      const failedModalData: ModalData = {
        personType: UserType.Doctor,
        text: ErrorType.BACKEND_ERROR,
      };
      const duplicatedDataModalData: ModalData = {
        ...failedModalData,
        exsitedPersonData: res.duplicated_doctor,
        personData: createdDoctor,
      };
      if (res.error === ErrorText.DUCPLICATED_USERNAME) {
        FailedModal(ErrorType.DUCPLICATED_USERNAME, duplicatedDataModalData);
      } else if (res.error === ErrorText.DUCPLICATED_CITIZEN_ID) {
        FailedModal(ErrorType.DUCPLICATED_CITIZEN_ID, duplicatedDataModalData);
      } else {
        FailedModal(ErrorType.BACKEND_ERROR, failedModalData);
      }
    } else {
      queryClient.invalidateQueries(['doctor_by_id', id]);
      const successModalData: ModalData = {
        personType: UserType.Doctor,
        text: 'Operation Successfully',
      };
      const successModal = SuccessModal(successModalData);
      setTimeout(() => {
        successModal.destroy();
      }, 4000);
      history.push('/doctors');
    }
  };

  const handleAlertError = () => {
    const apiErrorResponseModal: ModalData = {
      personType: UserType.Doctor,
      text: ErrorType.BACKEND_ERROR,
    };
    FailedModal(ErrorType.BACKEND_ERROR, apiErrorResponseModal);
  };

  const onSubmit = (values: TDoctorInitialValue) => {
    const validateCitizenID = ValidateCitizenID(values.citizen_id);
    if (!validateCitizenID && values.citizen_id) {
      setIsCititzenIDCorrect(false);
    } else {
      setIsCititzenIDCorrect(true);

      if (values.name_title === EnumNameTitle.other && inputOtherNameTitle.length === 0) {
        setMustInputOtherNameTitle(true);
      } else {
        setMustInputOtherNameTitle(false);
        let nameTitleToSave = '';
        if (inputOtherNameTitle.trim().length > 0 || values.name_title === EnumNameTitle.other) {
          nameTitleToSave = inputOtherNameTitle;
        } else {
          nameTitleToSave = values.name_title;
        }

        // DATE OF BIRTH
        let isoStringDate = '';
        let BIRTH_DATE = dateOfBirth.date;
        let BIRTH_MONTH = dateOfBirth.month;
        let BIRTH_YEAR = dateOfBirth.year;

        if (BIRTH_DATE === undefined) {
          BIRTH_DATE = '01';
        }
        if (BIRTH_DATE && BIRTH_DATE.length === 1) {
          BIRTH_DATE = `0${BIRTH_DATE}`;
        }

        if (BIRTH_MONTH === undefined) {
          BIRTH_MONTH = '01';
        }
        if (BIRTH_MONTH && BIRTH_MONTH.length === 1) {
          BIRTH_MONTH = `0${BIRTH_MONTH}`;
        }

        if (BIRTH_YEAR === undefined) {
          BIRTH_YEAR = '0001';
        } else {
          BIRTH_YEAR = (Number(BIRTH_YEAR) - 543).toString();
        }

        isoStringDate = `${BIRTH_YEAR}-${BIRTH_MONTH}-${BIRTH_DATE}T00:00:00.000Z`;

        // WORK DAYS
        const daysInWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        const markedDays = values.unavailable_day.filter((day) => day !== '');
        let unavailableDays = [];
        if (id) {
          for (let i = 0; i < markedDays.length; i += 1) {
            const founded = daysInWeek.find((value) => value === markedDays[i]);
            if (founded) {
              unavailableDays.push(founded);
            }
          }
        } else {
          for (let i = 0; i < markedDays.length; i += 1) {
            const markedDay = daysInWeek.find((value) => value === markedDays[i]);
            if (markedDay) {
              const indexOfMarkedDay = daysInWeek.indexOf(markedDay);
              daysInWeek.splice(indexOfMarkedDay, 1);
            }
          }
        }
        if (unavailableDays.length === 0) unavailableDays = ['Free'];
        const value = {
          avatar_img_url: values.avatar_img_url.trim(),
          name_title: nameTitleToSave.trim(),
          first_name: values.first_name.trim(),
          last_name: values.last_name.trim(),
          mobile_number: values.mobile_number.trim(),
          email: values.email ? values.email.trim() : '',
          raw_address: values.raw_address.trim(),
          date_of_birth: isoStringDate,
          citizen_id: values.citizen_id.trim(),
          password: values.password ? values.password : undefined,
          gender: values.gender,
        };
        if (id) {
          const valueToEdit = {
            ...value,
            unavailable_day: unavailableDays,
          };
          editDoctor({ id, body: valueToEdit }, { onSuccess: (res) => handleAlertSuccess(res, valueToEdit), onError: handleAlertError });
        } else {
          // Add New
          if (user?.success) {
            const valueToCreate = {
              ...value,
              unavailable_day: daysInWeek.length === 0 ? ['Free'] : daysInWeek,
              username: values.username.trim(),
              password: values.password.trim(),
              confirm_password: values.confirm_password && values.confirm_password.trim(),
              hospital_id: user.user_info.hospital_id,
              gender: values.gender,
            };
            delete valueToCreate.confirm_password;
            addDoctor(valueToCreate, { onSuccess: (res) => handleAlertSuccess(res, valueToCreate), onError: handleAlertError });
          }
        }
      }
    }
  };

  const formik = useFormik<TDoctorInitialValue>({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const {
    handleChange,
    values,
    handleSubmit,
    handleBlur,
    setFieldValue,
    errors,
    touched,
  } = formik;

  const handleShowHidePassword = (state: boolean) => {
    setIsShowPassword(!state);
  };

  const handleFetchImage = (res: any) => {
    setIsShowErrorUpload(false);
    setFieldValue('avatar_img_url', res.avatar_img_url);
  };

  const handleErrorUpload = () => {
    setIsShowErrorUpload(true);
  };

  const handleSelectImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const imageFile = e.target.files?.item(0);
    if (!imageFile) return;
    const fd = new FormData();
    fd.append('file', imageFile, imageFile.name);
    await uploadAvatar(fd, { onSuccess: handleFetchImage, onError: handleErrorUpload });
  };

  const handleSetWorkDay = (index: number, value: string) => {
    if (data?.success && data.doctor) {
      if (values.unavailable_day.includes(value)) {
        const valueIndex = values.unavailable_day.indexOf(value);
        setFieldValue(`unavailable_day[${valueIndex}]`, '');
      } else {
        const lastIndex = values.unavailable_day.length - 1;
        setFieldValue(`unavailable_day[${lastIndex + 1}]`, value);
      }
    } else {
      if (!values.unavailable_day[index] || values.unavailable_day[index] !== value) {
        setFieldValue(`unavailable_day[${index}]`, value);
      } else {
        setFieldValue(`unavailable_day[${index}]`, '');
      }
    }
  };

  const handleCheckConfirmPassword = (e: React.FocusEvent<HTMLInputElement>) => {
    if (values.confirm_password?.length === 0) {
      setErrorPasswordNotMatch(false);
      setErrorEmptyConfirmPassword(true);
    } else {
      setErrorPasswordNotMatch(false);
      setErrorEmptyConfirmPassword(false);
    }
    if (values.confirm_password && values.confirm_password.length > 0 && values.confirm_password !== values.password) {
      setErrorPasswordNotMatch(true);
    } else {
      setErrorPasswordNotMatch(false);
    }
    handleBlur(e);
  };

  useEffect(() => {
    if (id && status === 'success' && data?.success) {
      if (data.doctor) {
        const doctorData = data.doctor;
        setFieldValue('_id', doctorData._id);
        setFieldValue('avatar_img_url', doctorData.avatar_img_url);
        setFieldValue('username', doctorData.username);
        setFieldValue('name_title', doctorData.name_title);
        setFieldValue('first_name', doctorData.first_name);
        setFieldValue('last_name', doctorData.last_name);
        setFieldValue('mobile_number', doctorData.mobile_number);
        setFieldValue('email', doctorData.email);
        setFieldValue('gender', doctorData.gender);
        setFieldValue('raw_address', doctorData.raw_address);
        setFieldValue('citizen_id', doctorData.citizen_id);
        setFieldValue('date_of_birth', doctorData.date_of_birth);
        setFieldValue('unavailable_day', doctorData.unavailable_day);
        setFieldValue('password', '');
      }
    }
  }, [status, data?.success, id]);

  // change name title
  useEffect(() => {
    if (values.name_title === EnumNameTitle.other) {
      setShowInputOtherNameTitle(true);
    } else {
      setShowInputOtherNameTitle(false);
      setInputOtherNameTitle('');
    }
  }, [values.name_title]);

  useEffect(() => {
    if (values.citizen_id.length > 0) setIsCititzenIDCorrect(true);
  }, [values.citizen_id]);

  // adding 0 to birth-date for cal age
  useEffect(() => {
    if (dateOfBirth.date) {
      if (dateOfBirth.date && dateOfBirth.date.length === 1) {
        setBirthDateForCalAge(`0${dateOfBirth.date}`);
      } else {
        setBirthDateForCalAge(dateOfBirth.date);
      }
    }
  }, [dateOfBirth.date]);

  // adding 0 to birth-month for cal age
  useEffect(() => {
    if (dateOfBirth.month) {
      if (dateOfBirth.month && dateOfBirth.month.length === 1) {
        setBirthMonthForCalAge(`0${dateOfBirth.month}`);
      } else {
        setBirthMonthForCalAge(dateOfBirth.month);
      }
    }
  }, [dateOfBirth.month]);

  // automatic set birth date and month to 01 01
  useEffect(() => {
    if (dateOfBirth.year) {
      if (!dateOfBirth.date && !dateOfBirth.month) {
        setDateOfBirth({
          ...dateOfBirth,
          date: '01',
          month: '1',
        });
      } else if (!dateOfBirth.date && dateOfBirth.month) {
        setDateOfBirth({
          ...dateOfBirth,
          date: '01',
        });
      }
    }
  }, [dateOfBirth.year]);

  // recheck date of birth every time refresh page
  useEffect(() => {
    if (data?.success && data.doctor) {
      const nurseDateOfBirth = HandleInitDateOfBirth(data.doctor);
      if (nurseDateOfBirth.date?.includes('1') && nurseDateOfBirth.month?.includes('1') && nurseDateOfBirth.year === '544') {
        setDateOfBirth({
          date: undefined,
          month: undefined,
          year: undefined,
        });
      } else {
        setDateOfBirth(nurseDateOfBirth);
      }
    }
  }, [data?.success && data.doctor?.date_of_birth]);

  useEffect(() => {
    if (values.password.length < 8) {
      setFieldValue('confirm_password', '');
    }
  }, [values.password, values.confirm_password]);

  if (id) {
    if (isLoading) return <div><Loading /></div>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col lg:flex-row">
        <div className="flex-1 px-8">
          <HeadText>รูป</HeadText>
          <div className="w-full">
            <div>
              <Avatar
                icon={<UserOutlined />}
                src={values.avatar_img_url && `${config.apiPath}/cos/image/${values.avatar_img_url}`}
                className="block mb-4 ml-auto mr-auto"
                size={{
                  md: 80,
                  lg: 80,
                  xl: 100,
                  xxl: 100,
                }}
              />
              <label
                htmlFor="avatar_img_url"
                className="items-center block w-40 py-1 mb-4 ml-auto mr-auto text-base font-medium text-center border-2 outline-none cursor-pointer opacity-80 rounded-3xl"
              >
                <UploadOutlined className="mr-3" />
                Add new avatar
                <input
                  id="avatar_img_url"
                  name="avatar_img_url"
                  className="hidden"
                  type="file"
                  accept="image/*"
                  onChange={handleSelectImage}
                />
              </label>
              {isShowErrorUpload && <div className="my-2 text-center"><ErrorMsg>เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้งในภายหลัง</ErrorMsg></div>}
            </div>
            <div className="mb-2 text-base font-medium">
              <p className="mb-1">ชื่อผู้ใช้ <span className="text-red-500">*</span></p>
              <InputBox
                id="username"
                name="username"
                readOnly={!!id}
                disabled={!!id}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.username}
                type="text"
                className={`${!!id && 'border-transparent'}`}
              />
              {errors.username && touched.username && <ErrorMsg>{errors.username}</ErrorMsg>}
            </div>
            <div className="mb-2 text-base font-medium">
              <div className="flex flex-row md:flex-col xl:flex-row">
                <div className="flex flex-row xl:mr-1">
                  <p className="mb-1 mr-1">{id && 'เปลี่ยน'}รหัสผ่าน</p>
                  {!id && <span className="mb-1 mr-1 text-sm text-red-500">*</span>}
                </div>
              </div>
              <p className="mb-1 md:text-xs xl:text-sm opacity-90">{id && !user?.user_info.is_super_admin ? '(สงวนสิทธิ์เฉพาะ Super Admin เท่านั้น)' : '(ไม่กำหนดรูปแบบ แต่ต้องมีอย่างน้อย 8 ตัวอักษร)'}</p>
              <InputBox
                id="password"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                suffix={<EyeOutlined onClick={() => handleShowHidePassword(isShowPassword)} />}
                type={isShowPassword ? 'text' : 'password'}
                disabled={!!id && !user?.user_info.is_super_admin}
                className={`${!!id && !user?.user_info.is_super_admin && 'border-transparent'}`}
              />
              {errors.password && touched.password && <ErrorMsg>{errors.password}</ErrorMsg>}
            </div>
            {values.password.length >= 8 && (
              <div className="text-base font-medium">
                <div className="flex flex-row md:flex-col xl:flex-row">
                  <div className="flex flex-row xl:mr-1">
                    <p className="mb-1 mr-1">ยืนยันรหัสผ่าน</p>
                    <span className="mb-1 mr-1 text-sm text-red-500">*</span>
                  </div>
                </div>
                <p className="mb-1 md:text-xs xl:text-sm opacity-90">(กรอกรหัสผ่านอีกครั้ง)</p>
                <InputBox
                  id="confirm_password"
                  name="confirm_password"
                  onBlur={(e) => (id ? handleCheckConfirmPassword(e) : handleBlur(e))}
                  onChange={handleChange}
                  value={values.confirm_password}
                  suffix={<EyeOutlined onClick={() => handleShowHidePassword(isShowPassword)} />}
                  type={isShowPassword ? 'text' : 'password'}
                />
                {id && errorEmptyConfirmPassword && <ErrorMsg>กรุณายืนยันรหัสผ่าน</ErrorMsg>}
                {id && errorPasswordNotMatch && <ErrorMsg>รหัสผ่านไม่ตรงกัน</ErrorMsg>}
                {errors.confirm_password && touched.confirm_password && <ErrorMsg>{errors.confirm_password}</ErrorMsg>}
              </div>
            )}
          </div>
        </div>
        <div className="flex-1 px-8 mt-6 lg:mt-0">
          <HeadText>ข้อมูลแพทย์</HeadText>
          <div className="mb-2 text-base font-medium">
            <p className="mb-1">คำนำหน้าชื่อ</p>
            <div className={`flex flex-row ${showInputOtherNameTitle && 'gap-x-2'}`}>
              <div className={`${showInputOtherNameTitle ? 'w-1/3' : 'w-full'}`}>
                <NameTitlesDoctor formik={formik} />
              </div>
              {showInputOtherNameTitle && (
                <InputBox
                  type="text"
                  autoFocus
                  value={inputOtherNameTitle}
                  onChange={(e) => setInputOtherNameTitle(e.target.value)}
                  placeholder="กรุณากรอกคำนำหน้าชื่อ"
                />
              )}
            </div>
            {mustInputOtherNameTitle && inputOtherNameTitle.length === 0 && <ErrorMsg>กรุณากรอกคำนำหน้าชื่อ</ErrorMsg>}
          </div>
          <div className="mb-2 text-base font-medium">
            <p className="mb-1">ชื่อ <span className="text-red-500">*</span></p>
            <InputBox
              id="first_name"
              name="first_name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.first_name}
              type="text"
            />
            {errors.first_name && touched.first_name && <ErrorMsg>{errors.first_name}</ErrorMsg>}
          </div>
          <div className="mb-2 text-base font-medium">
            <p className="mb-1">นามสกุล <span className="text-red-500">*</span></p>
            <InputBox
              id="last_name"
              name="last_name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.last_name}
              type="text"
            />
            {errors.last_name && touched.last_name && <ErrorMsg>{errors.last_name}</ErrorMsg>}
          </div>
          <div className="mb-2 text-base font-medium">
            <p className="mb-1">เลขบัตรประชาชน</p>
            <InputBox
              id="citizen_id"
              name="citizen_id"
              onBlur={handleBlur}
              onChange={(e) => {
                if (/^\d{0,13}$/.test(e.target.value)) {
                  setFieldValue('citizen_id', e.target.value);
                }
              }}
              value={values.citizen_id}
              type="text"
              // className={`${!!id && 'border-transparent'}`}
              maxLength={13}
            />
            {errors.citizen_id && touched.citizen_id && <ErrorMsg>{errors.citizen_id}</ErrorMsg>}
            {!isCitizenIDCorrect && <ErrorMsg>เลขบัตรประชาชนไม่ถูกต้อง กรุณาตรวจสอบอีกครั้ง</ErrorMsg>}
          </div>
          <div className="mb-2 text-base font-medium">
            <div className="flex flex-row items-center">
              <p className="mb-1 mr-2">วันเดือนปีเกิด</p>
              <Popover content={<p className="mb-10 text-base">• สามารถปล่อยว่างได้<br />• สามารถกรอก &apos;ปี&apos; เพียงอย่างเดียวได้ ซึ่งระบบจะเลือกวันเกิดให้เป็น <span className="underline">วันที่ 1 มกราคม</span> ของปีนั้นๆ<br />• หากกรอก &apos;เดือน&apos; และ &apos;ปี&apos; ระบบจะคำนวณให้วันเกิดเป็น <span className="underline">วันที่ 1 ของเดือนที่เลือก</span> โดยอัตโนมัติ</p>} className="w-content">
                <QuestionCircleTwoTone className="cursor-pointer" />
              </Popover>
              {dateOfBirth.date && dateOfBirth.month && dateOfBirth.year && <p className="mb-1 ml-2 opacity-80">(อายุ {handleCalculateAge(`${(Number(dateOfBirth.year) - 543).toString()}-${birthMonthForCalAge}-${birthDateForCalAge}T00:00:00.000Z`)} ปี)</p>}
            </div>
            <div className="flex md:flex-col md:gap-y-4 xl:flex-row xl:gap-x-3">
              <Select
                bordered={false}
                placeholder="วันที่"
                className="items-center w-full text-base border-2 outline-none 2xl:w-full xl:rounded-tr-lg xl:rounded-br-lg md:rounded-tr-3xl md:rounded-br-3xl rounded-bl-3xl rounded-tl-3xl"
                dropdownStyle={{ borderRadius: 20 }}
                value={!dateOfBirth.date ? undefined : dateOfBirth.date}
                options={dates.map((date) => ({ label: date.label, value: date.value }))}
                showSearch
                filterOption={(input, option) => (option?.label as string).toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0}
                onChange={(value) => setDateOfBirth({ ...dateOfBirth, date: value })}
                allowClear
              />
              <Select
                bordered={false}
                placeholder="เดือน"
                className="items-center w-full text-base border-2 xl:rounded-md md:rounded-3xl 2xl:w-full"
                dropdownStyle={{ borderRadius: 20 }}
                value={!dateOfBirth.month ? undefined : dateOfBirth.month}
                options={months.map((month) => ({ label: month.label, value: month.value }))}
                showSearch
                filterOption={(input, option) => (option?.label as string).toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0}
                onChange={(value) => setDateOfBirth({ ...dateOfBirth, month: value })}
                allowClear
              />
              <Select
                bordered={false}
                placeholder="ปี"
                className="items-center w-full text-base border-2 outline-none xl:rounded-tl-lg 2xl:w-full xl:rounded-bl-lg md:rounded-3xl xl:rounded-tr-3xl xl:rounded-br-3xl"
                dropdownStyle={{ borderRadius: 20 }}
                value={!dateOfBirth.year ? undefined : dateOfBirth.year}
                options={years.map((year) => ({ label: year, value: year }))}
                showSearch
                filterOption={(input, option) => (option?.label as string).toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0}
                onChange={(value) => setDateOfBirth({ ...dateOfBirth, year: value })}
                allowClear
              />
            </div>
            {errors.date_of_birth && touched.date_of_birth && <ErrorMsg>{errors.date_of_birth}</ErrorMsg>}
          </div>
          <div className="mb-2 text-base font-medium">
            <p className="mb-1">เพศ <span className="text-red-500">*</span></p>
            <Select
              id="gender"
              bordered={false}
              onBlur={handleBlur}
              disabled={!!id && !user?.user_info.is_super_admin}
              onChange={(value: string) => setFieldValue('gender', value)}
              value={values.gender}
              dropdownStyle={{ borderRadius: 20 }}
              className={`${!!id && !user?.user_info.is_super_admin && 'bg-gray-100 border-transparent'} items-center w-full text-base border-2 outline-none rounded-3xl`}
            >
              <Option value="male">ชาย</Option>
              <Option value="female">หญิง</Option>
            </Select>
            {errors.gender && touched.gender && <ErrorMsg>{errors.gender}</ErrorMsg>}
          </div>
        </div>
        <div className="flex flex-col justify-between flex-1 px-8">
          <div>
            <div className="mb-2 text-base font-medium mt-9">
              <p className="mb-1">ที่อยู่</p>
              <TextAreaInput
                autoSize={{ minRows: 4 }}
                id="raw_address"
                name="raw_address"
                onBlur={handleBlur}
                onChange={(e) => setFieldValue('raw_address', e.target.value)}
                value={values.raw_address}
              />
              {errors.raw_address && touched.raw_address && <ErrorMsg>{errors.raw_address}</ErrorMsg>}
            </div>
            <div className="mb-2 text-base font-medium">
              <p className="mb-1">Email</p>
              <InputBox
                id="email"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                type="text"
              />
              {errors.email && touched.email && <ErrorMsg>{errors.email}</ErrorMsg>}
            </div>
            <div className="mb-2 text-base font-medium">
              <p className="mb-1">เบอร์โทรศัพท์</p>
              <InputBox
                id="mobile_number"
                name="mobile_number"
                onBlur={handleBlur}
                onChange={(e) => {
                  if (/^\d{0,10}$/.test(e.target.value)) {
                    setFieldValue('mobile_number', e.target.value);
                  }
                }}
                value={values.mobile_number}
                type="text"
                maxLength={10}
              />
              {errors.mobile_number && touched.mobile_number && <ErrorMsg>{errors.mobile_number}</ErrorMsg>}
            </div>
            <div className="mb-2 text-base font-medium gap-x-1">
              <p className="mb-1">วันทำงานของแพทย์</p>
              <div className="grid md:grid-cols-2 lg:grid-cols-2 xl::grid-cols-3 2xl:grid-cols-4">
                <Checkbox
                  className="w-full my-1 ml-2"
                  name="unavailable_day[0]"
                  defaultChecked={data?.success && data.doctor && !data.doctor.unavailable_day.includes('Monday')}
                  onChange={() => handleSetWorkDay(0, 'Monday')}
                >
                  วันจันทร์
                </Checkbox>
                <Checkbox
                  className="w-full my-1 ml-2"
                  name="unavailable_day[1]"
                  defaultChecked={data?.success && data.doctor && !data.doctor.unavailable_day.includes('Tuesday')}
                  onChange={() => handleSetWorkDay(1, 'Tuesday')}
                >
                  วันอังคาร
                </Checkbox>
                <Checkbox
                  className="w-full my-1 ml-2"
                  name="unavailable_day[2]"
                  defaultChecked={data?.success && data.doctor && !data.doctor.unavailable_day.includes('Wednesday')}
                  onChange={() => handleSetWorkDay(2, 'Wednesday')}
                >
                  วันพุธ
                </Checkbox>
                <Checkbox
                  className="w-full my-1 ml-2"
                  name="unavailable_day[3]"
                  defaultChecked={data?.success && data.doctor && !data.doctor.unavailable_day.includes('Thursday')}
                  onChange={() => handleSetWorkDay(3, 'Thursday')}
                >
                  วันพฤหัสบดี
                </Checkbox>
                <Checkbox
                  className="w-full my-1 ml-2"
                  name="unavailable_day[4]"
                  defaultChecked={data?.success && data.doctor && !data.doctor.unavailable_day.includes('Friday')}
                  onChange={() => handleSetWorkDay(4, 'Friday')}
                >
                  วันศุกร์
                </Checkbox>
                <Checkbox
                  className="w-full my-1 ml-2"
                  name="unavailable_day[5]"
                  defaultChecked={data?.success && data.doctor && !data.doctor.unavailable_day.includes('Saturday')}
                  onChange={() => handleSetWorkDay(5, 'Saturday')}
                >
                  วันเสาร์
                </Checkbox>
                <Checkbox
                  className="w-full my-1 ml-2"
                  name="unavailable_day[6]"
                  defaultChecked={data?.success && data.doctor && !data.doctor.unavailable_day.includes('Sunday')}
                  onChange={() => handleSetWorkDay(6, 'Sunday')}
                >
                  วันอาทิตย์
                </Checkbox>
              </div>
            </div>
          </div>
          <div className="items-center mt-4 text-right align-middle lg:mt-0">
            <SaveButton
              onClick={() => {
                if (values.confirm_password?.length === 0) {
                  setErrorPasswordNotMatch(false);
                  setErrorEmptyConfirmPassword(true);
                } else {
                  setErrorPasswordNotMatch(false);
                  setErrorEmptyConfirmPassword(false);
                }
                if (values.confirm_password && values.confirm_password.length > 0 && values.confirm_password !== values.password) {
                  setErrorPasswordNotMatch(true);
                } else {
                  setErrorPasswordNotMatch(false);
                  handleSubmit();
                }
              }}
            >
              Save
            </SaveButton>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddNewDoctorForm;
