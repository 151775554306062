import { APIResponse, UserType } from '../../constant-enum-type';
import {
  TAppointment as _TAppointment,
  TMedicalNote,
  TMedicalNoteInitialValue,
  TModifiedAppointment,
  TNotificationAppointment,
} from '../../form-schema/appointments/AppointmentSchema';
import { BpModel } from '../../form-schema/blood-pressure/BpSchema';
import { BMIModel } from '../../form-schema/bmi/BMISchema';
import { TRescheduleAppointmentSchema } from '../../form-schema/reschedule-appointment/RescheduleAppointmentSchema';
import api from '../../utils/api';

export interface TSendNotificationMessageByAppointmentID {
  is_read: boolean;
  _id: string;
  patient_id: string;
  message: string;
  type: string;
}

export type TAppointment = _TAppointment;
export type CreateAppointmentResponse = APIResponse<{ success: boolean; appointment: TAppointment }>;
export type FetchAppointmentByAppointmentIDResponse = APIResponse<{ success: boolean; appointment: TAppointment }>;
export type EditResponses = APIResponse<{ success: boolean; data: TModifiedAppointment }>;
export type AllIncommingApointmentsResponses = APIResponse<{ success: boolean; appointments: TAppointment[] }>;
export type AllApointmentsResponses = APIResponse<{ success: boolean; appointments: TAppointment[] }>;
export type AppointmentsByID = APIResponse<{ success: boolean; appointments: TAppointment[] }>;
export type AppointmentsByIDResponse = APIResponse<{ success: boolean; appointments: TAppointment[] }>;
export type BMIByPatientIdResponse = APIResponse<{ success: boolean; bmis: BMIModel[] }>;
export type BpByPatientIdResponse = APIResponse<{ success: boolean; blood_pressures: BpModel[] }>;
export type DeleteAppointmentResponse = APIResponse<{ success: boolean; appointment: { message: 'Deleted Success' } }>;
export type ShiftAppointmentResponse = APIResponse<{ success: boolean; appointment: TAppointment }>;
export type MedicalNoteResponse = APIResponse<{ success: boolean; medical_note: TMedicalNote[] }>;
export type DeleteMedicalNoteResponse = APIResponse<{ success: boolean; medical_note: TMedicalNote }>;
export type NotificationAppointmentResponse = APIResponse<{ success: boolean; notification: TNotificationAppointment }>;
export type SendTelemedQueueResponse = APIResponse<{ success: boolean; notification: TSendNotificationMessageByAppointmentID }>;
export type DeleteBMIResponse = APIResponse<{ success: boolean }>;

export const createAppointment = (body: TModifiedAppointment): Promise<CreateAppointmentResponse> => {
  return api
    .post('/appointments', body)
    .then((res) => res.data);
};

export const fetchAppointmentByAppointmentID = (appointmentID: string): Promise<FetchAppointmentByAppointmentIDResponse> => {
  return api
    .get(`/appointments/detail/${appointmentID}`)
    .then((res) => res.data);
};

export const fetchIncomingAppointmentsByUserID = (id: string, userType: string): Promise<AllIncommingApointmentsResponses> => {
  let user = '';
  if (userType === UserType.Doctor) user = 'doctor';
  else user = 'patient';

  return api
    .get(`/appointments/${user}/incoming/${id}`)
    .then((res) => res.data);
};

export const fetchOldAppointmentsByUserID = (id: string, userType: string): Promise<AppointmentsByIDResponse> => {
  let user = '';
  if (userType === UserType.Doctor) user = 'doctor';
  else user = 'patient';

  return api
    .get(`/appointments/${user}/history/${id}`)
    .then((res) => res.data);
};

export const fetchAllAppointmentsByDoctorID = (id: string): Promise<AppointmentsByIDResponse> => {
  return api
    .get(`/appointments/doctor/${id}`)
    .then((res) => res.data);
};

export const editAppointment = (data: { id: string | undefined; body: TModifiedAppointment }): Promise<EditResponses> => {
  return api
    .put(`/appointments/${data.id}`, data.body)
    .then((res) => res.data);
};

export const fetchAllIncomingAppointments = (/* query?: { maxDate?: string } */): Promise<AllIncommingApointmentsResponses> => {
  return api
    .get('/appointments')
    // .get(`/appointments/me${query?.maxDate ? `?maxDate=${query.maxDate}` : ''}`)
    .then((res) => res.data);
};

export const fetchAllShiftAppointments = (/* query?: { maxDate?: string } */): Promise<AllIncommingApointmentsResponses> => {
  return api
    .get('/appointments/shifts')
    // .get(`/appointments/me${query?.maxDate ? `?maxDate=${query.maxDate}` : ''}`)
    .then((res) => res.data);
};

export const fetchAppointments = ({ datePointer, wingSize = 1 }: { datePointer?: string; wingSize?: number }): Promise<AllApointmentsResponses> => {
  return api
    .get(`/appointments/scope?${datePointer ? `datePointer=${datePointer}&` : ''}${wingSize ? `wingSize=${wingSize}` : ''}`)
    .then((res) => res.data);
};

export const deleteAppointmentByAppointmentID = (id: string): Promise<DeleteAppointmentResponse> => {
  return api
    .delete(`/appointments/${id}`)
    .then((res) => res.data);
};

export const deleteBMIById = (id: string): Promise<DeleteBMIResponse> => {
  return api
    .delete(`/health-history/${id}`)
    .then((res) => res.data);
};

export const confirmShiftAppointment = (data: { id: string; body: { answer: 'approved' | 'rejected' } & TModifiedAppointment }): Promise<ShiftAppointmentResponse> => {
  return api
    .put(`/appointments/confirm-request/${data.id}`, data.body)
    .then((res) => res.data);
};

export const fetchAllMedicalNote = (id: string, sortType: string): Promise<MedicalNoteResponse> => {
  return api
    .get(`/medical-note/patient/${id}?sort=${sortType}`)
    .then((res) => res.data);
};

export const createMedicalNote = (body: TMedicalNoteInitialValue): Promise<MedicalNoteResponse> => {
  return api
    .post('/medical-note', body)
    .then((res) => res.data);
};

export const editMedicalNote = (data: { noteID: string; body: TMedicalNoteInitialValue }): Promise<MedicalNoteResponse> => {
  return api
    .put(`/medical-note/${data.noteID}`, data.body)
    .then((res) => res.data);
};

export const deleteMedicalNote = (id: string): Promise<DeleteMedicalNoteResponse> => {
  return api
    .delete(`/medical-note/${id}`)
    .then((res) => res.data);
};

export const updateComeByScheduleStatus = (body: { appointmentId: string; value?: boolean }): Promise<APIResponse> => {
  return api
    .put(`/appointments/come-by-schedule/${body.appointmentId}`, { come_by_schedule: body.value })
    .then((res) => res.data);
};

export const checkAppointmentNotifyingStatus = (appointmentID: string): Promise<NotificationAppointmentResponse> => {
  return api
    .get(`/appointments/check-appointment-notifying-status/${appointmentID}`)
    .then((res) => res.data);
};

export const updateIsNotified = (body: { appointmentID: string; is_notified: boolean }): Promise<APIResponse> => {
  return api
    .put(`/appointments/is-notified/${body.appointmentID}`, { is_notified: body.is_notified })
    .then((res) => res.data);
};

export const sendTelemedQueue = (params: { appointmentID: string; resend?: string }): Promise<SendTelemedQueueResponse> => {
  if (params.resend === 'true') {
    return api
      .post(`/notification/appointment-queue/${params.appointmentID}?resend=true`)
      .then((res) => res.data);
  }
  return api
    .post(`/notification/appointment-queue/${params.appointmentID}`)
    .then((res) => res.data);
};

export const shiftAppointments = (body: Omit<TRescheduleAppointmentSchema, 'doctor_id'>): Promise<APIResponse> => {
  return api
    .put('/appointments/shift-appointments', body)
    .then((res) => res.data);
};

export const fetchBMIsByPatientId = (id: string): Promise<BMIByPatientIdResponse> => {
  return api
    .get(`/health-history/${id}/bmi`)
    .then((res) => res.data);
};

export const fetchBpsByPatientId = (id: string): Promise<BpByPatientIdResponse> => {
  return api
    .get(`/health-history/${id}/blood-pressure`)
    .then((res) => res.data);
};
