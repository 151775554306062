import React from 'react';
import { useParams } from 'react-router';
import PageTitle from '../../features/common/components/PageTitle';
import AddNewNurseForm from '../../features/common/components/form/AddNewNurseForm';

const AddNewNurse: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  return (
    <div>
      <PageTitle title={id ? 'Edit Nurse Detail' : 'Add New Nurse'} backTo={id ? `/nurse/${id}` : '/nurses'} />
      <div className="w-full p-6 mt-6 bg-white rounded-3xl">
        <AddNewNurseForm />
      </div>
    </div>
  );
};

export default AddNewNurse;
