/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useQuery } from 'react-query';
import { fetchPatientsRecentChat } from '../api-action';

const useFetchPatientsRecentChat = (userID: string | undefined, limit = 20) => {
  return useQuery(
    ['patients_recent_chat', userID],
    () => fetchPatientsRecentChat(limit),
    { refetchOnWindowFocus: false },
  );
};

export default useFetchPatientsRecentChat;
