/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useQuery } from 'react-query';
import { fetchPatientByID } from '../api-action';

const useFetchPatientByID = (id: string) => {
  return useQuery(
    ['patient_by_id', id],
    () => fetchPatientByID(id),
    { refetchOnWindowFocus: false },
  );
};

export default useFetchPatientByID;
