/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import Select from 'antd/es/select';
import { useQueryClient } from 'react-query';
import { FormikProps } from 'formik';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import useFetchDoctors from '../../connecting-api/doctor/hooks/use-fetch-doctors';
import { TModifiedAppointment } from '../../form-schema/appointments/AppointmentSchema';
import { TDoctor } from '../../form-schema/doctors/DoctorSchema';
import { UserSchema } from '../../form-schema/user/UserSchema';

interface ISelectDoctorsComponentProps {
  disabled?: boolean;
  formik: FormikProps<TModifiedAppointment>;
  onDoctorIsChanged?: (doctor?: TDoctor) => void;
}

const SelectDoctorsComponent: React.FC<ISelectDoctorsComponentProps> = (props) => {
  const queryClient = useQueryClient();
  const queryData = queryClient.getQueryData<{ success: boolean; user_info: UserSchema }>(['user']);
  const user = queryData;

  const { formik, disabled, onDoctorIsChanged } = props;
  const memoizedDoctorId = useRef(formik.values.doctor_id);
  const doctorsQuery = useFetchDoctors(user?.user_info._id);

  useEffect(() => {
    if (onDoctorIsChanged && memoizedDoctorId.current !== formik.values.doctor_id) {
      const selectedDoctorData = doctorsQuery.data?.success ? doctorsQuery.data.doctor?.find((d) => d._id === formik.values.doctor_id) : undefined;
      onDoctorIsChanged(selectedDoctorData);
    }
    memoizedDoctorId.current = formik.values.doctor_id;
  }, [formik.values.doctor_id]);

  if (doctorsQuery.status !== 'success' || !doctorsQuery.data.success) return <div><LoadingOutlined /></div>;

  return (
    <Select
      id="doctor"
      bordered={false}
      disabled={disabled}
      className={`${!!disabled && 'bg-gray-100 border-transparent'} items-center w-full text-base border-2 outline-none rounded-3xl`}
      showSearch
      dropdownStyle={{ borderRadius: 20 }}
      suffixIcon={<SearchOutlined className="text-base" />}
      value={formik.values.doctor_id === '' ? '' : formik.values.doctor_id}
      notFoundContent={<p className="text-sm opacity-60">ไม่มีข้อมูล</p>}
      filterOption={(input, option) => (option?.label as string).toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0}
      onChange={(doctorId) => formik.setFieldValue('doctor_id', doctorId)}
      options={doctorsQuery.data.doctor.map((doctor) => ({ label: `${doctor.first_name} ${doctor.last_name}`, value: doctor._id }))}
    />
  );
};

export default SelectDoctorsComponent;
