import {
  XrayNotification,
} from '../../../xray/schema/XrayNotification';
import { APIResponse } from '../../constant-enum-type';
import api from '../../utils/api';

export type XrayNotificationResponse = APIResponse<{ notifications: XrayNotification[]; total_size: number }>;

export const fetchXrayNotifications = (skip = 0, limit = 10, searchKey = '', type: 'upcoming' | 'history' = 'upcoming', sort: 'asc' | 'desc'): Promise<XrayNotificationResponse> => {
  let stringAPI = '';
  const isRead = type === 'history';
  if (searchKey) {
    stringAPI = `/xray-notifications/search?skip=${skip}&limit=${limit}&is_read=${isRead}&sort=${sort}&search=${searchKey}`;
  } else {
    stringAPI = `/xray-notifications/search?skip=${skip}&limit=${limit}&is_read=${isRead}&sort=${sort}`;
  }

  return api
    .get(stringAPI)
    .then((res) => res.data);
};

export const fetchNotificationCount = (): Promise<APIResponse<{ count: number }>> => {
  return api.get('/xray-notifications/count')
    .then((res) => res.data);
};

export const updateReadXrayNotification = (id: string): Promise<APIResponse> => {
  return api
    .put(`/xray-notifications/${id}`, { is_read: true })
    .then((res) => res.data);
};
