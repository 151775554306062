/* eslint-disable react/require-default-props */
/* eslint-disable no-multi-str */
import React, { useState } from 'react';
import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/image';
import 'tinymce/plugins/table';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';
import { Editor } from '@tinymce/tinymce-react';

interface Props {
  disabled?: boolean;
  height?:string;
  inputValue: string | undefined;
  handleChangeValue: (value: string) => void;

}
const TinyMECInput: React.FC<Props> = (props) => {
  const handleEditorChange = (content: string, editor: any) => {
    props.handleChangeValue(content);
  };

  return (
    <div className={`${props.disabled ? 'pointer-events-none cursor-not-allowed' : null}`}>
      <Editor
        init={{
          skin: 'naked',
          content_css: 'light',
          height: `${props.height ? props.height : 350}`,
          menubar: false,
          placeholder: 'กรอกข้อมูลที่เกี่ยวข้อง',
          plugins: [
            'link image',
            'table paste',
            'lists',
          ],
          contextmenu: false,
          branding: true,
          statusbar: true,
          toolbar: 'undo redo | bold italic underline strikethrough  | outdent indent |  bullist | forecolor backcolor removeformat | ltr rtl',
        }}
        value={props.inputValue}
        onEditorChange={handleEditorChange}
      />
    </div>
  );
};

export default TinyMECInput;
