import React from 'react';
import ELearningNotification from './ELearningNotification';
import SendNotification from './SendNotification';

const Notifications: React.FC = () => {
  return (
    <div className="h-auto min-h-96">
      <ELearningNotification />
      <SendNotification />
    </div>
  );
};

export default Notifications;
