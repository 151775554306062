/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { Radio } from 'antd';
import { TPersonalNoteTemplate } from '../PersonalNoteModal';

export type PersonalNoteCirrhosisType = {
  radioSelected: string;
};

export const personalNoteCirrhosisNewState = {
  radioSelected: 'none_cirrhosis',
};

const PersonalNoteCirrhosis: React.FC<{
  personalNote: TPersonalNoteTemplate;
  value: PersonalNoteCirrhosisType | null;
  onHandleChange: (_value: PersonalNoteCirrhosisType) => void;
}> = ({ personalNote, value, onHandleChange }) => {
  const {
    name,
    sub_name: subName,
    options,
    items,
    defaultValue,
  } = personalNote;

  const [state, setState] = useState<PersonalNoteCirrhosisType>(
    (value && value?.radioSelected !== '') ? value : {
      radioSelected: defaultValue ?? '',
    },
  );

  useEffect(() => {
    onHandleChange(state);
  }, [state, setState]);

  const onChangeRadio = (_value: string) => {
    setState({
      ...state,
      radioSelected: _value,
    });
  };

  return (
    <div className="pb-4">
      <p className="font-bold pb-2">{name}</p>
      {subName && <p className="text-sm font-thin">{subName}</p>}
      <Radio.Group
        className={`${items !== undefined ? 'pb-4' : ''}`}
        onChange={(e) => onChangeRadio(e.target.value)}
        value={state.radioSelected}
      >
        {options?.map((option) => (
          <Radio key={option.key} value={option.key}>
            {option.name}
          </Radio>
        ))}
      </Radio.Group>
    </div>
  );
};

export default PersonalNoteCirrhosis;
