/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { Checkbox, Dropdown } from 'antd';
import { TPersonalNoteTemplate } from '../../PersonalNoteModal';

export type PersonalNoteDropdownType = {
  isChecked: boolean;
  selected: string | null;
};

export const personalNoteDropdownNewState: PersonalNoteDropdownType = {
  isChecked: false,
  selected: null,
};

const PersonalNoteDropdown: React.FC<{
  personalNote: TPersonalNoteTemplate;
  value: PersonalNoteDropdownType | null;
  onHandleChange: (value: PersonalNoteDropdownType) => void;
}> = ({ personalNote, value, onHandleChange }) => {
  const { name, options } = personalNote;

  const [state, setState] = useState<PersonalNoteDropdownType>(
    value ?? personalNoteDropdownNewState,
  );

  useEffect(() => {
    onHandleChange(state);
  }, [state, setState]);

  useEffect(() => {
    setState(value ?? personalNoteDropdownNewState);
  }, [value]);

  const onChangeCheckbox = (checked: boolean) => {
    setState({
      ...state,
      isChecked: checked,
      selected: checked ? state.selected : null,
    });
  };

  const onChangeSelected = (selected: string) => {
    setState({
      ...state,
      selected,
      isChecked: true,
    });
  };

  return (
    <div className="p-4 border-b" style={{ background: '#F8F8F8' }}>
      <div className="flex">
        <div className="flex flex-grow">
          <Checkbox
            name={name}
            className="pr-4"
            checked={state.isChecked}
            onChange={(e) => onChangeCheckbox(e.target.checked)}
          />
          <p>{name}</p>
        </div>
      </div>
      <Dropdown
        className="border rounded-full py-2 px-4 mt-4 w-80 h-10 bg-white"
        trigger={['click']}
        menu={{
          className: 'rounded-xl',
          items:
            options?.map((option) => {
              return {
                key: option.key,
                label: option.name,
                onClick: () => {
                  onChangeSelected(option.name);
                },
              };
            }) ?? [],
        }}
      >
        <div className="flex">
          <div className="flex flex-grow">{state.selected}</div>
          <div className="flex flex-grow-0 w-4">
            <img src="/icons/arrow_down.svg" alt="arrow down" />
          </div>
        </div>
      </Dropdown>
    </div>
  );
};

export default PersonalNoteDropdown;
