/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Link } from 'react-router-dom';
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import { BackButton } from './Button';

interface IProps {
  title: string;
  backTo?: string;
  currentShow?: string;
  doctorName?: string;
  rightButtonAction?: {
    text: string;
    handleClick: () => void;
    isFetchPatientPatientSummaryError: boolean;
  };
}

const PageTitle: React.FC<IProps> = (props) => {
  const {
    title,
    backTo,
    doctorName,
    rightButtonAction,
  } = props;

  return (
    <div className="sticky flex items-center p-2 bg-white border-gray-200 xl:py-5 rounded-3xl">
      <div>
        {backTo && (
          <Link to={backTo} className="flex flex-row items-center gap-1 w-content hover:text-gray-400">
            <BackButton>
              <LeftOutlined className="text-lg leading-3" />
              <p className="text-base">{backTo === '/appointments' ? 'Back to Appointments' : 'Back'}</p>
            </BackButton>
          </Link>
        )}
      </div>
      <p className="ml-4 text-xl font-medium md:text-base lg:text-lg xl:text-xl text-primary">
        {title} {doctorName && ` : ${doctorName}`}
      </p>
      {rightButtonAction && (
        <>
          {rightButtonAction.isFetchPatientPatientSummaryError ? (
            <div
              className="items-center px-4 py-1 ml-auto text-base border rounded-3xl border-gray-8 text-gray-8 bg-gray-50"
            >
              Unable to fetch Patient Summary, <span className="underline cursor-pointer text-primary" onClick={() => window.location.reload()}>Try again</span>
            </div>
          ) : (
            <div
              className="items-center px-4 py-1 ml-auto text-base border cursor-pointer rounded-3xl"
              style={{ background: '#fff1e8', color: '#fa541c', borderColor: '#fa541c' }}
              onClick={() => rightButtonAction.handleClick()}
            >
              {rightButtonAction?.text}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PageTitle;
