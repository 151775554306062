import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import moment from 'moment';
import Loading from '../common/components/Loading';
import { TUserView } from '../common/form-schema/user/UserSchema';
import { DeleteBpButton } from '../common/components/Button';
import ConfirmDelete from '../common/components/modals/ConfirmDeleteModal';
import useDeleteBMIById from '../common/connecting-api/appointment/hooks/use-delete-bmi-by-id';
import { FailedModal, SuccessModal } from '../common/components/modals/CommonAlertModal';
import useFetchBpsByPatientId from '../common/connecting-api/appointment/hooks/use-fetch-bp-by-patient_id';
import { TableBpModel } from '../common/form-schema/blood-pressure/BpTableSchema';

const columns: ColumnsType<TableBpModel> = [
  {
    title: 'HBPM (mmHg)',
    dataIndex: 'bp',
    key: 'bp',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  }, {
    title: 'Edit By',
    dataIndex: 'editBy',
    key: 'editBy',
  },
];

type Props = {
  patientID: string;
};

const BpHistories: React.FC<Props> = ({ patientID }) => {
  const { status, data, refetch } = useFetchBpsByPatientId(patientID);
  const [selectedBpId, setSelectedBpId] = useState<string | null>(null);
  const [isShowModal, setShowModal] = useState<boolean>(false);
  const { mutate: removeBMIById } = useDeleteBMIById();

  useEffect(() => {
    if (columns.find((col) => col.title === 'Action') === undefined) {
      columns.push({
        title: 'Action',
        key: 'action',
        render: (_: any, record: TableBpModel) => {
          return (
            <DeleteBpButton
              onClick={() => {
                setSelectedBpId(record.key);
                setShowModal(true);
              }}
            >
              Delete
            </DeleteBpButton>
          );
        },
      });
    }
  }, []);

  if (status !== 'success' || !data.success) return <div><Loading /></div>;

  const bps = data.blood_pressures.sort((a, b) => {
    return moment(a.created_at).unix() - moment(b.created_at).unix();
  });

  const tableData: TableBpModel[] = bps.map((_bp) => {
    const editBy = (_bp.edit_by as TUserView);
    const date = moment(_bp.created_at).year(moment().year() + 543);
    return {
      key: _bp._id,
      bp: `${_bp.value.sbp} / ${_bp.value.dbp}`,
      date: moment(date).format('DD MMMM yyyy'),
      editBy: `${editBy.name_title} ${editBy.first_name} ${editBy.last_name}`,
    };
  });

  return (
    <div className="w-full">
      <ConfirmDelete
        id={selectedBpId ?? ''}
        isShuttingDownModal={isShowModal}
        onCancel={() => setShowModal(false)}
        onOk={async () => {
          removeBMIById(selectedBpId ?? '', {
            onSuccess: () => {
              setShowModal(false);
              refetch();
              const successModal = SuccessModal('ลบข้อมูลสำเร็จ');
              setTimeout(() => {
                successModal.destroy();
              }, 4000);
            },
            onError: () => {
              setShowModal(false);
              FailedModal('ลบข้อมูลไม่สำเร็จ');
            },
          });
        }}
      />
      <Table columns={columns} dataSource={tableData} />
    </div>
  );
};

export default BpHistories;
