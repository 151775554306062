/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useQuery } from 'react-query';
import { fetchNurseByID } from '../api-action';

const useFetchNurseByID = (id: string) => {
  return useQuery(
    ['nurse_by_id', id],
    () => fetchNurseByID(id),
    { refetchOnWindowFocus: false },
  );
};

export default useFetchNurseByID;
