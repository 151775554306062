import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import React from 'react';
import styled from 'styled-components';

const CenterLoading = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  margin: auto;
`;

const Loading: React.FC = () => {
  return (
    <CenterLoading>
      <LoadingOutlined className="text-4xl opacity-70" />
    </CenterLoading>
  );
};

export default Loading;
