/* eslint-disable react/require-default-props */
/* eslint-disable react/no-danger */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import Modal from 'antd/lib/modal/Modal';
import styled from 'styled-components';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import FormOutlined from '@ant-design/icons/FormOutlined';
import VideoCameraOutlined from '@ant-design/icons/VideoCameraOutlined';

import {
  Radio,
  Row,
} from 'antd';
import moment from 'moment-timezone';

import EditAppointmentDiagnosisModal from './EditAppointmentDiagnosisModal';
import { AppointmentTypeBadge } from './AppointmentTypes';
import { TAppointment } from '../../common/form-schema/appointments/AppointmentSchema';
import useUpdateComeBySchedule from '../../common/connecting-api/appointment/hooks/use-update-come-by-schedule';
import { UserSchema } from '../../common/form-schema/user/UserSchema';
import { teleconferenceLocalStorage } from '../../common/utils/local-storage';
import { AppointmentType, UserType } from '../../common/constant-enum-type';
import { EditAppointmentButton, StartVideoCallButton } from '../../common/components/Button';
import useWindowDimensions from '../../common/use-window-dimensions';
import PersonCardOnAppointment from './PersonCardOnAppointment';
import TinyMECShowDetail from '../../common/components/TinyMECShowDetail';

const RowDisplay = styled.div`
  display: flex;
  flex-direction: column;
`;

const ELearningLink = styled.span`
  a {
  color: #1890FF;
  }
`;

interface IAppointmentDiagnosisModalProps {
  isModalVisible: boolean;
  handleClickHideModal: () => void;
  handleClickShowAppointmentModal: () => void;
  handleClickEditAppointmet: () => void;

  appointment: TAppointment;
  isFromAppointmentHistories?: boolean;

  handleGoToAppointmentDate?: (date: moment.Moment) => void;
  handleEditModalActive?: (status: boolean) => void;
}

const AppointmentDiagnosisModal: React.FC<IAppointmentDiagnosisModalProps> = (props) => {
  const {
    appointment,
    isModalVisible,
    handleClickHideModal,
    handleClickShowAppointmentModal,
    handleClickEditAppointmet,
    isFromAppointmentHistories,
    handleEditModalActive,
    handleGoToAppointmentDate,
  } = props;

  const { height } = useWindowDimensions();

  const queryClient = useQueryClient();
  const [comeBySchedule, setComeBySchedule] = useState<boolean | undefined>(appointment.come_by_schedule);
  const { mutate: updateComeBySchedule } = useUpdateComeBySchedule();
  const onUpdateComeBySchedule = async (value?: boolean) => {
    updateComeBySchedule({ appointmentId: appointment._id, value }, {
      onSuccess: (res) => {
        if (res.success) {
          setComeBySchedule(value);
          queryClient.invalidateQueries('get_appointments');
        }
      },
    });
  };
  const [isButtonTeleconferenceActive, setIsButtonTeleconferenceActive] = useState(false);
  const [isOpenEditAppointmentModal, setIsOpenEditAppointmentModal] = useState(false);
  const [bloodTest, setBloodTest] = useState('');

  const userQuery = queryClient.getQueryData<{ success: boolean; user_info: UserSchema }>(['user']);

  const handleCancelEditModal = () => {
    setIsOpenEditAppointmentModal(false);
  };

  const handleOpenEditAppointmentModal = () => {
    setIsOpenEditAppointmentModal(true);
    handleClickHideModal();
  };

  const handleToAppointmentInfo = () => {
    setIsOpenEditAppointmentModal(false);
    handleClickShowAppointmentModal();
  };

  useEffect(() => {
    setComeBySchedule(appointment.come_by_schedule);
  }, [appointment]);

  useEffect(() => {
    if (appointment.blood_test === 'morning') {
      setBloodTest('ช่วงเช้าก่อนพบแพทย์');
    } else if (appointment.blood_test === 'before_two_weeks') {
      setBloodTest('อย่างน้อย 2 สัปดาห์');
    } else if (appointment.blood_test === 'before_one_week') {
      setBloodTest('อย่างน้อย 1 สัปดาห์');
    } else {
      setBloodTest('ไม่ต้องตรวจเลือด');
    }
  }, [appointment.blood_test, isOpenEditAppointmentModal]);

  useEffect(() => {
    if (handleEditModalActive) {
      handleEditModalActive(!isOpenEditAppointmentModal);
    }
  }, [isOpenEditAppointmentModal]);

  useEffect(() => {
    if (!teleconferenceLocalStorage.used()
      && (moment(appointment.appointment_date).diff(moment()) < 0)
      && (userQuery?.user_info.user_type === UserType.Doctor || userQuery?.user_info.user_type === UserType.Nurse)) {
      setIsButtonTeleconferenceActive(true);
    } else {
      setIsButtonTeleconferenceActive(false);
    }
    const checkButtonActive = setInterval(() => {
      if (!teleconferenceLocalStorage.used()
        && (moment(appointment.appointment_date).diff(moment()) < 0)
        && (userQuery?.user_info.user_type === UserType.Doctor || userQuery?.user_info.user_type === UserType.Nurse)) {
        setIsButtonTeleconferenceActive(true);
      } else {
        setIsButtonTeleconferenceActive(false);
      }
    }, 0);
    return () => {
      clearInterval(checkButtonActive);
    };
  }, [appointment, isButtonTeleconferenceActive]);

  return (
    <div>
      <Modal
        title={(
          <Row align="middle" className="flex flex-row align-middle">
            <p className="mr-3 text-xl font-medium" style={{ color: appointment.appointment_type === AppointmentType.onsite ? '#1E73BE' : '#ED6024' }}>
              {moment(appointment.appointment_date).add(543, 'year').format('LL')}
            </p>
            {userQuery?.user_info.user_type === UserType.Doctor && appointment.appointment_type === AppointmentType.online && <p className="opacity-80">เวลา {moment(appointment.appointment_date).add(543, 'year').format('LT')} น.</p>}
            {isFromAppointmentHistories
              || userQuery?.user_info.user_type === UserType.Doctor
              ? ''
              : (
                <EditAppointmentButton onClick={handleOpenEditAppointmentModal} className="hover:bg-blue-400 hover:text-white hover:border-transparent">
                  <FormOutlined />
                  <p>Edit Appointment Detail</p>
                </EditAppointmentButton>
              )}
          </Row>
        )}
        open={isModalVisible}
        centered
        width="80%"
        onCancel={handleClickHideModal}
        closeIcon={<CloseOutlined className="outline-none" />}
        footer={false}
      >
        <div className="flex flex-col overflow-y-scroll" style={{ maxHeight: height - 230 }}>
          {appointment.is_ultrasound
            && (
              <Row className="mb-3">
                <div className="w-full px-2 py-1 text-base text-center text-white bg-red-500 rounded-2xl">
                  <p>ผู้รับบริการต้องไป Ultrasound ก่อนพบแพทย์</p>
                </div>
              </Row>
            )}
          <div className="grid gap-4 mb-4 sm:grid-cols-1 lg:grid-cols-2">
            <div className="w-full h-full">
              <p className="mb-1 text-base font-medium">ผู้รับบริการ</p>
              <PersonCardOnAppointment personInfo={appointment.patient_id} linkTo={`/patient/${appointment.patient_id._id}/${appointment.appointment_date}`} />
            </div>
            {userQuery?.user_info.user_type !== UserType.Doctor
              && (
                <div className="w-full h-full">
                  <p className="mb-1 text-base font-medium">แพทย์</p>
                  <PersonCardOnAppointment personInfo={appointment.doctor_id} linkTo={`/doctor/${appointment.doctor_id._id}/${appointment.appointment_date}`} />
                </div>
              )}
          </div>
          <RowDisplay>
            <p className="text-base font-medium">ประเภทการนัดหมาย</p>
            <Row className="mb-3">
              <AppointmentTypeBadge appointmentType={appointment.appointment_type} />
            </Row>
          </RowDisplay>
          {/* {appointment.doctor_note.note
            && (
              <div className="mb-3 text-base">
                <p className="text-base font-medium">Doctor Note</p>
                <span className="text-base opacity-90">
                  <TinyMECShowDetail details={appointment.doctor_note.note} />
                </span>
              </div>
            )} */}
          {appointment.nurse_note
            && (
              <div className="mb-3 text-base">
                <p className="text-base font-medium">Nurse Note</p>
                <span className="text-base opacity-90">
                  <TinyMECShowDetail details={appointment.nurse_note} />
                </span>
              </div>
            )}
          {appointment.appointment_type === AppointmentType.onsite && (
            <RowDisplay>
              <p className="text-base font-medium">การตรวจเลือด</p>
              <p className="mb-3 text-base opacity-90">{bloodTest}</p>
            </RowDisplay>
          )}
          <RowDisplay className="mb-2">
            {appointment.e_learning.length > 0 && <p className="text-base font-medium">E-Learning</p>}
            {appointment.e_learning.length > 0
              && (
                appointment.e_learning.map((value: string) => {
                  return (
                    <div key={value} className="flex flex-row text-base font-normal opacity-90">
                      <span>-&nbsp;</span>
                      <ELearningLink dangerouslySetInnerHTML={{ __html: value }} />
                    </div>
                  );
                })
              )}
          </RowDisplay>
          <RowDisplay className="mb-2">
            <p className="text-base font-medium">มาพบแพทย์</p>
            <Radio.Group
              value={comeBySchedule}
              onChange={(e) => onUpdateComeBySchedule(e.target.value)}
              disabled={userQuery?.user_info.user_type === UserType.Doctor}
            >
              <Radio value>มา</Radio>
              <Radio value={false}>ไม่มาพบแพทย์</Radio>
              <Radio value={undefined}>ไม่มีสถานะ</Radio>
            </Radio.Group>
          </RowDisplay>
          {appointment.appointment_type === AppointmentType.online
            && (
              userQuery?.user_info.user_type === UserType.Doctor || userQuery?.user_info.user_type === UserType.Nurse ? (
                <Row className="flex flex-row gap-2 mt-2">
                  <StartVideoCallButton
                    className={`outline-none ${isButtonTeleconferenceActive && moment(new Date()).startOf('day').diff(moment(appointment.appointment_date).startOf('day')) === 0 ? 'bg-orange text-white hover:bg-white hover:text-orange hover:border-orange' : 'bg-gray-100 text-gray-400'}`}
                    onClick={() => {
                      if (isButtonTeleconferenceActive && moment(new Date()).startOf('day').diff(moment(appointment.appointment_date).startOf('day')) === 0) {
                        teleconferenceLocalStorage.set();
                        setIsButtonTeleconferenceActive(false);
                        if (userQuery.success && userQuery.user_info.user_type === UserType.Doctor) {
                          window.open(`/doctor/tele/${appointment._id}/${appointment.patient_id._id}`);
                        } else if (userQuery.success && userQuery.user_info.user_type === UserType.Nurse) {
                          window.open(`/nurse/tele/${appointment._id}/${appointment.patient_id._id}`);
                        }
                      }
                    }}
                    disabled={isButtonTeleconferenceActive === false || moment(new Date()).startOf('day').diff(moment(appointment.appointment_date).startOf('day')) !== 0}
                  >
                    <VideoCameraOutlined />
                    <p>{isButtonTeleconferenceActive === true ? 'Start Video Calling' : 'Not Availabel Yet'}</p>
                  </StartVideoCallButton>
                </Row>
              ) : null
            )}
        </div>
      </Modal>
      {
        isOpenEditAppointmentModal
          ? (
            <EditAppointmentDiagnosisModal
              appointment={appointment}
              isOpenEditAppointmentModal={isOpenEditAppointmentModal}
              handleCancelEditModal={handleCancelEditModal}
              handleToAppointmentInfo={handleToAppointmentInfo}
              handleGoToAppointmentDate={handleGoToAppointmentDate}
            />
          ) : null
      }
    </div>
  );
};

export default AppointmentDiagnosisModal;
