/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

type Props = {
  onUploadPdfFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const AddAttachFile: React.FC<Props> = ({ onUploadPdfFile }) => {
  return (
    <div className="w-full">
      <label
        htmlFor="pdf-input"
        className="cursor-pointer font-bold text-base border rounded-full py-1 flex justify-center w-full"
      >
        + เพิ่มไฟล์เอกสาร
      </label>
      <input
        id="pdf-input"
        type="file"
        className="hidden"
        accept=".pdf"
        onChange={onUploadPdfFile}
      />
    </div>
  );
};

export default AddAttachFile;
