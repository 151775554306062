/* eslint-disable import/prefer-default-export */
import moment from 'moment-timezone';
import * as Yup from 'yup';

export interface TDoctor {
  _id: string;
  avatar_img_url: string;
  first_name: string;
  last_name: string;
  username: string;
  hospital_id: string;
  email: string;
  mobile_number: string;
  date_of_birth: string;
  citizen_id: string;
  gender: string;
  name_title: string;
  raw_address: string;
  unavailable_day: string[];
  user_type: string;
}

export interface TDoctorEdit {
  avatar_img_url?: string;
  name_title?: string;
  first_name?: string;
  last_name?: string;
  mobile_number?: string;
  email?: string;
  raw_address?: string;
  unavailable_day?: string[];
  date_of_birth?: string;
  citizen_id?: string;
  gender?: string;
}

export interface TDoctorInitialValue {
  _id?: string;
  avatar_img_url: string;
  name_title: string;
  first_name: string;
  last_name: string;
  username: string;
  password: string;
  confirm_password?: string;
  hospital_id: string;
  mobile_number: string;
  email?: string;
  date_of_birth: string;
  citizen_id: string;
  gender: string;
  raw_address: string;
  unavailable_day: string[];
}

const initialValues: TDoctorInitialValue = {
  _id: '',
  avatar_img_url: '',
  first_name: '',
  last_name: '',
  username: '',
  password: '',
  confirm_password: '',
  hospital_id: '',
  mobile_number: '',
  email: '',
  date_of_birth: moment(new Date()).toISOString(),
  citizen_id: '',
  gender: '',
  name_title: '',
  raw_address: '',
  unavailable_day: ['', '', '', '', '', '', ''],
};

export const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .required('กรุณากรอกชื่อแพทย์'),
  last_name: Yup.string()
    .required('กรุณากรอกนามสกุลแพทย์'),
  username: Yup.string()
    .required('กรุณากรอกชื่อผู้ใช้'),
  password: Yup.string()
    .when('_id', {
      is: '' || undefined,
      then: Yup
        .string()
        .min(8, 'รหัสผ่านต้องมีขนาด อย่างน้อย 8 ตัวอักษร')
        .required('กรุณากรอกรหัสผ่าน'),
      otherwise: Yup.string().nullable(),
    }),
  confirm_password: Yup.string()
    .when('_id', {
      is: '' || undefined,
      then: Yup
        .string()
        .oneOf([Yup.ref('password')], 'รหัสผ่านไม่ตรงกัน')
        .required('กรุณายืนยันรหัสผ่าน'),
      otherwise: Yup.string().nullable(),
    }),
  email: Yup.string()
    .email('รูปแบบ Email ไม่ถูกต้อง'),
  mobile_number: Yup.number()
    .typeError('กรุณากรอกเฉพาะตัวเลข'),
  citizen_id: Yup.number()
    .typeError('กรุณากรอกเฉพาะตัวเลข'),
  gender: Yup.string()
    .required('กรุณาเลือกเพศ'),
});

export default initialValues;
