import React from 'react';
import { PersonalNoteUDType } from '../../../common/components/modals/PersonalNoteModal/type/PersonalNoteUD/PersonalNoteUD';
import PersonalNoteChronicHBVResult from './PersonalNoteChronicHBV';
import PersonalNoteChronicHCVResult from './PersonalNoteChronicHCV';

const PersonalNoteUDResult: React.FC<{
  value: PersonalNoteUDType;
}> = ({ value }) => {
  if (
    !value.autoimmuneHepatitis.isChecked
    && !value.chronicHBV.isChecked
    && !value.chronicHCV.isChecked
    && !value.fattyLiver.isChecked
    && !value.others.isChecked
  ) {
    return <div />;
  }

  return (
    <div className="'flex flex-col pt-2 border-b">
      <p className="text-primary font-bold text-lg pb-2">U/D</p>
      <PersonalNoteChronicHBVResult value={value.chronicHBV} />
      <PersonalNoteChronicHCVResult value={value.chronicHCV} />
      {value.fattyLiver.isChecked && (
        <div className="pb-4">
          <p className="text-base font-bold">Fatty liver</p>
          <p className="text-base text-gray-500">
            {value.fattyLiver.isChecked ? 'Yes' : 'No'}
          </p>
        </div>
      )}
      {value.autoimmuneHepatitis.isChecked && (
        <div className="pb-4">
          <p className="text-base font-bold">Autoimmune hepatitis</p>
          <p className="text-base text-gray-500">
            {value.autoimmuneHepatitis.isChecked ? 'Yes' : 'No'}
          </p>
        </div>
      )}
      {value.others.isChecked && (
        <div className="pb-4">
          <p className="text-base font-bold">Others</p>
          <p className="text-base text-gray-500 break-words">
            {value.others.text !== '' ? value.others.text : '-'}
          </p>
        </div>
      )}
    </div>
  );
};

export default PersonalNoteUDResult;
