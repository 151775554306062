/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';
import styled from 'styled-components';
import EditFilled from '@ant-design/icons/EditFilled';
import UserOutlined from '@ant-design/icons/UserOutlined';
import Avatar from 'antd/lib/avatar/avatar';
import {
  Row, Col, Typography,
} from 'antd';
import { useQueryClient } from 'react-query';
import { NavLink } from 'react-router-dom';
import { HeadParagrahp, SubHeadParagrahp } from '../common/components/Lable';
import config from '../../config';
import { TOfficer } from '../common/form-schema/officers/OfficerSchema';
import { UserSchema } from '../common/form-schema/user/UserSchema';
import { UserType } from '../common/constant-enum-type';
import { EditUserInfoButton } from '../common/components/Button';
import { handleCalculateAge, handleFormatMobileNumber, handleRenderDateOfBirth } from '../common/all-about-string-method';
import useWindowDimensions from '../common/use-window-dimensions';

const { Text } = Typography;
const baseURL = config.apiPath;

interface IOfficerBasicInfoProps {
  officer: TOfficer;
}

const ThreeCols = styled(Row)`
  @media(max-width: 1024px) {
    display: grid;
    grid-template-columns: minmax(min-content, 2fr) minmax(min-content, 1fr) minmax(min-content, 1fr);
    grid-column-start: 1;
    grid-column-end: 3;
  }
`;

const OfficerBasicInfo: React.FC<IOfficerBasicInfoProps> = (props) => {
  const { officer } = props;
  const queryClient = useQueryClient();
  const queryData = queryClient.getQueryData<{ success: boolean; user_info: UserSchema }>(['user']);
  const user = queryData;

  const { width } = useWindowDimensions();

  return (
    <div className="w-full bg-white rounded-3xl">
      <div className="pt-3 pr-3">
        {user?.success && user.user_info.user_type === UserType.Nurse
          ? officer.user_type === UserType.Nurse
            ? (
              <span className="flex flex-row items-center justify-end">
                <NavLink to={`/nurse/edit/${officer._id}`}>
                  <EditUserInfoButton className="hover:border-blue-400 hover:text-blue-600 hover:bg-blue-50">
                    <EditFilled />
                    <p className="text-sm">Edit</p>
                  </EditUserInfoButton>
                </NavLink>
              </span>
            )
            : (
              <span className="flex flex-row items-center justify-end">
                <NavLink to={`/doctor/edit/${officer._id}`}>
                  <EditUserInfoButton className="hover:border-blue-400 hover:text-blue-600 hover:bg-blue-50">
                    <EditFilled />
                    <p className="text-sm">Edit</p>
                  </EditUserInfoButton>
                </NavLink>
              </span>
            )
          : (
            ''
          )}
      </div>
      <div className="px-6 pb-6">
        <Row className={`mb-3 ${user?.user_info.user_type === UserType.Doctor ? 'pt-6' : ''}`}>
          <Avatar
            src={officer.avatar_img_url && `${baseURL}/cos/image/${officer.avatar_img_url}`}
            icon={!officer.avatar_img_url && <UserOutlined />}
            className="flex-col mx-auto"
            size={{
              md: 80,
              lg: 80,
              xl: 100,
              xxl: 100,
            }}
          />
        </Row>
        <Row>
          <Text strong className="left-0 mx-auto mb-2 text-xl font-medium lg:text-center text-primary">
            {officer.name_title && officer.name_title}{officer.first_name}{width === 1024 && officer.user_type !== UserType.Nurse && <br />} {officer.last_name}
          </Text>
        </Row>
        {width === 1024 || width === 1366
          ? (
            <>
              <div className="mb-3">
                <div className="mb-1">
                  <HeadParagrahp>วันเดือนปีเกิด</HeadParagrahp>
                  <SubHeadParagrahp>{handleRenderDateOfBirth(officer?.date_of_birth)}</SubHeadParagrahp>
                </div>
                <div className="mb-1">
                  <HeadParagrahp>อายุ</HeadParagrahp>
                  <SubHeadParagrahp>{handleCalculateAge(officer?.date_of_birth)}</SubHeadParagrahp>
                </div>
                <div>
                  <HeadParagrahp>เพศ</HeadParagrahp>
                  <SubHeadParagrahp>{officer.gender === 'male' ? 'ชาย' : 'หญิง'}</SubHeadParagrahp>
                </div>
              </div>
            </>
          )
          : (
            <ThreeCols justify="space-between" className="mb-1">
              <Col>
                <HeadParagrahp>วันเดือนปีเกิด</HeadParagrahp>
                <SubHeadParagrahp>{handleRenderDateOfBirth(officer?.date_of_birth)}</SubHeadParagrahp>
              </Col>
              <Col>
                <HeadParagrahp>อายุ</HeadParagrahp>
                <SubHeadParagrahp>{handleCalculateAge(officer?.date_of_birth)}</SubHeadParagrahp>
              </Col>
              <Col>
                <HeadParagrahp>เพศ</HeadParagrahp>
                <SubHeadParagrahp>{officer.gender === 'male' ? 'ชาย' : 'หญิง'}</SubHeadParagrahp>
              </Col>
            </ThreeCols>
          )}
        <hr className="my-5" />

        <HeadParagrahp>ที่อยู่</HeadParagrahp>
        <SubHeadParagrahp>{officer.raw_address ? officer.raw_address : '-'}</SubHeadParagrahp>

        <HeadParagrahp>เบอร์โทรศัพท์</HeadParagrahp>
        <SubHeadParagrahp>{handleFormatMobileNumber(officer.mobile_number)}</SubHeadParagrahp>

        <HeadParagrahp>Email</HeadParagrahp>
        <SubHeadParagrahp>{officer.email ? officer.email : '-'}</SubHeadParagrahp>
      </div>
    </div>
  );
};

export default OfficerBasicInfo;
