/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useMutation, useQuery } from 'react-query';
import { fetchAllMedicalNote, createMedicalNote, editMedicalNote, deleteMedicalNote } from '../api-action';

export const useFetchAllMedicalNote = (id: string, sortType: string) => {
  return useQuery(
    ['all_medical_note', id],
    () => fetchAllMedicalNote(id, sortType),
    { refetchOnWindowFocus: false },
  );
};

export const useCreatePlainMedicalNote = () => {
  return useMutation(createMedicalNote);
};

export const useEditPlainMedicalNote = () => {
  return useMutation(editMedicalNote);
};

export const useDeleteMedicalNote = () => {
  return useMutation(deleteMedicalNote);
};
