import React from 'react';

interface Props {
  details: string;
}

const TinyMECShowDetail: React.FC<Props> = (props) => {
  const { details } = props;
  return (
    <>
      <p className="Markdown break-words" dangerouslySetInnerHTML={{ __html: details }} />
    </>
  );
};

export default TinyMECShowDetail;
