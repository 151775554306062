/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

const handleCheckCodeIDPatient1 = (code: string) => {
  const codeForm = ['A', 'B', 'C'];
  const checkCode = codeForm.includes(code);
  return checkCode;
};

const handleCheckCodeIDPatient2To5 = (code: string) => {
  const codeForm = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  const checkCode = codeForm.includes(code);
  return checkCode;
};

const ValidateCodeID = (code?: string) => {
  if (code) {
    const splitCode1 = code.charAt(0);
    const splitCode2 = code.charAt(1);
    const splitCode3 = code.charAt(2);
    const splitCode4 = code.charAt(3);
    const splitCode5 = code.charAt(4);

    const checked1 = handleCheckCodeIDPatient1(splitCode1);
    const checked2 = handleCheckCodeIDPatient2To5(splitCode2);
    const checked3 = handleCheckCodeIDPatient2To5(splitCode3);
    const checked4 = handleCheckCodeIDPatient2To5(splitCode4);
    const checked5 = handleCheckCodeIDPatient2To5(splitCode5);

    if (checked1 && checked2 && checked3 && checked4 && checked5) {
      return true;
    }
    return false;
  }
  return true; // this case won't happend, but this function has to has a return statement at the last line
};

export default ValidateCodeID;
