/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const vowels = ['เ', 'แ', 'โ', 'ไ', 'ใ'];

const FirstLetter = (name = '') => {
  for (let i = 0; i < name.length; i += 1) {
    if (vowels.indexOf(name.charAt(i)) === -1) return name.charAt(i);
  }
  return true;
};

export default FirstLetter;
