import { useCallback, useState } from 'react';
import io from 'socket.io-client';

const useSocket = (): {
  isConnected: boolean;
  open: (api: string, token: string) => void;
  close: () => void;
  subAdminMessage: (cb: (data: any) => void) => void;
  subAdminRead: (cb: (data: any) => void) => void;
  subPatientReadMyMessage: (name: string, cb: (data: any) => void) => void;
  subAdminRemoveMessage: (cb: (data: any) => void) => void;
  unsubPatientReadMyMessage: (name: string) => void;
  unsubAdminRead: () => void;
  unsubAdminMessage: () => void;
  unsubAdminRemoveMessage: () => void;
  emitServerRead: (data: any, ack?: (data: any) => void) => boolean;
  emitServerMessage: (data: any, ack?: (data: any) => void) => boolean;
  emitUnsendServerMessage: (data: any, ack?: (data: any) => void) => boolean;
  subTelemedQueue: (cb: (data: any) => void) => void;
  unsubTelemedQueue: () => void;
} => {
  const [socket, setSocket] = useState<SocketIOClient.Socket>();
  const [isConnected, setConnected] = useState(false);
  const open = useCallback(
    async (api: string, token: string) => {
      const newSocket = io(api, {
        path: '/socket.io/',
        query: { token },
      });
      newSocket.on('connect', () => {
        setConnected(true);
      });
      newSocket.on('disconnect', () => {
        setConnected(false);
      });
      setSocket(newSocket);
      setConnected(true);
    },
    [socket],
  );

  const close = useCallback(() => {
    socket?.close();
    setConnected(false);
  }, [socket]);

  const subAdminMessage = useCallback(
    (cb: (data: any) => void) => {
      socket?.on('admin-message', (data: any) => cb(data));
    },
    [socket],
  );

  const subAdminRemoveMessage = useCallback(
    (cb: (data: any) => void) => {
      socket?.on('admin-remove-message', (data: any) => cb(data));
    },
    [socket],
  );

  const subAdminRead = useCallback(
    (cb: (data: any) => void) => {
      socket?.on('admin-read', (data: any) => cb(data));
    },
    [socket],
  );

  const subPatientReadMyMessage = useCallback(
    (patientId: string, cb: (data: any) => void) => {
      socket?.on(`${patientId}-read`, (data: any) => cb(data));
    },
    [socket],
  );

  const unsubAdminMessage = useCallback(() => {
    socket?.off('admin-message');
  }, [socket]);

  const unsubAdminRemoveMessage = useCallback(() => {
    socket?.off('admin-remove-message');
  }, [socket]);

  const unsubPatientReadMyMessage = useCallback(
    (patientId: string) => {
      socket?.off(`${patientId}-read`);
    },
    [socket],
  );

  const unsubAdminRead = useCallback(() => {
    socket?.off('admin-read');
  }, [socket]);

  const emitServerRead = useCallback(
    (data: any, ack?: (data: any) => void): boolean => {
      if (socket) {
        socket?.emit('server-read', data, ack ?? {});
        return true;
      }
      return false;
    },
    [socket],
  );

  const emitServerMessage = useCallback(
    (data: any, ack?: (data: any) => void): boolean => {
      if (socket) {
        socket?.emit('server-message', data, ack ?? {});
        return true;
      }
      return false;
    },
    [socket],
  );

  const emitUnsendServerMessage = useCallback(
    (data: any, ack?: (data: any) => void): boolean => {
      if (socket) {
        socket?.emit('unsend-message', data, ack ?? {});
        return true;
      }
      return false;
    },
    [socket],
  );

  const subTelemedQueue = useCallback((cb: (data: any) => void) => {
    socket?.on('telemed-queue', (data: any) => cb(data));
  }, [socket]);

  const unsubTelemedQueue = useCallback(() => {
    socket?.off('telemed-queue');
  }, [socket]);

  return {
    isConnected,
    open,
    close,
    subAdminMessage,
    unsubAdminMessage,
    subAdminRead,
    unsubAdminRead,
    subPatientReadMyMessage,
    unsubPatientReadMyMessage,
    subAdminRemoveMessage,
    unsubAdminRemoveMessage,
    emitServerRead,
    emitServerMessage,
    emitUnsendServerMessage,
    subTelemedQueue,
    unsubTelemedQueue,
  };
};

export default useSocket;
