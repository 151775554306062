/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { DatePicker, Input, Radio, Tooltip } from 'antd';
import moment from 'moment';
import cx from 'classnames';

import { TPersonalNoteTemplate } from '../PersonalNoteModal';

export type PersonalNoteColonoScopeType = {
  isShow: boolean;
  isPreviousExpand: boolean;
  previousDate: moment.Moment | null;
  previousNote: string;
  isUpdatedExpand: boolean;
  updatedDate: moment.Moment | null;
  updatedNote: string;
};

export const personalNoteColonoScopeNewState = {
  isShow: false,
  isPreviousExpand: true,
  previousDate: null,
  previousNote: '',
  isUpdatedExpand: true,
  updatedDate: null,
  updatedNote: '',
};

const PersonalNoteColonoScope: React.FC<{
  personalNote: TPersonalNoteTemplate;
  value: PersonalNoteColonoScopeType | null;
  onHandleChange: (_value: PersonalNoteColonoScopeType) => void;
}> = ({ personalNote, value, onHandleChange }) => {
  const { name, sub_name: subName } = personalNote;
  const [state, setState] = useState<PersonalNoteColonoScopeType>(
    value ?? personalNoteColonoScopeNewState,
  );
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    onHandleChange(state);
  }, [state, setState]);

  useEffect(() => {
    setIsDisabled(!(state.updatedDate !== null || state.updatedNote !== ''));
  }, [state.updatedDate, state.updatedNote]);

  const onShowData = (isShow: boolean) => {
    setState(isShow ? {
      ...state,
      isShow,
    } : personalNoteColonoScopeNewState);
  };

  const togglePreviousExpand = () => {
    setState({
      ...state,
      isPreviousExpand: !state.isPreviousExpand,
    });
  };

  const toggleUpdatedExpand = () => {
    setState({
      ...state,
      isUpdatedExpand: !state.isUpdatedExpand,
    });
  };

  const onAddRecord = () => {
    if (!isDisabled) {
      setState({
        ...state,
        previousDate: state.updatedDate,
        previousNote: state.updatedNote,
        updatedDate: null,
        updatedNote: '',
      });
    }
  };

  const onChangePreviousDate = (date: moment.Moment | null) => {
    setState({
      ...state,
      previousDate: date,
    });
  };

  const onChangePreviousNote = (text: string) => {
    setState({
      ...state,
      previousNote: text,
    });
  };

  const onChangeUpdatedDate = (date: moment.Moment | null) => {
    setState({
      ...state,
      updatedDate: date,
    });
  };

  const onChangeUpdatedNote = (text: string) => {
    setState({
      ...state,
      updatedNote: text,
    });
  };

  return (
    <div className="pb-4">
      <p className="font-bold pb-2">{name}</p>
      <Radio.Group
        className="pb-2"
        onChange={(e) => onShowData(e.target.value)}
        value={state.isShow}
      >
        <Radio key="yes" value>
          Yes
        </Radio>
        <Radio key="no" value={false}>
          No
        </Radio>
      </Radio.Group>
      {state.isShow && (
        <div>
          <div className="border-t border-r border-l p-4">
            <div className="flex pb-2">
              {subName && <p className="text-sm font-thin">{subName}</p>}
              <p className=" flex-grow">Previous Record</p>
              <div onClick={togglePreviousExpand} className=" cursor-pointer">
                <img
                  className={`transform transition-transform ${
                    state.isPreviousExpand ? 'rotate-0' : 'rotate-180'
                  }`}
                  src="/icons/arrow_down.svg"
                  alt="arrow down"
                />
              </div>
            </div>
            <div
              className={`flex ${state.isPreviousExpand ? 'block' : 'hidden'}`}
            >
              <div className="flex flex-col w-56">
                <p>Date</p>
                <DatePicker
                  className="rounded-full"
                  value={state.previousDate}
                  onChange={onChangePreviousDate}
                />
              </div>
              <div className="flex flex-col flex-grow pl-4">
                <p>Note</p>
                <Input
                  className="w-full rounded-full"
                  type="text"
                  value={state.previousNote}
                  onChange={(e) => {
                    onChangePreviousNote(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="border p-4">
            <div className="flex pb-2">
              <p className=" flex-grow">Updated Record</p>
              <div onClick={toggleUpdatedExpand} className=" cursor-pointer">
                <img
                  className={`transform transition-transform ${
                    state.isUpdatedExpand ? 'rotate-0' : 'rotate-180'
                  }`}
                  src="/icons/arrow_down.svg"
                  alt="arrow down"
                />
              </div>
            </div>
            <div
              className={`flex justify-center ${
                state.isUpdatedExpand ? 'block' : 'hidden'
              }`}
            >
              <div className="flex flex-col w-56">
                <p>Date</p>
                <DatePicker
                  className="rounded-full"
                  value={state.updatedDate}
                  onChange={onChangeUpdatedDate}
                  status=""
                />
              </div>
              <div className="flex flex-col flex-grow pl-4">
                <p>Note</p>
                <Input
                  className="rounded-full"
                  type="text"
                  value={state.updatedNote}
                  onChange={(e) => {
                    onChangeUpdatedNote(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end py-2">
            <div
              className={cx('flex', {
                'cursor-pointer': !isDisabled,
              })}
              onClick={onAddRecord}
            >
              <img
                className="mr-2 w-5"
                src={isDisabled ? '/icons/plus-gray.svg' : '/icons/plus.svg'}
                alt="plus"
              />
              <p className="underline" style={{ color: isDisabled ? '#8C8C8C' : '#096DD9' }}>
                Add Record
              </p>
            </div>
            <Tooltip
              title="‘Add Record’ จะเป็นการเพิ่ม ‘Updated Record’ อันใหม่ โดยที่ ‘Previous Record’ เดิม จะถูกแทนที่ ด้วย ‘Updated Record’ ก่อนหน้านี้"
              trigger="hover"
            >
              <img
                className="ml-1 w-5"
                src="/icons/info.svg"
                alt="information"
              />
            </Tooltip>
          </div>
        </div>
      )}
    </div>
  );
};

export default PersonalNoteColonoScope;
