/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */

import {
  TPatient,
  TPatientEdit,
  TPatientInitialValue,
  TPatientSummary,
  TPatientTags,
} from '../../form-schema/patients/PatientSchema';
import { APIResponse, UserSortType } from '../../constant-enum-type';
import api from '../../utils/api';
import { PatientResponse } from '../../components/form/common/responses';

export type FetchPatientsResponse = APIResponse<{ patients: TPatient[]; total: number }>;
export type FetchPatientSummaryResponse = APIResponse<{ success: boolean; patient_summary: TPatientSummary[] }>;
export type FetchPatientSummaryByPatientIDResponses = APIResponse<{ success: boolean; patient_summary: TPatientSummary }>;
export type FetchPatientsByIDResponse = APIResponse<{ success: boolean; patient: TPatient | undefined }>;
export type FetchDiagnosisCodeResponse = APIResponse<{ success: boolean; code_detail: string[] }>;
export type FetchPatientsTagsResponse = APIResponse<{ success: boolean; data: TPatientTags }>;
export type EditPatientNoteByID = APIResponse<{ success: boolean }>;
export type EditPatientNoteTemplateByID = APIResponse<{ success: boolean; patient: TPatient | undefined }>;
export type EditMedicationNoteResponse = APIResponse<{ success: boolean; patient: TPatient }>;
export type UpdateLiveResponse = APIResponse<{ success: boolean }>;

export const fetchPatients = (skip: number, limit: number, searchKey: string, sortType: UserSortType, serachType: string): Promise<FetchPatientsResponse> => {
  return api
    .get(`/patients?skip=${skip}&limit=${limit}&search=${searchKey}&sort=${sortType}&searchType=${serachType}`)
    .then((res) => res.data);
};

export const fetchPatientSummary = (skip: number, limit: number, searchKey: string, sortType: UserSortType): Promise<FetchPatientSummaryResponse> => {
  return api
    .get(`/patients/summary?skip=${skip}&limit=${limit}&search=${searchKey}&sort=${sortType}`)
    .then((res) => res.data);
};

export const fetchPatientSummaryByPatientID = (patientID: string): Promise<FetchPatientSummaryByPatientIDResponses> => {
  return api
    .get(`/patients/summary/${patientID}`)
    .then((res) => res.data);
};

export const addPatient = (body: TPatientInitialValue): Promise<PatientResponse> => {
  return api
    .post('/patients', body)
    .then((res) => res.data);
};

export const fetchPatientByID = (id: string): Promise<FetchPatientsByIDResponse> => {
  if (!id) return Promise.resolve({ success: false } as APIResponse<FetchPatientsByIDResponse>);
  return api
    .get(`/patients/${id}`)
    .then((res) => res.data);
};

export const editPatientByID = (data: { id: string; body: TPatientEdit }): Promise<PatientResponse> => {
  return api
    .put(`/patients/${data.id}`, data.body)
    .then((res) => res.data);
};

export const fetchDiagnosisCode = (diagnosisCode: string | undefined | false): Promise<FetchDiagnosisCodeResponse> => {
  return api
    .get(`/patients/code-diagnosis/${diagnosisCode}`)
    .then((res) => res.data);
};

export const fetchPatientTags = (): Promise<FetchPatientsTagsResponse> => {
  return api
    .get('/system-configurations/patient_tags')
    .then((res) => res.data);
};

export const editPatientNoteByID = (data: { id: string; body: { personal_note: string } }): Promise<EditPatientNoteByID> => {
  return api
    .put(`/patients/personal-note/${data.id}`, data.body)
    .then((res) => res.data);
};

export const editPatientNoteTemplateByID = (data: { id: string; body: any }): Promise<EditPatientNoteTemplateByID> => {
  return api
    .put(`/patients/personal-note-template/${data.id}`, data.body)
    .then((res) => res.data);
};

export const editMedicationNote = (data: { id: string; body: { medication_note: string } }): Promise<EditMedicationNoteResponse> => {
  return api
    .put(`/patients/medication-note/${data.id}`, data.body)
    .then((res) => res.data);
};

export const updateLive = (appointment_id: string): Promise<UpdateLiveResponse> => {
  return api
    .put('/live', { appointment_id })
    .then((res) => res.data);
};
