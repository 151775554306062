/* eslint-disable import/prefer-default-export */
import moment from 'moment-timezone';
import * as Yup from 'yup';

export interface TNurse {
  _id: string;
  avatar_img_url: string;
  name_title: string;
  first_name: string;
  last_name: string;
  username: string;
  user_type: string;
  hospital_id: string;
  mobile_number: string;
  email: string;
  date_of_birth: string;
  citizen_id: string;
  gender: string;
  raw_address: string;
  is_super_admin: boolean;
  uid_agora?: string;
}

export interface TNurseEdit {
  avatar_img_url?: string;
  name_title?: string;
  first_name?: string;
  last_name?: string;
  mobile_number?: string;
  email?: string;
  raw_address?: string;
  date_of_birth: string;
  citizen_id?: string;
  password?: string;
  is_super_admin?: boolean;
  gender?: string;
}

export interface TNurseInitialValue {
  avatar_img_url: string;
  name_title: string;
  first_name: string;
  last_name: string;
  username: string;
  password: string;
  confirm_password?: string;
  hospital_id: string;
  mobile_number: string;
  email: string;
  date_of_birth: string;
  citizen_id: string;
  gender: string;
  raw_address: string;
  is_super_admin: boolean;
}

const initialValues: TNurseInitialValue = {
  avatar_img_url: '',
  name_title: '',
  first_name: '',
  last_name: '',
  username: '',
  password: '',
  confirm_password: '',
  hospital_id: '',
  mobile_number: '',
  email: '',
  date_of_birth: moment(new Date()).toISOString(),
  citizen_id: '',
  gender: '',
  raw_address: '',
  is_super_admin: false,
};

export const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .required('กรุณากรอกชื่อพยาบาล'),
  last_name: Yup.string()
    .required('กรุณากรอกนามสกุลพยาบาล'),
  username: Yup.string()
    .required('กรุณากรอกชื่อผู้ใช้'),
  password: Yup.string()
    .when('_id', {
      is: '' || undefined,
      then: Yup
        .string()
        .min(8, 'รหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร')
        .required('กรุณากรอกรหัสผ่าน'),
      otherwise: Yup.string().nullable(),
    }),
  confirm_password: Yup.string()
    .when('_id', {
      is: '' || undefined,
      then: Yup
        .string()
        .oneOf([Yup.ref('password')], 'รหัสผ่านไม่ตรงกัน')
        .required('กรุณายืนยันรหัสผ่าน'),
      otherwise: Yup.string().nullable(),
    }),
  email: Yup.string()
    .email('รูปแบบ Email ไม่ถูกต้อง'),
  mobile_number: Yup.number()
    .typeError('กรุณากรอกเฉพาะตัวเลขเท่านั้น'),
  citizen_id: Yup.number()
    .typeError('กรุณากรอกเฉพาะตัวเลขเท่านั้น'),
  gender: Yup.string()
    .required('กรุณาเลือกเพศ'),
});

export default initialValues;
