/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { Radio } from 'antd';
import { TPersonalNoteTemplate } from '../PersonalNoteModal';
import PersonalNoteText, {
  personalNoteTextNewState,
  PersonalNoteTextType,
} from './common/PersonalNoteText';

export type PersonalNoteFamilyHistoryType = {
  isShow: boolean;
  hcc: PersonalNoteTextType;
  caColon: PersonalNoteTextType;
  others: PersonalNoteTextType;
};

export const personalNoteFamilyHistoryNewState = {
  isShow: false,
  hcc: personalNoteTextNewState,
  caColon: personalNoteTextNewState,
  others: personalNoteTextNewState,
};

const PersonalNoteFamilyHistory: React.FC<{
  personalNote: TPersonalNoteTemplate;
  value: PersonalNoteFamilyHistoryType | null;
  onHandleChange: (newState: PersonalNoteFamilyHistoryType) => void;
}> = ({ personalNote, value, onHandleChange }) => {
  const { key, name, sub_name: subName, items } = personalNote;

  const [state, setState] = useState<PersonalNoteFamilyHistoryType>(
    value ?? personalNoteFamilyHistoryNewState,
  );

  useEffect(() => {
    onHandleChange(state);
  }, [state, setState]);

  const onShowData = (isShow: boolean) => {
    setState(isShow ? {
      ...state,
      isShow,
    } : personalNoteFamilyHistoryNewState);
  };

  const onMapData = (keyName: string): PersonalNoteTextType => {
    if (keyName === 'hcc') {
      return state.hcc;
    }
    if (keyName === 'ca_colon') {
      return state.caColon;
    }
    if (keyName === 'others') {
      return state.others;
    }
    return personalNoteTextNewState;
  };

  const onSetMapData = (keyName: string, _value: PersonalNoteTextType) => {
    setState({
      ...state,
      hcc: keyName === 'hcc' ? _value : state.hcc,
      caColon: keyName === 'ca_colon' ? _value : state.caColon,
      others: keyName === 'others' ? _value : state.others,
    });
  };

  return (
    <div key={key} className="pb-4">
      <p className="font-bold pb-2">{name}</p>
      {subName && <p className="text-sm font-thin pb-2">{subName}</p>}
      <Radio.Group
        className={`${items !== undefined ? 'pb-4' : ''}`}
        onChange={(e) => onShowData(e.target.value)}
        value={state.isShow}
      >
        <Radio key="yes" value>
          Yes
        </Radio>
        <Radio key="no" value={false}>
          No
        </Radio>
      </Radio.Group>
      {(state.isShow && items) && (
        items?.map((item) => {
          return (
            <PersonalNoteText
              key={item.key}
              value={onMapData(item.key)}
              personalNote={item}
              onHandleAnswer={(data) => {
                onSetMapData(item.key, data);
              }}
            />
          );
        }))}
    </div>
  );
};

export default PersonalNoteFamilyHistory;
